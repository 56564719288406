import store from "../store";
import invoicesClient from "../api/invoices.api";
import { formatToCurrency, formatInvoiceNumber } from "../utility/helpers";
import { useToast } from "vue-toastification";

let dayjs = require("dayjs");
let duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export default {
    getUnpaidClientInvoices: async function (invoiceStatus = "unpaid") {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            const paymentProviderSettingsId = store.getters.paymentProviderSettingsId;

            response = await invoicesClient.getClientInvoices(
                tenantId,
                clientId,
                paymentProviderSettingsId,
                invoiceStatus
            );
        } catch (error) {
            Promise.reject("Unable to retrieve invoices - " + error);
        }

        return response.data;
    },

    getAllClientInvoices: async function () {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            const paymentProviderSettingsId = store.getters.paymentProviderSettingsId;
            response = await invoicesClient.getClientInvoices(
                tenantId,
                clientId,
                paymentProviderSettingsId
            );
        } catch (error) {
            Promise.reject("Unable to retrieve invoices - " + error);
        }
        return response.data;
    },
    getInvoiceBySignedId: async function (signedInvoiceId, isCombinedInvoiceFormatEnabled) {
        await store.dispatch("flushInvoicesStore");
        await store.dispatch("flushPaymentsStore");

        let response;
        try {
            response = await invoicesClient.getInvoiceBySignedInvoiceId(signedInvoiceId);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve invoice");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve invoice at this time");
            const toast = useToast();
            toast.error(ex.message);
            throw ex.message;
        }

        store.commit("setSignedInvoiceId", response.data.signedInvoiceId);
        if (isCombinedInvoiceFormatEnabled === true) {
            response.data.invoiceNumber = response.data.erpProjectNumber + "-" + formatInvoiceNumber(response.data.erpInvoiceNumber);
        }
        if (response.data && response.data.clientId) {
            store.commit("setClientId", response.data.clientId);
        }
        if (response.data && response.data.dueDate) {
            store.commit("setShowInvoiceDueDate", true);
        }

        let invoiceArray = [];
        invoiceArray.push(response.data);
        this.storeInvoicesToPay(invoiceArray);

        return response.data;
    },
    getInvoiceById: async function (invoiceId) {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            response = await invoicesClient.getInvoiceById(
                tenantId,
                clientId,
                invoiceId
            );
        } catch (error) {
            const ex = new Error("Unable to retrieve invoice");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve invoice");
            throw ex.message;
        }

        return response.data;
    },

    storeInvoicesToPay: function (invoices) {
        const totalInvoicesAmount = invoices.reduce(
            (prior, current) => prior + current.invoiceAmount, 0,
        );
        const totalAmount = formatToCurrency(totalInvoicesAmount);
        store.commit("setInvoices", invoices);
        store.commit("setInvoicesToPayTotalAmount", totalAmount);
    },

    getInvoiceFileUrl: async function (invoiceId) {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            response = await invoicesClient.getInvoiceFileUrl(
                tenantId,
                clientId,
                invoiceId
            );
        } catch (error) {
            const ex = new Error("Unable to retrieve invoice file url");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve invoice pdf at this time");
            const toast = useToast();
            toast.error(ex.message);
            throw ex.message;
        }
        return response.data;
    },

    getInvoiceFileUrlBySignedInvoiceId: async function (signedInvoiceId) {
        let response;
        try {
            response = await invoicesClient.getInvoiceFileUrlBySignedInvoiceId(
                signedInvoiceId
            );
        } catch (error) {
            const ex = new Error("Unable to retrieve invoice file url");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve invoice pdf at this time");
            const toast = useToast();
            toast.error(ex.message);
            throw ex.message;
        }
        return response.data;
    },

    reloadInvoicesAsync: async function () {
        const signedInvoiceId = store.getters.signedInvoiceId;
        const invoices = store.getters.invoicesToPay;
        let reloadedInvoices = [];
        for (let i = 0; i < invoices.length; i++) {
            let invoice;
            if (signedInvoiceId) {
                invoice = await this.getInvoiceBySignedId(
                    signedInvoiceId,
                    false
                );
            } else {
                invoice = await this.getInvoiceById(invoices[i].invoiceId);
            }
            invoice.invoiceNumber = invoices[i].invoiceNumber
                ? invoices[i].invoiceNumber
                : undefined;
            reloadedInvoices.push(invoice);
        }

        store.commit("setInvoices", reloadedInvoices);
    }
};