<template>
  <div class="layout-footer">
    <div class="evpay-logo">
      <a href="https://www.eleviasoftware.com/evpay" target="_blank">
        <img
          class="evpay-logo-img"
          src="/images/evpay_assets/poweredbyevpay.png"
          alt="evpay-logo"
        />
      </a>
    </div>
    <span class="copyright">&#169; EleVia Software - {{ currentYear }}</span>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "AppFooter",
  setup() {
    const currentYear = ref(new Date().getFullYear());

    return {
      currentYear,
    };
  },
};
</script>