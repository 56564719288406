<template>
  <div class="card ev-fluid-card">
    <div class="card-header">
      <h4>Tenant Details</h4>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="tenantId">Tenant Id:</label>
        <div>
          <InputText
            id="tenantId"
            v-model="tenant.tenantId"
            class="inputfield w-full"
            disabled
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <label for="tenantName">Tenant Name:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="tenantName"
            v-model="tenant.tenantName"
            class="inputfield w-full"
          />
          <Button
            :icon="loadingIconType"
            @click="updateTenantField('tenantName')"
          ></Button>
        </div>
        <template v-if="v$.tenant.tenantName.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.tenant.tenantName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="field col-12 md:col-6">
        <label for="appClientId">App Client Id:</label>
        <div>
          <InputText
            id="appClientId"
            v-model="tenant.appClientId"
            class="inputfield w-full"
            disabled
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <label for="appClientSecret">App Client Secret:</label>
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchAppClientSecretVisibility"
          ></Button>
          <InputText
            id="appClientSecret"
            v-model="tenant.appClientSecret"
            class="inputfield w-full"
            :type="appClientSecretPasswordFieldType"
            disabled
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <label for="apiKeyId">Api Key:</label>
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchApiKeyVisibility"
          ></Button>
          <InputText
            id="apiKeyId"
            v-model="tenant.apiValue"
            class="inputfield w-full"
            :type="apiKeyPasswordFieldType"
            disabled
          />
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <label for="enabled">Enabled:</label>
        <div>
          <InputSwitch
            v-model="tenant.enabled"
            @change="updateTenantField('enabled')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import tenantService from "@/services/Admin/tenant";
import { useVuelidate } from "@vuelidate/core";
import { minLength, maxLength, required, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";

export default {
  props: { tenantIdProp: { type: String } },
  setup(props) {
    const toast = useToast();
    const tenant = ref({});
    const tenants = ref([]);
    const initialTenant = ref({});
    const appClientSecretPasswordFieldType = ref("password");
    const apiKeyPasswordFieldType = ref("password");
    const updateTenantDto = ref({});
    const updateTenantResponse = ref();
    const loadingIconType = ref("pi pi-check");

    const fetchAllTenants = async () => {
      tenants.value = await tenantService.getAllTenants();
    };

    const rules = computed(() => ({
      tenant: {
        tenantName: {
          required: helpers.withMessage("Tenant Name is required", required),
          minLength: helpers.withMessage(
            "Tenant Name must be at least 2 characters",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Tenant Name cannot be more than 100 characters",
            maxLength(100)
          ),
          duplicateTenantName: helpers.withMessage(
            "Tenant Name already exists",
            validateDuplicateTenantName
          ),
        },
      },
    }));
    const v$ = useVuelidate(
      rules,
      {
        tenant,
      },
      { $lazy: true }
    );

    onMounted(fetchTenantDetail);
    onMounted(fetchAllTenants);

    async function fetchTenantDetail() {
      tenant.value = await tenantService.getTenantDetail(props.tenantIdProp);
      Object.assign(initialTenant.value, tenant.value);
    }

    function validateDuplicateTenantName() {
      var existingTenant = tenants.value.filter(function (elem) {
        if (elem.enabled === tenant.value.enabled) {
          if (
            elem.tenantName.toLowerCase() ==
            tenant.value.tenantName.toLowerCase()
          ) {
            return elem.tenantName;
          }
        }
      });

      return existingTenant.length > 0 ? false : true;
    }

    async function updateTenantField(property) {
      if (!(await v$.value.$validate())) {
        return;
      } else {
        loadingIconType.value = "pi pi-spinner";

        let fieldValue = tenant.value[property];

        updateTenantDto.value = {
          tenantId: tenant.value.tenantId,
          tenantName:
            property == "tenantName"
              ? fieldValue
              : initialTenant.value.tenantName,
          enabled:
            property == "enabled" ? fieldValue : initialTenant.value.enabled,
        };

        updateTenantResponse.value = await tenantService.updateTenant(
          updateTenantDto.value
        );

        if (updateTenantResponse.value == 204) {
          toast.success("Tenant Updated");
          fetchTenantDetail();
        } else {
          toast.error("Unable to Update Tenant");
        }

        loadingIconType.value = "pi pi-check";
      }
    }

    function switchAppClientSecretVisibility() {
      appClientSecretPasswordFieldType.value =
        appClientSecretPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    function switchApiKeyVisibility() {
      apiKeyPasswordFieldType.value =
        apiKeyPasswordFieldType.value === "password" ? "text" : "password";
    }

    return {
      tenant,
      appClientSecretPasswordFieldType,
      apiKeyPasswordFieldType,
      rules,
      updateTenantDto,
      updateTenantResponse,
      loadingIconType,
      v$,
      switchAppClientSecretVisibility,
      switchApiKeyVisibility,
      updateTenantField,
    };
  },
};
</script>
