<template>
  <div>
    <div class="ev-fluid-card" v-if="!erpIntegrationSettingsId">
      <Message severity="warn" :sticky="true" :closable="false">
        <strong
          >Integration Settings have not been created for this Payment Provider
          Settings (Company).</strong
        >
      </Message>
      <div class="pb-3">
        <Button
          label="Create Integration Settings"
          @click="showIntegrationSettingsModal()"
        />
      </div>
    </div>
    <div><TenantDetail :tenantIdProp="tenantId" /></div>
    <div class="pt-5">
      <PaymentProviderSettingsDetail
        :tenantIdProp="tenantId"
        :paymentProviderSettingsIdProp="paymentProviderSettingsId"
      />
    </div>
    <div class="pt-5">
      <MerchantSettings
        :tenantIdProp="tenantId"
        :paymentProviderSettingsIdProp="paymentProviderSettingsId"
      />
    </div>
    <div class="pt-5" v-if="erpIntegrationSettingsId">
      <ErpIntegrationSettingsDetail
        :tenantIdProp="tenantId"
        :erpIntegrationSettingsIdProp="erpIntegrationSettingsId"
      />
    </div>
    <Dialog
      v-model:visible="isIntegrationSettingsModalVisible"
      :style="{ width: '600px' }"
      header="Confirm"
      :modal="true"
    >
      <ErpIntegrationSettingsDetail
        :tenantIdProp="tenantId"
        :paymentProviderSettingsIdProp="paymentProviderSettingsId"
      />
    </Dialog>
  </div>
</template>

<script>
import TenantDetail from "./TenantDetail.vue";
import PaymentProviderSettingsDetail from "./PaymentProviderSettingsDetail.vue";
import MerchantSettings from "./MerchantSettings.vue";
import ErpIntegrationSettingsDetail from "./ErpIntegrationSettingsDetail.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    TenantDetail,
    PaymentProviderSettingsDetail,
    MerchantSettings,
    ErpIntegrationSettingsDetail,
  },
  setup() {
    const route = useRoute();
    const tenantId = ref(route.params.tenantId);
    const paymentProviderSettingsId = ref(
      route.params.paymentProviderSettingsId
    );
    const erpIntegrationSettingsId = ref(route.params.erpIntegrationSettingsId);
    const isIntegrationSettingsModalVisible = ref(false);

    function showIntegrationSettingsModal() {
      isIntegrationSettingsModalVisible.value = true;
    }

    return {
      tenantId,
      paymentProviderSettingsId,
      erpIntegrationSettingsId,
      isIntegrationSettingsModalVisible,
      showIntegrationSettingsModal,
    };
  },
};
</script>