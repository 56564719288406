<template>
  <div class="guest-checkout-header-container">
    <div v-if="isInvoiceStatusPayable === true">
      <h4>Payment Options</h4>
      <br />
    </div>
    <div class="portal-select-container">
      <div class="portal-select-container-row">
        <div
          class="portal-select-container-cell"
          v-if="isInvoiceStatusPayable === true"
        >
          <div class="portal-select-container-table">
            <div class="portal-select-container-row">
              <div class="portal-select-container-cell-pad">
                <div class="portal-select-container-cell-header">
                  Pay online with Guest Checkout
                </div>
                <div class="portal-select-container-cell-features">
                  - No registration required
                </div>
                <div class="portal-select-container-cell-button">
                  <Button
                    class="
                      p-button-lg p-button-rounded
                      portal-select-container-button
                      justify-content-center
                      button-submit-payment
                    "
                    label="Pay"
                    @click="guestCheckout()"
                    :loading="isLoading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'portal-select-container-cell-border':
              isVaultEnabled === true && isInvoiceStatusPayable === true,
            'portal-select-container-cell':
              isVaultEnabled === true && isInvoiceStatusPayable === false,
          }"
          v-if="isVaultEnabled === true"
        >
          <div class="portal-select-container-table">
            <div class="portal-select-container-row">
              <div class="portal-select-container-cell-pad">
                <div class="portal-select-container-cell-header">
                  Create an account to use extended features
                </div>
                <div class="portal-select-container-cell-features">
                  <span class="portal-select-container-cell-font"
                    >- Pay multiple invoices at once</span
                  >
                  <br />
                  <span class="portal-select-container-cell-font"
                    >- Store payment methods</span
                  >
                  <br />
                  <span
                    class="portal-select-container-cell-font"
                    v-if="isScheduledPaymentEnabled === true"
                    >- Schedule payments</span
                  >
                </div>
                <div class="portal-select-container-cell-button">
                  <Button
                    class="
                      p-button-lg p-button-rounded
                      portal-select-container-button
                      justify-content-center
                      button-submit-payment
                    "
                    label="Register"
                    @click="signup()"
                    :loading="isLoading"
                  />
                  <div class="portal-select-container-cell-font">
                    <br />
                    <a :href="signinUrl" target="_self"
                      >Already Registered? Login</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
import router from "@/router";
import { getEnvironment } from "@/utility/helpers";
import store from "@/store";

export default {
  props: {
    isInvoiceStatusPayableProp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isInvoiceStatusPayable = toRef(props, "isInvoiceStatusPayableProp");
    const environment = getEnvironment();
    const tenantId = computed(() => store.getters.tenantId);
    const clientId = computed(() => store.getters.clientId);
    const subDomain = computed(() => store.getters.subDomain);
    const isScheduledPaymentEnabled = computed(
      () => store.getters.scheduledPaymentEnabled
    );
    const isVaultEnabled = computed(() => {
      return tenantId.value && clientId.value && subDomain.value ? true : false;
    });
    const isLoading = ref(false);
    const signupUrl = computed(() => {
      return isVaultEnabled.value === false
        ? null
        : window.location.protocol +
            "//" +
            subDomain.value +
            environment +
            ".evpay.com/sign-in?tenantId=" +
            tenantId.value +
            "&clientId=" +
            clientId.value;
    });
    const signinUrl = computed(() => {
      return (
        window.location.protocol +
        "//" +
        subDomain.value +
        environment +
        ".evpay.com/login"
      );
    });

    function guestCheckout() {
      isLoading.value = true;
      router.push({ path: "/guest-checkout" });
      isLoading.value = false;
    }
    function signup() {
      isLoading.value = true;
      window.location.href = signupUrl.value;
      isLoading.value = false;
    }

    return {
      isInvoiceStatusPayable,
      isScheduledPaymentEnabled,
      isVaultEnabled,
      signinUrl,
      isLoading,
      signup,
      guestCheckout,
    };
  },
};
</script>
