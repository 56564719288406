import {
    createWebHistory,
    createRouter
} from "vue-router";
import SignIn from "@/components/Client/SignIn";
import Login from "@/components/Client/Login";
import Logout from "@/components/Client/Logout";
import LoginCallback from "@/components/Client/LoginCallback";
import LogoutCallback from "@/components/Client/LogoutCallback";
import AdminLogin from "@/components/Admin/Login";
import AdminLogout from "@/components/Admin/Logout";
import AdminLoginCallback from "@/components/Admin/LoginCallback";
import AdminLogoutCallback from "@/components/Admin/LogoutCallback";
import InternalAdminDashboard from "@/components/Admin/Dashboard";
import TenantCompanies from "@/components/Admin/TenantCompanies";
import TenantSummary from "@/components/Admin/TenantSummary";
import PaymentProviderSettingsDetail from "@/components/Admin/PaymentProviderSettingsDetail";
import StoredPaymentMethods from "@/components/Client/StoredPaymentMethods";
import Error from "@/views/Error";
import Forbidden from "@/views/Forbidden";
import NotFound from "@/views/NotFound";
import ScheduledPayments from "@/components/Client/ScheduledPayments.vue";
import PaymentHistory from "@/components/Client/PaymentHistory.vue";
import Profile from "@/views/Profile";
import AdminUserManagement from "@/views/AdminUserManagement.vue";
import Summary from "@/views/Summary";
import Invoices from "@/views/Invoices";
import Payments from "@/views/Payments";
import PaymentConfirmation from "@/views/PaymentConfirmation";
import GuestCheckout from "@/views/GuestCheckout";
import PaymentPortalTypeSelectView from "@/views/PaymentPortalTypeSelectView";
import GuestCheckoutPaymentConfirmation from "@/views/GuestCheckoutPaymentConfirmation";
import store from "../store";
import {
    Role,
    ADMIN_ROLE_NAMES,
    CLIENT_ADMIN_ROLE_NAMES,
    CLIENT_USER_ROLE_NAMES,
    CLIENT_READONLY_ROLE_NAMES
} from "../utility/role";
import {
    useToast
} from "vue-toastification";

const routes = [{
        path: "/",
        component: Summary,
        name: "Summary",
        meta: {
            title: "EVPay",
            auth: true,
            breadcrumb: [{
                parent: "Summary",
                label: "Summary",
                to: "/"
            }],
        },
    },
    {
        path: "/internal-admin-dashboard",
        component: InternalAdminDashboard,
        name: "InternalAdminDashboard",
        meta: {
            title: "EVPay",
            auth: true,
            authrole: [Role.InternalAdmin],
            breadcrumb: [{
                parent: "Internal Dashboard",
                label: "Internal Dashboard",
                to: "/internal-admin-dashboard"
            }],
        },
    },
    {
        path: "/sign-in",
        name: "SignIn",
        component: SignIn,
        meta: {
            title: "Sign In",
            auth: false,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login",
            auth: false,
        },
    },
    {
        path: "/admin-login",
        name: "AdminLogin",
        component: AdminLogin,
        meta: {
            title: "Admin Login",
            auth: false,
        },
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: {
            title: "Logout",
            auth: true,
        },
    },
    {
        path: "/admin-logout",
        name: "AdminLogout",
        component: AdminLogout,
        meta: {
            title: "Admin Logout",
            auth: true,
            authrole: [Role.InternalAdmin],
        },
    },
    {
        path: "/logincallback",
        name: "LoginCallback",
        component: LoginCallback,
        meta: {
            title: "Authenticating...",
            auth: false,
        },
    },
    {
        path: "/adminlogincallback",
        name: "AdminLoginCallback",
        component: AdminLoginCallback,
        meta: {
            title: "Authenticating...",
            auth: false,
        },
    },
    {
        path: "/logoutcallback",
        name: "LogoutCallback",
        component: LogoutCallback,
        meta: {
            title: "LogoutCallback",
            auth: true,
        },
    },
    {
        path: "/adminlogoutcallback",
        name: "AdminLogoutCallback",
        component: AdminLogoutCallback,
        meta: {
            title: "Admin Logout Callback",
            auth: true,
            authrole: [Role.InternalAdmin],
        },
    },
    {
        path: "/tenant/:tenantId",
        component: TenantCompanies,
        name: "TenantCompanies",
        meta: {
            title: "Tenant Companies",
            auth: true,
            authrole: [Role.InternalAdmin],
            breadcrumb: [{
                parent: "Internal Admin",
                label: "Tenant Companies",
                to: "/tenant/:tenantId"
            }],
        }
    },
    {
        path: "/tenant/:tenantId/payment-provider-settings/:paymentProviderSettingsId/erp-integration-settings/:erpIntegrationSettingsId?",
        component: TenantSummary,
        name: "TenantSummary",
        meta: {
            title: "Tenant Summary",
            auth: true,
            authrole: [Role.InternalAdmin],
            breadcrumb: [{
                parent: "Internal Admin",
                label: "Tenant Summary",
            }],
        }
    },
    {
        path: "/tenant/:tenantId/payment-provider-settings",
        component: PaymentProviderSettingsDetail,
        name: "PaymentProviderSettingsDetail",
        meta: {
            title: "Payment Provider Settings Detail",
            auth: true,
            authrole: [Role.InternalAdmin],
            breadcrumb: [{
                parent: "Internal Admin",
                label: "Payment Provider Settings Detail",
            }],
        }
    },
    {
        path: "/invoices",
        component: Invoices,
        name: "Invoices",
        meta: {
            title: "Pay Invoices",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Invoices"
            }],
        },
    },
    {
        path: "/payments",
        component: Payments,
        name: "Payments",
        meta: {
            title: "Create Payments",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Payments"
            }],
        },
    },
    {
        path: "/payment-confirmation",
        component: PaymentConfirmation,
        name: "PaymentConfirmation",
        meta: {
            title: "Payment Confirmation",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Payment Confirmation"
            }],
        },
    },
    {
        path: "/stored-payment-methods",
        component: StoredPaymentMethods,
        name: "Stored Payment Methods",
        meta: {
            title: "Stored Payment Methods",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Stored Payment Methods"
            }],
        },
    },
    {
        path: "/profile",
        component: Profile,
        name: "Profile",
        meta: {
            title: "Profile",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Profile"
            }],
        },
    },
    {
        path: "/admin-user-management",
        component: AdminUserManagement,
        name: "Admin User Management",
        meta: {
            title: "Admin User Management",
            auth: true,
            authrole: [Role.ClientAdmin],
            breadcrumb: [{
                parent: "Clients",
                label: "Admin User Management"
            }],
        },
    },
    {
        path: "/scheduled-payments",
        component: ScheduledPayments,
        name: "ScheduledPayments",
        meta: {
            title: "Scheduled Payments",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Scheduled Payments"
            }],
        },
    },
    {
        path: "/payment-history",
        component: PaymentHistory,
        name: "PaymentHistory",
        meta: {
            title: "Payment History",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: "Payment History"
            }],
        },
    },
    {
        path: "/error",
        name: "Error",
        component: Error,
        meta: {
            title: "Error",
            auth: false,
        },
    },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: Forbidden,
        meta: {
            title: "Error - Forbidden",
            auth: false,
        },
    },
    {
        path: "/not-found",
        name: "NotFound",
        component: NotFound,
        meta: {
            title: "Error - Page not found",
            auth: false,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: "PageNotFound",
        meta: {
            title: "EvPay",
            auth: true,
            breadcrumb: [{
                parent: "Clients",
                label: ""
            }],
        },
    },
    {
        path: "/invoice/:id",
        name: "PaymentPortalTypeSelectView",
        component: PaymentPortalTypeSelectView,
        meta: {
            title: "Checkout Options",
            auth: false,
        },
    },
    {
        path: "/guest-checkout",
        name: "GuestCheckout",
        component: GuestCheckout,
        meta: {
            title: "Guest Checkout",
            auth: false,
        },
    },
    {
        path: "/guest-checkout-payment-confirmation",
        name: "GuestCheckoutPaymentConfirmation",
        component: GuestCheckoutPaymentConfirmation,
        meta: {
            title: "Payment Confirmation - Guest Checkout",
            auth: false,
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    await store.restored;
    if (to.meta && to.meta.title) {
        document.title = store.getters.name ? store.getters.name + " - " + to.meta.title : to.meta.title;
    }
    if (to.meta && to.meta.auth !== undefined) {
        if (to.meta.auth && store.getters.isAuthenticated === false) {
            next();
            let subdomain = window.location.host.split('.')[0]
            if (to.query.code && to.query.state) {
                if (subdomain == 'admin') {
                    router.push({
                        name: "AdminLoginCallback",
                        query: {
                            code: to.query.code,
                            state: to.query.state
                        }
                    });
                } else {
                    router.push({
                        name: "LoginCallback",
                        query: {
                            code: to.query.code,
                            state: to.query.state
                        }
                    });
                }
            } else {
                if (subdomain == 'admin' || to.fullPath == '/adminlogoutcallback') {
                    router.push({
                        name: "AdminLogin"
                    });
                } else {
                    router.push({
                        name: "SignIn"
                    });
                }
            }
        } else {
            if (to.meta.authrole !== undefined) {
                let hasRoleAccess = CheckRoleValidation(to.meta.authrole, store.getters.userRole)
                if (!hasRoleAccess) {
                    const toast = useToast();
                    if(store.getters.userRole == Role.InternalAdmin)
                    {
                        router.push({
                            name: "InternalAdminDashboard"
                        });
                    } else {
                        router.push({
                            name: "Summary"
                        });
                    }
                    
                    toast.warning("Role Unauthorized");
                }
            }
            return next();
        }
    } else {
        return next();
    }
});

function CheckRoleValidation(authRole, userRole) {
    let roleValidationMatch = false;
    authRole.forEach((role) => {
        switch (role) {
            case Role.InternalAdmin:
                if (roleValidationMatch == false) {
                    roleValidationMatch = ADMIN_ROLE_NAMES.includes(userRole);
                }
                break;
            case Role.ClientAdmin:
                if (roleValidationMatch == false) {
                    roleValidationMatch = CLIENT_ADMIN_ROLE_NAMES.includes(userRole);
                }
                break;
            case Role.ClientUser:
                if (roleValidationMatch == false) {
                    roleValidationMatch = CLIENT_USER_ROLE_NAMES.includes(userRole);
                }
                break;
            case Role.ClientReadOnly:
                if (roleValidationMatch == false) {
                    roleValidationMatch = CLIENT_READONLY_ROLE_NAMES.includes(userRole);
                }
                break;
            default:
                roleValidationMatch = false;
        }
    });
    return roleValidationMatch;
}

export default router;