export default {
    state: {
        userCreateDate: null,
    },

    getters: {
        userCreateDate: (state) => state.userCreateDate,
    },

    mutations: {
        setUserCreateDate: (state, userCreateDate) => {
            state.userCreateDate = userCreateDate;
        },
    },
};