<template>
  <div>
    <div class="pt-3">
      <div
        v-if="
          cardProcessingPriceProgramType ===
          CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment
        "
      >
        <NonCashAdjustmentModal
          :showModalProp="isNonCashAdjustmentModalVisible"
          :nonCashAdjustmentPercentProp="nonCashAdjustmentPercent"
          @resetIsVisible="onNonCashAdjustmentModalDataEvent()"
        />
      </div>
      <div
        v-if="
          cardProcessingPriceProgramType ===
          CARD_PROCESSING_PRICE_PROGRAM.Surcharge
        "
      >
        <Message :closable="false"
          >We assess a Credit Card Surcharge ("Surcharge") in accordance with
          major card brand rules. The Surcharge is applicable to credit
          transactions only and is not greater than our cost of
          acceptance.</Message
        >
      </div>
      <img
        class="card-logo-image pb-3"
        src="/images/evpay_assets/cc_logos.png"
        alt="card_logo"
        style="
          max-width: 20%;
          max-height: 20%;
          height: auto;
          margin: auto;
          display: flex;
          justify-content: center;
        "
      />
      <div class="formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="cardNumber">Card Number: *</label>
          <div>
            <InputNumber
              id="cardNumber"
              v-model="form.cardNumber"
              :format="false"
              class="inputfield w-full"
              @blur="onInputTextUpdateEvent($event)"
            />
          </div>
          <template v-if="v.cardNumber.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.cardNumber.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="cvvNumber">CVV Number: *</label>
          <div>
            <InputText
              id="cvvNumber"
              v-model="form.cvvNumber"
              type="text"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.cvvNumber.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.cvvNumber.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="expireMonth">Expiration Month: *</label>
          <div>
            <Dropdown
              v-model="form.selectedCardExpirationMonth"
              :options="cardExpirationMonths"
              optionLabel="name"
              optionValue="code"
              placeholder="Select Expiration Month"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.selectedCardExpirationMonth.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.selectedCardExpirationMonth.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="expireYear">Expiration Year: *</label>
          <div>
            <Dropdown
              v-model="form.selectedCardExpirationYear"
              :options="cardExpirationYears"
              optionLabel="name"
              optionValue="code"
              placeholder="Select Expiration Year"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.selectedCardExpirationYear.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.selectedCardExpirationYear.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="streetAddress">Street Address: *</label>
          <div>
            <InputText
              id="streetAddress"
              v-model="form.streetAddress"
              type="text"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.streetAddress.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.streetAddress.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="zipCode">Zip Code: *</label>
          <div>
            <InputText
              id="zipCode"
              v-model="form.zipCode"
              type="text"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.zipCode.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.zipCode.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>

        <div class="field col-12 md:col-6">
          <label for="emailAddress">Email Address: *</label>
          <div>
            <InputText
              id="emailAddress"
              v-model="form.emailAddress"
              type="text"
              class="inputfield w-full"
            />
          </div>
          <template v-if="v.emailAddress.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v.emailAddress.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>

      <div v-if="isGuestCheckoutLink === false">
        <span>
          <Checkbox v-model="storePaymentMethod" :binary="true" />
          Store this account for future payment.
        </span>
      </div>

      <div class="pt-3">
        <PaymentTermsAndConditions
          @isAgreementCheckboxClicked="onAgreementCheckboxDataEvent"
          :resetCheckBoxAgreementProp="isAgreementCheckboxClicked"
        />
      </div>

      <div
        class="pt-3 text-center"
        v-if="isGuestCheckoutLink === true && paymentMethod === 'Card'"
      >
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="recaptchaSiteKey"
          :load-recaptcha-script="true"
          @verify="onVerifyChallenge"
          @error="onErrorChallenge"
          size="invisible"
        >
        </vue-recaptcha>
        <Button
          class="justify-content-center button-submit-payment"
          label="Submit Payment"
          @click="onSubmitChallenge"
          :loading="isLoading"
        />
      </div>
      <div class="pt-3 text-center" v-if="isGuestCheckoutLink === false">
        <Button
          class="justify-content-center button-submit-payment"
          label="Submit Payment"
          @click="onSubmitPayment"
          :loading="isLoading"
        />
      </div>
      <div
        v-if="scheduledPaymentEnabled === true && isGuestCheckoutLink === false"
        class="pt-3 text-center"
      >
        <p><strong>OR</strong></p>
        <Button
          class="justify-content-center button-submit-payment"
          label="Schedule Payment"
          @click="showScheduledPaymentModal()"
          :loading="isLoading"
        />
      </div>
      <div>
        <SchedulePayment
          :isModalVisibleProp="isScheduledPaymentModalVisible"
          :paymentAccountProp="createPaymentDto"
          :paymentMethodProp="paymentMethod"
          :termsAndConditionsAcceptedProp="isAgreementCheckboxClicked"
          @resetScheduledPaymentModal="onScheduledPaymentModalDataEvent()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, toRef, computed, reactive } from "vue";
import { useToast } from "vue-toastification";
import store from "@/store";
import router from "@/router";
import paymentService from "@/services/payment";
import clientService from "@/services/client";
import invoiceService from "@/services/invoice";
import cardProcessingPriceProgramService from "@/services/cardProcessingPriceProgram";
import { CARD_PROCESSING_PRICE_PROGRAM } from "@/utility/cardProcessingPriceProgram";
import PaymentTermsAndConditions from "./PaymentTermsAndConditions.vue";
import NonCashAdjustmentModal from "./NonCashAdjustmentModal.vue";
import SchedulePayment from "./SchedulePayment.vue";
import { VueRecaptcha } from "vue-recaptcha";
import reCaptchaClient from "@/api/reCaptcha.api";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
  helpers,
} from "@vuelidate/validators";

export default {
  components: {
    PaymentTermsAndConditions,
    NonCashAdjustmentModal,
    SchedulePayment,
    "vue-recaptcha": VueRecaptcha,
  },
  props: {
    paymentMethodProp: {
      type: String,
    },
    cardProcessingPriceProgramTypeProp: {
      type: Number,
    },
    signedInvoiceIdProp: {
      type: String,
      default: null,
    },
  },
  emits: ["isRadioDisabled"],
  setup(props, { emit }) {
    const toast = useToast();
    const cardProcessingPriceProgramType = toRef(
      props,
      "cardProcessingPriceProgramTypeProp"
    );
    const paymentMethod = toRef(props, "paymentMethodProp");
    const signedInvoiceId = toRef(props, "signedInvoiceIdProp");

    const scheduledPaymentEnabled = computed(
      () => store.getters.scheduledPaymentEnabled
    );
    const nonCashAdjustmentPercent = computed(
      () => store.getters.nonCashAdjustmentPercent
    );
    const isGuestCheckoutLink = ref(signedInvoiceId.value ? true : false);
    const cardExpirationMonths = ref([]);
    const cardExpirationYears = ref([]);
    const isNonCashAdjustmentModalVisible = ref(false);
    const isScheduledPaymentModalVisible = ref(false);
    const storePaymentMethod = ref(false);
    const isLoading = ref(false);
    const isAgreementCheckboxClicked = ref(false);
    const initialFormState = {
      cardNumber: undefined,
      cvvNumber: "",
      selectedCardExpirationMonth: "",
      selectedCardExpirationYear: "",
      streetAddress: "",
      zipCode: "",
      emailAddress: "",
    };
    const form = reactive({ ...initialFormState });
    let createPaymentDto = computed(() => {
      return {
        paymentType: paymentMethod.value,
        paymentUserEmail: form.emailAddress,
        termsAndConditionsAccepted: isAgreementCheckboxClicked.value,
        cardAccount: {
          number: form.cardNumber?.toString(),
          expiryMonth: form.selectedCardExpirationMonth,
          expiryYear: form.selectedCardExpirationYear,
          avsStreet: form.streetAddress,
          avsZip: form.zipCode,
          cvv: form.cvvNumber,
        },
      };
    });

    const recaptcha = ref(null);
    const recaptchaSiteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    });

    async function fetchExpirationMonths() {
      cardExpirationMonths.value =
        await paymentService.fetchCardExpirationMonths();
    }
    async function fetchExpirationYears() {
      cardExpirationYears.value =
        await paymentService.fetchCardExpirationYears();
    }
    async function fetchInvoicesNonCashAdjustmentsAndTotal() {
      if (
        cardProcessingPriceProgramType.value ===
        CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment
      ) {
        isNonCashAdjustmentModalVisible.value = true;
        if (isGuestCheckoutLink.value === true) {
          await cardProcessingPriceProgramService.getInvoiceNonCashAdjustmentFromSignedInvoiceId(
            signedInvoiceId.value
          );
        } else {
          await cardProcessingPriceProgramService.getInvoiceNonCashAdjustment();
        }
      }
    }
    async function fetchInvoicesSurchargeAndTotal(bin) {
      try {
        if (isGuestCheckoutLink.value === true) {
          await cardProcessingPriceProgramService.getInvoiceSurchargeFromSignedInvoiceId(
            signedInvoiceId.value,
            bin
          );
        } else {
          await cardProcessingPriceProgramService.getInvoiceSurcharge(bin);
        }
      } catch (error) {
        toast.error(error);
        form.cardNumber = undefined;
        return;
      }
    }

    async function onSubmitPayment() {
      if (!(await v.value.$validate())) {
        return;
      } else {
        isLoading.value = true;
        emit("isRadioDisabled", true);

        if (isGuestCheckoutLink.value === true) {
          try {
            await paymentService.createInvoicePaymentFromSignedInvoiceId(
              signedInvoiceId.value,
              createPaymentDto.value
            );
          } catch (error) {
            await onErrorChallenge();
            return resetFormState();
          }
        } else {
          try {
            await paymentService.createClientInvoicePayment(
              createPaymentDto.value
            );
          } catch (error) {
            return resetFormState();
          }
        }

        if (storePaymentMethod.value === true) {
          const cardAccountDto = {
            cardAccount: {
              number: form.cardNumber,
              expiryMonth: form.selectedCardExpirationMonth,
              expiryYear: form.selectedCardExpirationYear,
              avsStreet: form.streetAddress,
              avsZip: form.zipCode,
              cvv: form.cvvNumber,
            },
          };
          await clientService.createClientVaultedAccount(cardAccountDto);
        }
        isLoading.value = false;
        emit("isRadioDisabled", false);

        if (isGuestCheckoutLink.value === true) {
          await router.push({ path: "/guest-checkout-payment-confirmation" });
        } else {
          await router.push({ path: "/payment-confirmation" });
        }
      }
    }

    async function showScheduledPaymentModal() {
      if (!(await v.value.$validate())) return;
      isScheduledPaymentModalVisible.value = true;
    }

    async function onSubmitChallenge() {
      if (!(await v.value.$validate())) {
        return;
      }
      await recaptcha.value.execute();
    }
    async function onVerifyChallenge(challengeResult) {
      isLoading.value = true;
      const response = await reCaptchaClient.postSiteVerify({
        response: challengeResult,
      });
      if (response && response.data.success === false) {
        await recaptcha.value.reset();
      } else {
        await onSubmitPayment();
      }
      isLoading.value = false;
    }
    async function onErrorChallenge() {
      await recaptcha.value.reset();
    }

    function onScheduledPaymentModalDataEvent() {
      setTimeout((isScheduledPaymentModalVisible.value = false), 1500);
    }

    function onNonCashAdjustmentModalDataEvent() {
      setTimeout((isNonCashAdjustmentModalVisible.value = false), 1500);
    }

    function onAgreementCheckboxDataEvent(event) {
      isAgreementCheckboxClicked.value = event;
    }

    function resetFormState() {
      isAgreementCheckboxClicked.value = false;
      emit("isRadioDisabled", false);
      v.value.$reset();
      Object.assign(form, initialFormState);
      isLoading.value = false;
    }

    async function onInputTextUpdateEvent(event) {
      if (
        cardProcessingPriceProgramType.value ===
          CARD_PROCESSING_PRICE_PROGRAM.Surcharge &&
        event.value.length >= 6
      ) {
        const bin = event.value.toString().substring(0, 6);
        await fetchInvoicesSurchargeAndTotal(bin);
      }

      if (
        cardProcessingPriceProgramType.value ===
          CARD_PROCESSING_PRICE_PROGRAM.Surcharge &&
        form.cardNumber !== null &&
        !event.value
      ) {
        await invoiceService.reloadInvoicesAsync();
      }
    }

    onMounted(fetchExpirationYears);
    onMounted(fetchExpirationMonths);
    onMounted(fetchInvoicesNonCashAdjustmentsAndTotal);

    const rules = computed(() => ({
      cardNumber: {
        numeric: helpers.withMessage("Card Number must be numeric", numeric),
        minLength: helpers.withMessage(
          "Card Number must be at least 12 digits",
          minLength(12)
        ),
        maxLength: helpers.withMessage(
          "Card Number cannot be more than 19 digits",
          maxLength(19)
        ),
        required: helpers.withMessage("Card Number is required", required),
      },
      cvvNumber: {
        numeric: helpers.withMessage("CVV Number must be numeric", numeric),
        minLength: helpers.withMessage(
          "CVV Number must be at least 3 digits",
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          "CVV Number cannot be more than 4 digits",
          maxLength(4)
        ),
        required: helpers.withMessage("CVV Number is required", required),
      },
      selectedCardExpirationMonth: { required },
      selectedCardExpirationYear: { required },
      streetAddress: {
        minLength: helpers.withMessage(
          "Street Address must be at least 2 characters",
          minLength(2)
        ),
        maxLength: helpers.withMessage(
          "Street Address cannot be more than 50 characters",
          maxLength(50)
        ),
        required: helpers.withMessage("Street Address is required", required),
      },
      zipCode: {
        numeric: helpers.withMessage("Zip Code must be numeric", numeric),
        minLength: helpers.withMessage(
          "Zip Code must be 5 digits",
          minLength(5)
        ),
        maxLength: helpers.withMessage(
          "Zip Code must be 5 digits",
          maxLength(5)
        ),
        required: helpers.withMessage("Zip Code is required", required),
      },
      emailAddress: {
        email: helpers.withMessage("Email Address is not valid", email),
        required: helpers.withMessage("Email Address is required", required),
      },
    }));
    const v = useVuelidate(rules, form, { $lazy: true });

    return {
      CARD_PROCESSING_PRICE_PROGRAM,
      cardProcessingPriceProgramType,
      scheduledPaymentEnabled,
      nonCashAdjustmentPercent,
      isGuestCheckoutLink,
      cardExpirationMonths,
      cardExpirationYears,
      isLoading,
      isNonCashAdjustmentModalVisible,
      isScheduledPaymentModalVisible,
      storePaymentMethod,
      form,
      createPaymentDto,
      paymentMethod,
      isAgreementCheckboxClicked,
      recaptcha,
      recaptchaSiteKey,
      onVerifyChallenge,
      onSubmitChallenge,
      onErrorChallenge,
      showScheduledPaymentModal,
      onScheduledPaymentModalDataEvent,
      onNonCashAdjustmentModalDataEvent,
      onSubmitPayment,
      onAgreementCheckboxDataEvent,
      onInputTextUpdateEvent,
      v,
    };
  },
};
</script>
