<template>
  <div>
    <div
      v-if="
        cardProcessingPriceProgramType ===
          CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment &&
        selectedPaymentMethod &&
        selectedPaymentMethod.cardType
      "
    >
      <NonCashAdjustmentModal
        :showModalProp="isModalVisible"
        :nonCashAdjustmentPercentProp="nonCashAdjustmentPercent"
        @resetIsVisible="onNonCashAdjustmentModalDataEvent()"
      />
    </div>
    <div class="payment-detail" v-if="storedAccountsStore.length">
      <div class="field md:col-5">
        <label for="emailAddress">Payment Confirmation Email Address: *</label>
        <div>
          <InputText
            id="emailAddress"
            v-model="emailAddress"
            type="text"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.emailAddress.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.emailAddress.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="client-datatable">
        <div v-if="storedBankAccounts && storedBankAccounts.length">
          <DataTable
            :value="storedBankAccounts"
            :loading="isLoading"
            v-model:selection="selectedPaymentMethod"
            v-if="storedBankAccounts && storedBankAccounts.length"
            dataKey="id"
            showGridlines
            stripedRows
            autoLayout
            responsiveLayout="stack"
            breakpoint="960px"
            @row-select="
              isModalVisibleMultiAccountSelectorEvent(selectedPaymentMethod)
            "
          >
            <h5 class="pt-3">Stored Bank Accounts</h5>

            <Column
              v-if="hasAchActive"
              selectionMode="single"
              field="paymentMethod"
              header="Select method:"
              headerStyle="width: 25%"
            ></Column>
            <Column
              field="type"
              header="Type"
              headerStyle="width: 25%"
            ></Column>
            <Column
              field="last4"
              header="Last 4"
              headerStyle="width: 25%"
            ></Column>
            <Column header="Default" headerStyle="width: 25%">
              <template #body="slotProps">
                <Tag
                  v-if="slotProps.data.isDefault"
                  value="Yes"
                  icon="pi pi-check-square"
                  severity="success"
                  rounded
                ></Tag>
              </template>
            </Column>
          </DataTable>
        </div>
        <br />
        <div>
          <DataTable
            :value="storedCardAccounts"
            :loading="isLoading"
            v-model:selection="selectedPaymentMethod"
            v-if="storedCardAccounts && storedCardAccounts.length"
            dataKey="id"
            showGridlines
            stripedRows
            autoLayout
            responsiveLayout="stack"
            breakpoint="960px"
            @row-select="
              isModalVisibleMultiAccountSelectorEvent(selectedPaymentMethod)
            "
          >
            <h5>Stored Card Accounts</h5>
            <div
              v-if="
                cardProcessingPriceProgramType ===
                CARD_PROCESSING_PRICE_PROGRAM.Surcharge
              "
            >
              <Message :closable="false"
                >We assess a Credit Card Surcharge ("Surcharge") in accordance
                with major card brand rules. The Surcharge is applicable to
                credit transactions only and is not greater than our cost of
                acceptance.</Message
              >
            </div>
            <Column
              v-if="hasCardActive"
              selectionMode="single"
              field="paymentMethod"
              header="Select method:"
              headerStyle="width: 25%"
            ></Column>
            <Column
              field="paymentMethod"
              header="Method"
              headerStyle="width: 25%"
            ></Column>
            <Column
              field="expiration"
              header="Expiration"
              headerStyle="width: 25%"
            ></Column>
            <Column header="Default" headerStyle="width: 25%">
              <template #body="slotProps">
                <Tag
                  v-if="slotProps.data.isDefault"
                  value="Yes"
                  icon="pi pi-check-square"
                  severity="success"
                  rounded
                ></Tag>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="pt-3">
          <PaymentTermsAndConditions
            @isAgreementCheckboxClicked="onAgreementCheckboxDataEvent"
          />
        </div>
        <div class="pt-3 text-center">
          <Button
            class="justify-content-center button-submit-payment"
            label="Submit Payment"
            @click="onSubmitPayment()"
            :loading="isLoading"
          />
        </div>
        <div v-if="scheduledPaymentEnabled === true" class="pt-3 text-center">
          <p><strong>OR</strong></p>
          <Button
            class="justify-content-center button-schedule-payment"
            label="Schedule Payment"
            @click="showScheduledPaymentModal()"
            :loading="isLoading"
          />
        </div>
        <div>
          <SchedulePayment
            :isModalVisibleProp="isScheduledPaymentModalVisible"
            :paymentAccountProp="createPaymentDto"
            :paymentMethodProp="paymentMethod"
            :termsAndConditionsAcceptedProp="isAgreementCheckboxClicked"
            @resetScheduledPaymentModal="onScheduledPaymentModalDataEvent()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted, computed } from "vue";
import router from "@/router";
import store from "@/store";
import paymentService from "@/services/payment";
import clientService from "@/services/client";
import invoiceService from "@/services/invoice";
import cardProcessingPriceProgramService from "@/services/cardProcessingPriceProgram";
import { CARD_PROCESSING_PRICE_PROGRAM } from "@/utility/cardProcessingPriceProgram";
import PaymentTermsAndConditions from "./PaymentTermsAndConditions.vue";
import NonCashAdjustmentModal from "./NonCashAdjustmentModal.vue";
import SchedulePayment from "./SchedulePayment.vue";
import useVuelidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";

export default {
  components: {
    NonCashAdjustmentModal,
    PaymentTermsAndConditions,
    SchedulePayment,
  },
  props: {
    paymentMethodProp: {
      type: String,
    },
    cardProcessingPriceProgramTypeProp: {
      type: Number,
    },
  },
  emits: ["isRadioDisabled", "reloadSelectedPaymentMethod"],
  setup(props, { emit }) {
    const paymentMethod = toRef(props, "paymentMethodProp");
    const cardProcessingPriceProgramType = toRef(
      props,
      "cardProcessingPriceProgramTypeProp"
    );

    const scheduledPaymentEnabled = computed(
      () => store.getters.scheduledPaymentEnabled
    );
    const nonCashAdjustmentPercent = computed(
      () => store.getters.nonCashAdjustmentPercent
    );
    const storedAccountsStore = computed(() => store.getters.accounts);
    const confirmationEmailAddress = ref(store.getters.emailAddress);
    const emailAddress = computed({
      get: () => confirmationEmailAddress.value,
      set: (value) => {
        confirmationEmailAddress.value = value;
      },
    });
    const hasCardActive = computed(() => store.getters.enableCC);
    const hasAchActive = computed(() => store.getters.enableACH);

    const selectedPaymentMethod = ref({});
    const storedBankAccounts = ref([]);
    const storedCardAccounts = ref([]);
    const isScheduledPaymentModalVisible = ref(false);
    const isLoading = ref(false);
    const isModalVisible = ref(true);
    const createPaymentDto = ref();
    const isAgreementCheckboxClicked = ref(false);

    async function onSubmitPayment() {
      if (!(await v.value.$validate())) {
        return;
      } else {
        isLoading.value = true;
        emit("isRadioDisabled", true);

        if (selectedPaymentMethod.value.cardType) {
          createPaymentDto.value = {
            paymentType: "Card",
            paymentUserEmail: emailAddress.value,
            termsAndConditionsAccepted: isAgreementCheckboxClicked.value,
            cardAccount: {
              token: selectedPaymentMethod.value.token,
              customerId: selectedPaymentMethod.value.customerId,
              cardId: selectedPaymentMethod.value.id,
            },
          };
        } else if (selectedPaymentMethod.value.type) {
          createPaymentDto.value = {
            paymentType: "ACH",
            paymentUserEmail: emailAddress.value,
            termsAndConditionsAccepted: isAgreementCheckboxClicked.value,
            bankAccount: {
              token: selectedPaymentMethod.value.token,
            },
          };
        } else {
          return;
        }
        await paymentService.createClientInvoicePayment(createPaymentDto.value);

        isLoading.value = false;
        emit("isRadioDisabled", false);
        await router.push({ path: "/payment-confirmation" });
      }
    }

    async function isModalVisibleMultiAccountSelectorEvent(event) {
      await invoiceService.reloadInvoicesAsync();
      if (
        event &&
        event.cardType &&
        cardProcessingPriceProgramType.value ===
          CARD_PROCESSING_PRICE_PROGRAM.Surcharge
      ) {
        isLoading.value = true;
        emit("isRadioDisabled", true);
        await cardProcessingPriceProgramService.getInvoiceSurcharge(
          undefined,
          event.customerId,
          event.id
        );
        isLoading.value = false;
        emit("isRadioDisabled", false);
      }
      if (
        event &&
        event.cardType &&
        cardProcessingPriceProgramType.value ===
          CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment
      ) {
        isLoading.value = true;
        emit("isRadioDisabled", true);
        await cardProcessingPriceProgramService.getInvoiceNonCashAdjustment();
        setTimeout((isModalVisible.value = true), 1500);
        isLoading.value = false;
        emit("isRadioDisabled", false);
      } else {
        isLoading.value = true;
        emit("isRadioDisabled", true);
        setTimeout(isModalVisible.value === false, 1500);
        isLoading.value = false;
        emit("isRadioDisabled", false);
      }
    }

    async function showScheduledPaymentModal() {
      if (!(await v.value.$validate())) return;
      if (selectedPaymentMethod.value.cardType) {
        createPaymentDto.value = {
          paymentType: "Card",
          paymentUserEmail: emailAddress.value,
          cardAccount: {
            type: selectedPaymentMethod.value.cardType,
            last4: selectedPaymentMethod.value.last4,
            token: selectedPaymentMethod.value.token,
            customerId: selectedPaymentMethod.value.customerId.toString(),
            cardId: selectedPaymentMethod.value.id.toString(),
          },
        };
      } else if (selectedPaymentMethod.value.type) {
        createPaymentDto.value = {
          paymentType: "ACH",
          paymentUserEmail: emailAddress.value,
          bankAccount: {
            type: selectedPaymentMethod.value.type,
            last4: selectedPaymentMethod.value.last4,
            token: selectedPaymentMethod.value.token,
          },
        };
      } else {
        return;
      }
      isScheduledPaymentModalVisible.value = true;
    }

    function onScheduledPaymentModalDataEvent() {
      setTimeout((isScheduledPaymentModalVisible.value = false), 1500);
    }

    function onAgreementCheckboxDataEvent(event) {
      isAgreementCheckboxClicked.value = event;
    }

    function onNonCashAdjustmentModalDataEvent() {
      isModalVisible.value = false;
    }

    async function fetchStoredPaymentMethods() {
      storedBankAccounts.value = await clientService.fetchStoredBankAccounts(
        storedAccountsStore.value
      );

      let storedCards = await clientService.fetchStoredCardAccounts(
        storedAccountsStore.value
      );

      storedCards.forEach((result) => {
        result.paymentMethod =
          (result.cardType == null ? "" : result.cardType) +
          " - " +
          (result.last4 == null ? "" : result.last4);
        result.expiration =
          (result.expiryMonth == null ? "" : result.expiryMonth) +
          "/" +
          (result.expiryYear == null ? "" : result.expiryYear);
        storedCardAccounts.value.push(result);
      });

      if (
        !storedBankAccounts.value.length &&
        !storedCardAccounts.value.length
      ) {
        setTimeout(emit("reloadSelectedPaymentMethod", true), 1000);
      } else {
        selectedPaymentMethod.value =
          await clientService.fetchDefaultStoredAccount();
        if (
          selectedPaymentMethod.value &&
          selectedPaymentMethod.value.cardType
        ) {
          isModalVisibleMultiAccountSelectorEvent(selectedPaymentMethod.value);
        }
      }
    }

    onMounted(fetchStoredPaymentMethods);

    const rules = computed(() => ({
      emailAddress: {
        email: helpers.withMessage("Email Address is not valid", email),
        required: helpers.withMessage("Email Address is required", required),
      },
    }));

    const v = useVuelidate(
      rules,
      {
        emailAddress,
      },
      { $lazy: true }
    );

    return {
      CARD_PROCESSING_PRICE_PROGRAM,
      scheduledPaymentEnabled,
      nonCashAdjustmentPercent,
      cardProcessingPriceProgramType,
      paymentMethod,
      selectedPaymentMethod,
      storedAccountsStore,
      storedBankAccounts,
      storedCardAccounts,
      emailAddress,
      createPaymentDto,
      hasCardActive,
      hasAchActive,
      isLoading,
      isModalVisible,
      isScheduledPaymentModalVisible,
      isAgreementCheckboxClicked,
      onSubmitPayment,
      onNonCashAdjustmentModalDataEvent,
      isModalVisibleMultiAccountSelectorEvent,
      showScheduledPaymentModal,
      onScheduledPaymentModalDataEvent,
      onAgreementCheckboxDataEvent,
      v,
    };
  },
};
</script>
