import store from "../store";

export default {
    authenticate: function () {
        var domain = process.env.VUE_APP_USER_POOL_DOMAIN;
        var clientId = process.env.VUE_APP_USER_POOL_CLIENT_ID;
        var type = "code";

        var callback = window.location.protocol + "//" + window.location.host + "/";
        store.commit("setCallbackUri", callback);

        var verification = generateVerification();
        store.commit("setVerification", verification);

        window.location.href =
            "https://" +
            domain +
            "/login?response_type=" +
            type +
            "&client_id=" +
            clientId +
            "&redirect_uri=" +
            callback +
            "&state=" +
            verification;
    },
    adminAuthenticate: function () {
        var domain = process.env.VUE_APP_USER_POOL_DOMAIN;
        var clientId = process.env.VUE_APP_MANAGER_CLIENT_ID;
        var type = "code";
        var callback = window.location.protocol + "//" + window.location.host + "/";
        store.commit("setCallbackUri", callback);

        var verification = generateVerification();
        store.commit("setVerification", verification);

        window.location.href =
            "https://" +
            domain +
            "/login?response_type=" +
            type +
            "&client_id=" +
            clientId +
            "&redirect_uri=" +
            callback +
            "&state=" +
            verification;
    },
    logout: function () {
        var domain = process.env.VUE_APP_USER_POOL_DOMAIN;
        var clientId = process.env.VUE_APP_USER_POOL_CLIENT_ID;
        var callback =
            window.location.protocol +
            "//" +
            window.location.host +
            "/logoutcallback";

        window.location.href =
            "https://" +
            domain +
            "/logout?client_id=" +
            clientId +
            "&logout_uri=" +
            callback;
    },
    adminLogout: function () {
        var domain = process.env.VUE_APP_USER_POOL_DOMAIN;
        var clientId = process.env.VUE_APP_MANAGER_CLIENT_ID;
        var callback =
            window.location.protocol +
            "//" +
            window.location.host +
            "/adminlogoutcallback";

        window.location.href =
            "https://" +
            domain +
            "/logout?client_id=" +
            clientId +
            "&logout_uri=" +
            callback;
    },
};

function generateVerification() {
    var verification = "";
    var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 32; i++) {
        verification += possible.charAt(
            Math.floor(Math.random() * possible.length)
        );
    }
    return verification;
}