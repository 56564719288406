export default {
    state: {
        accounts: [],
    },

    getters: {
        accounts: (state) => state.accounts,
    },

    mutations: {
        setAccounts: (state, accounts) => {
            state.accounts = accounts;
        },
        clearAccounts: (state, payment) => {
            state.accounts = payment;
        },
    },

    actions: {
        flushAccountsStore: function(context) {
            context.commit("clearAccounts", []);
        },
    },
};