<template>
  <div><AdminUpdateUserInformation /></div>
</template>

<script>
import AdminUpdateUserInformation from "../components/Client/AdminUpdateUserInformation.vue";

export default {
  components: {
    AdminUpdateUserInformation,
  },
};
</script>