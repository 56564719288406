<template>
  <div class="card ev-fluid-card-wide">
    <DataTable
      :value="tenants"
      :paginator="true"
      :rows="15"
      :loading="isLoading"
      :globalFilterFields="['tenantId', 'tenantName', 'enabled']"
      :multiSortMeta="[{ field: 'tenantName', order: 1 }]"
      sortMode="multiple"
      class="p-datatable-customers"
      responsiveLayout="scroll"
      dataKey="tenantId"
      v-model:filters="filters"
      filterDisplay="menu"
    >
      <template #header>
        <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </template>
      <template #loading>
        <p style="text-align: center">Loading tenants...</p>
      </template>
      <Column
        header="TenantId"
        field="tenantId"
        style="min-width: 10rem"
        :sortable="true"
      >
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.tenantId }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Search by TenantId"
          />
        </template>
      </Column>
      <Column
        header="Tenant Name"
        field="tenantName"
        style="min-width: 10rem"
        :sortable="true"
      >
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.tenantName }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            placeholder="Search by tenant name"
          />
        </template>
      </Column>
      <Column
        header="Enabled"
        field="enabled"
        style="min-width: 10rem"
        :sortable="true"
      >
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.enabled }}</span>
        </template>
      </Column>
      <Column bodyStyle="text-align:left">
        <template #body="slotProps">
          <Button
            v-tooltip="'View Tenant'"
            icon="pi pi-pencil"
            @click="viewTenant(slotProps.data.tenantId)"
          />
        </template>
      </Column>
      <template #empty>
        <p style="text-align: center">No records found.</p>
      </template>
    </DataTable>
    <div>
      <Button label="Add New Tenant" @click="showTenantModal()" />
    </div>
    <div>
      <TenantCreation
        :isModalVisibleProp="isTenantModalVisible"
        :tenantsProp="tenants"
        @resetTenantModal="onTenantModalDataEvent()"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import tenantService from "@/services/Admin/tenant";
import TenantCreation from "./TenantCreation.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import router from "@/router";

export default {
  name: "InternalAdminDashboard",
  components: {
    TenantCreation,
  },
  setup() {
    const tenants = ref([]);
    const isLoading = ref(true);
    const isTenantModalVisible = ref(false);

    const fetchAllTenants = async () => {
      tenants.value = await tenantService.getAllTenants();
      isLoading.value = false;
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      tenantId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      tenantName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    onMounted(fetchAllTenants);

    async function viewTenant(tenantId) {
      await router.push({
        name: "TenantCompanies",
        params: { tenantId: tenantId },
      });
    }

    function showTenantModal() {
      isTenantModalVisible.value = true;
    }

    function onTenantModalDataEvent() {
      fetchAllTenants();
      setTimeout((isTenantModalVisible.value = false), 1500);
    }

    return {
      tenants,
      isLoading,
      filters,
      isTenantModalVisible,
      onTenantModalDataEvent,
      showTenantModal,
      viewTenant,
    };
  },
};
</script>
