"use strict";
import httpClient from "./httpClient";

export default {
    async postSiteVerify(userResponseToken) {
        try {
            var response = await httpClient.post("/recaptcha", userResponseToken, {
                baseURL: process.env.VUE_APP_RECAPTCHA_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            });
        } catch (error) {
            response = error.response;
        }
        return response;
    },
}