import store from "../store";
import paymentProviderSettingsClient from "../api/paymentProviderSettings.api";
import { ADMIN_ROLE_NAMES } from "../utility/role";

export default {
    fetchPaymentProviderDetail: async function () {
        let response;
        try {

            if (store.getters.isAuthenticated === true && ADMIN_ROLE_NAMES.includes(store.getters.userRole) === false) {
                const tenantId = store.getters.tenantId;
                const paymentProviderSettingsId =
                    store.getters.paymentProviderSettingsId;
                response =
                    await paymentProviderSettingsClient.getPaymentProviderSettingsById(
                        tenantId,
                        paymentProviderSettingsId
                    );
            }

            if (response && response.status === 200) {
                store.dispatch("paymentProviderSettingsDetail", {
                    imagePath: response.data.imagePath,
                    name: response.data.name,
                    subDomain: response.data.subDomain,
                    addressLine1: response.data.address.addressLine1,
                    addressLine2: response.data.address.addressLine2,
                    city: response.data.address.city,
                    zipCode: response.data.address.zipCode,
                    state: response.data.address.state,
                    phoneNumber: response.data.address.phoneNumber,
                    cardProcessingPriceProgramType: response.data.cardProcessingPriceProgramType,
                    paymentConfirmationMessage: response.data.paymentConfirmationMessage,
                    paymentPageMessage: response.data.paymentPageMessage,
                    contactEmailAddress: response.data.address.contactEmailAddress,
                    scheduledPaymentEnabled: response.data.scheduledPaymentEnabled,
                    invoiceFileViewerEnabled: response.data.invoiceFileViewerEnabled,
                    combinedInvoiceFormatEnabled: response.data.combinedInvoiceFormatEnabled,
                    nonCashAdjustmentPercent: response.data.nonCashAdjustmentPercent,
                });
            } else {
                return await store.state.pps;
            }

        } catch (error) {
            const ex = new Error("Unable to retrieve payment provider settings");
            throw ex.message;
        }

        return response.data;
    },
    fetchMerchantSettings: async function () {
        try {
            if (store.getters.isAuthenticated === true) {
                const tenantId = store.getters.tenantId;
                const paymentProviderSettingsId =
                    store.getters.paymentProviderSettingsId;
                var response = await paymentProviderSettingsClient.getMerchantSettings(
                    tenantId,
                    paymentProviderSettingsId
                );
            }

            if (response && response.status === 200) {
                store.commit("setEnableACH", response.data.enableAchPayments);
                store.commit("setEnableCC", response.data.enableCardPayments);
            } else {
                return await store.state.pps;
            }
        } catch (error) {
            Promise.reject("Unable to retrieve merchant settings - " + error);
        }
        return response.status;
    },
    fetchPaymentProviderSettingsBySignedInvoiceId: async function (signedInvoiceId) {
        let response;
        try {
            response = await paymentProviderSettingsClient.getPaymentProviderSettingsBySignedInvoiceId(signedInvoiceId);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve payment provider settings");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve payment provider settings");
            throw ex.message;
        }

        store.dispatch("paymentProviderSettingsDetail", {
            imagePath: response.data.imagePath,
            name: response.data.name,
            subDomain: response.data.subDomain,
            addressLine1: response.data.address.addressLine1,
            addressLine2: response.data.address.addressLine2,
            city: response.data.address.city,
            zipCode: response.data.address.zipCode,
            state: response.data.address.state,
            phoneNumber: response.data.address.phoneNumber,
            cardProcessingPriceProgramType: response.data.cardProcessingPriceProgramType,
            paymentConfirmationMessage: response.data.paymentConfirmationMessage,
            paymentPageMessage: response.data.paymentPageMessage,
            contactEmailAddress: response.data.address.contactEmailAddress,
            scheduledPaymentEnabled: response.data.scheduledPaymentEnabled,
            invoiceFileViewerEnabled: response.data.invoiceFileViewerEnabled,
            combinedInvoiceFormatEnabled: response.data.combinedInvoiceFormatEnabled,
            nonCashAdjustmentPercent: response.data.nonCashAdjustmentPercent,
        });
        store.commit("setPaymentProviderSettingsId", response.data.paymentProviderSettingsId);
        store.commit("setTenantId", response.data.tenantId);

        return response.data;
    },
    fetchMerchantSettingsBySignedInvoiceId: async function (signedInvoiceId) {
        let response;

        try {
            response = await paymentProviderSettingsClient.getMerchantSettingsBySignedInvoiceId(signedInvoiceId);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve merchant settings");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve merchant settings");
            throw ex.message;
        }
        store.commit("setEnableACH", response.data.enableAchPayments);
        store.commit("setEnableCC", response.data.enableCardPayments);

        return response.status;
    }
};