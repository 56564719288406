export default {
    state: {
        payments: [],
        clientPaymentPaginationToken: null
    },

    getters: {
        payments: (state) => state.payments,
        clientPaymentPaginationToken: (state) => state.clientPaymentPaginationToken
    },

    mutations: {
        setPaymentData: (state, payment) => {
            state.payments.push(payment);
        },
        clearPaymentData: (state, payment) => {
            state.payments = payment;
        },
        setClientPaymentPaginationToken: (state, clientPaymentPaginationToken) => {
            state.clientPaymentPaginationToken = clientPaymentPaginationToken
        }
    },

    actions: {
        flushPaymentsStore: function (context) {
            context.commit("clearPaymentData", []);
        },
    },
};