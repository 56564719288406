<template>
  <div class="card ev-fluid-card">
    <div class="grid">
      <div class="col-12">
        <div id="webviewercontainer">
          <WebViewer :initialDoc="initialDocument" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef, defineAsyncComponent } from "vue";

const WebViewer = defineAsyncComponent(() => import('./WebViewer.vue'));

export default {
  name: "WebViewerWrapper",
  components: {
    WebViewer,
  },
  props: {
    initialDoc: {
      type: String,
    },
  },
  setup(props) {
    const initialDocument = toRef(props, "initialDoc");

    return {
      initialDocument
    };
  }
};
</script>