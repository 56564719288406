import paymentProviderSettingsClient from "@/api/Admin/paymentProviderSettings.api";
import erpIntegrationSettingsService from "../Admin/erpIntegrationSettings";

export default {
    createPaymentProviderSettings: async function (tenantId, createPaymentProviderSettingsDto) {
        let response;
        try {
            response = await paymentProviderSettingsClient.createPaymentProviderSettings(
                tenantId,
                createPaymentProviderSettingsDto
            );
        } catch (error) {
            const ex = new Error("Unable to create payment provider settings");
            throw ex.message;
        }

        if (response && response.status !== 201) {
            const ex = new Error("Unable to create payment provider settings");
            throw ex.message;
        }

        return response.data;
    },

    getTenantPaymentProviderSettingsDetail: async function (tenantId) {
        let response;
        try {
            let erpIntegrationSettings = await erpIntegrationSettingsService.getErpIntegrationSettingsByTenantId(tenantId);
            response = await this.getPaymentProviderSettingsByTenantId(tenantId);

            response.forEach((paymentProviderSettings) => {
                let result = erpIntegrationSettings.find(x => x.paymentProviderSettingsId == paymentProviderSettings.paymentProviderSettingsId);
                if (result != null) {
                    paymentProviderSettings.erpIntegrationSettingsId = result.erpIntegrationSettingsId;
                }
            });
        } catch (error) {
            const ex = new Error("Unable to retrieve payment provider settings");
            throw ex.message;
        }
        return response;
    },

    getPaymentProviderSettingsByTenantId: async function (tenantId) {
        let response;
        try {
            response = await paymentProviderSettingsClient.getPaymentProviderSettingsByTenantId(tenantId);
        } catch (error) {
            const ex = new Error("Unable to retrieve paymentProviderSettings");
            throw ex.message;
        }
        return response.data;
    },

    getPaymentProviderSettingsById: async function (tenantId, paymentProviderSettingsId) {
        let response;
        try {
            response = await paymentProviderSettingsClient.getPaymentProviderSettingsById(tenantId, paymentProviderSettingsId);
        } catch (error) {
            const ex = new Error("Unable to retrieve paymentProviderSettings");
            throw ex.message;
        }
        return response.data;
    },

    getMerchantSettings: async function (tenantId, paymentProviderSettingsId) {
        let response = await paymentProviderSettingsClient.getMerchantSettings(tenantId, paymentProviderSettingsId);
        return response.data;
    },

    updateMerchantSettings: async function (tenantId, paymentProviderSettingsId, merchantSettingsDto) {
        let response;
        try {
            response = await paymentProviderSettingsClient.updateMerchantSettings(
                tenantId,
                paymentProviderSettingsId,
                merchantSettingsDto
            );
        } catch (error) {
            const ex = new Error("Unable to update merchant settings");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to update merchant settings");
            throw ex.message;
        }
        return response.status;
    },

    updatePaymentProviderSettings: async function (tenantId, paymentProviderSettingsId, keyValuesToUpdateData) {
        let response;
        try {
            let updatePaymentProviderSettingsDto = buildUpdatePaymentProviderSettingsJsonDocument(
                keyValuesToUpdateData
            );

            response = await paymentProviderSettingsClient.updatePaymentProviderSettings(
                tenantId,
                paymentProviderSettingsId,
                updatePaymentProviderSettingsDto
            );
        } catch (error) {
            const ex = new Error("Unable to update payment provider settings");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to update payment provider settings");
            throw ex.message;
        }
        return response.data;
    },

    uploadLogo: async function (tenantId, paymentProviderSettingsId, formData) {
        let response;
        try {
            response = await paymentProviderSettingsClient.uploadLogo(tenantId, paymentProviderSettingsId, formData);
        } catch (error) {
            const ex = new Error("Unable to upload logo");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to upload logo");
            throw ex.message;
        }

        return response.data;
    },

    updateNotificationEmailAddresses: async function (tenantId, paymentProviderSettingsId, emailDtos) {
        let response;
        try {
            response = await paymentProviderSettingsClient.updateNotificationEmailAddresses(tenantId, paymentProviderSettingsId, emailDtos);
        } catch (error) {
            const ex = new Error("Unable to update notification email address");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to update notification email address");
            throw ex.message;
        }
        return response.data;
    },
};

function buildUpdatePaymentProviderSettingsJsonDocument(keyValueData) {
    let jsonPatchDocument = [];
    for (const [key, value] of Object.entries(keyValueData)) {
        let jsonPatchObject = {
            op: "replace",
            path: "/" + key,
            value: value,
        };
        jsonPatchDocument.push(jsonPatchObject);
    }
    return jsonPatchDocument;
}