<template>
    <div>
      <Invoices />
    </div>
    
</template>

<script>
import Invoices from "../components/Client/Invoices.vue";

export default {
  components: {
    Invoices
  },
};
</script>
