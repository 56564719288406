<template>
  <div>
    <h2>Signing Out...</h2>
  </div>
</template>

<script>
import auth from "@/services/auth";
import store from "@/store";

export default {
  name: "AdminLogout",
  setup() {
    store.dispatch("signout");
    auth.adminLogout();
  },
};
</script>
