"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async getClientById(tenantId, clientId) {
        let response;
        try {
            const bearerToken = await store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}`,
                {
                    headers: {
                        "x-api-key": process.env.VUE_APP_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async getVaultedAccounts(tenantId, clientId, paymentProviderSettingsId) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-accounts?paymentProviderSettingsId=${paymentProviderSettingsId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async postVaultedAccount(tenantId, clientId, createVaultedAccountDto) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-accounts`,
                createVaultedAccountDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async putVaultedCardAccount(tenantId, clientId, updateVaultedCardAccountDto) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.put(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-card-accounts`,
                updateVaultedCardAccountDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async putVaultedBankAccount(tenantId, clientId, updateVaultedBankAccountDto) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.put(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-bank-accounts`,
                updateVaultedBankAccountDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async deleteVaultedCardAccount(tenantId, clientId, cardAccountDto) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.delete(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-card-accounts`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
                data: {
                    id: cardAccountDto.id,
                    cardType: cardAccountDto.cardType,
                    last4: cardAccountDto.last4,
                    token: cardAccountDto.token,
                    expiryMonth: cardAccountDto.expiryMonth,
                    expiryYear: cardAccountDto.expiryYear,
                    isDefault: cardAccountDto.isDefault,
                    paymentProviderSettingsId: cardAccountDto.paymentProviderSettingsId,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },

    async deleteVaultedBankAccount(tenantId, clientId, bankAccountDto) {
        try {
            const bearerToken = await store.getters.accessToken;
            var response = await httpClient.delete(
                `/tenants/${tenantId}/clients/${clientId}/vaulted-bank-accounts`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
                data: {
                    id: bankAccountDto.id,
                    type: bankAccountDto.type,
                    last4: bankAccountDto.last4,
                    token: bankAccountDto.token,
                    isDefault: bankAccountDto.isDefault,
                    paymentProviderSettingsId: bankAccountDto.paymentProviderSettingsId,
                },
            }
            );
        } catch (error) {
            response = await error.response;
        }
        return response;
    },
};