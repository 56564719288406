<template>
  <div>
    <div>
      <div class="pt-5">
        <div class="card ev-fluid-card">
          <GuestCheckoutHeader />
        </div>
      </div>
      <div class="pt-5" v-if="signedInvoiceId !== null">
        <PayInvoicesSummary
          @invoiceStatusEvent="isClientPaymentMethodVisibleEvent"
          :signedInvoiceIdProp="signedInvoiceId"
        />
      </div>
    </div>
    <div
      class="pt-5"
      v-if="
        signedInvoiceId != null &&
        (isClientPaymentMethodVisible === true || isVaultEnabled === true)
      "
    >
      <div class="card ev-fluid-card">
        <PaymentPortalTypeSelect
          :isInvoiceStatusPayableProp="isClientPaymentMethodVisible"
        />
      </div>
    </div>
    <div class="pt-5">
      <div class="card ev-fluid-card">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import store from "../store";
import GuestCheckoutHeader from "../components/Client/GuestCheckoutHeader.vue";
import PaymentPortalTypeSelect from "../components/Client/PaymentPortalTypeSelect.vue";
import PayInvoicesSummary from "../components/Client/PayInvoicesSummary.vue";
import AppFooter from "../AppFooter.vue";

export default {
  components: {
    GuestCheckoutHeader,
    PayInvoicesSummary,
    PaymentPortalTypeSelect,
    AppFooter,
  },
  setup() {
    const route = useRoute();
    const isClientPaymentMethodVisible = ref(false);
    const signedInvoiceId = route.params.id;
    const tenantId = computed(() => store.getters.tenantId);
    const clientId = computed(() => store.getters.clientId);
    const subDomain = computed(() => store.getters.subDomain);
    const isVaultEnabled = computed(() => {
      return tenantId.value && clientId.value && subDomain.value ? true : false;
    });

    async function isClientPaymentMethodVisibleEvent(value) {
      isClientPaymentMethodVisible.value = value;
    }

    return {
      isClientPaymentMethodVisible,
      signedInvoiceId,
      isVaultEnabled,
      isClientPaymentMethodVisibleEvent,
    };
  },
};
</script>
