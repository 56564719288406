<template>
  <div v-if="paymentProviderSettingsId">
    <Dashboard />
  </div>
</template>

<script>
import { computed } from "vue";
import store from "../store";
import Dashboard from "../components/Client/Dashboard.vue";
export default {
  // eslint-disable-next-line
  name: "Summary",
  components: {
    Dashboard,
  },
  setup() {
    const paymentProviderSettingsId = computed(
      () => store.getters.paymentProviderSettingsId
    );

    return {
      paymentProviderSettingsId,
    };
  },
};
</script>
