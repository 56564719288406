<template>
  <div>
    <Dialog
      header="Add Notification Email Address"
      v-model:visible="isModalVisible"
      :style="{ width: '40vw' }"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
      :draggable="false"
    >
      <div class="card ev-fluid-card">
        <div class="formgrid grid">
          <div class="field col-12">
            <label for="addedNotificationEmailAddress">Email Address: </label>
            <InputText
              id="addedNotificationEmailAddress"
              v-model="addedNotificationEmailAddress"
              class="inputfield w-full"
            />
            <template v-if="v$.addedNotificationEmailAddress.$dirty">
              <div
                class="error-msg text-pink-700"
                v-for="error of v$.addedNotificationEmailAddress.$silentErrors"
                :key="error.$message"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          class="p-button-text"
          label="Cancel"
          icon="pi pi-times"
          :disabled="isLoading"
          @click="emitNotificationEmailAddressModalDataEvent()"
        />
        <Button
          class="p-button-text"
          label="Add Email Address"
          icon="pi pi-check"
          autofocus
          :loading="isLoading"
          :disabled="addedNotificationEmailAddress === undefined"
          @click="onSubmitNotificationEmailAddress()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
import paymentProviderSettingsService from "@/services/Admin/paymentProviderSettings";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";

export default {
  props: {
    isModalVisibleProp: {
      type: Boolean,
    },
    tenantIdProp: {
      type: String,
    },
    paymentProviderSettingsIdProp: {
      type: String,
    },
    notificationEmailAddressesProp: {
      type: Object,
    },
    isUpdateProp: {
      type: Boolean,
    },
  },
  emits: ["resetNotificationEmailAddressModal"],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isModalVisible = toRef(props, "isModalVisibleProp");
    const tenantId = toRef(props, "tenantIdProp");
    const paymentProviderSettingsId = toRef(
      props,
      "paymentProviderSettingsIdProp"
    );
    const notificationEmailAddresses = toRef(
      props,
      "notificationEmailAddressesProp"
    );
    const isUpdate = toRef(props, "isUpdateProp");
    const addedNotificationEmailAddress = ref();
    const notificationEmailAddressResponse = ref();
    const toast = useToast();

    const rules = computed(() => ({
      addedNotificationEmailAddress: {
        required: helpers.withMessage(
          "Notification Email Address is required",
          required
        ),
        email: helpers.withMessage(
          "Notification Email Address is in an invalid format",
          email
        ),
        duplicateEmailAddress: helpers.withMessage(
          "Notification Email Address already exists",
          validateAddDuplicateEmailAddress
        ),
      },
    }));

    const v$ = useVuelidate(
      rules,
      {
        addedNotificationEmailAddress,
      },
      { $lazy: true }
    );

    async function onSubmitNotificationEmailAddress() {
      if (!(await v$.value.$validate())) return;
      v$.value.addedNotificationEmailAddress.$reset();
      isLoading.value = true;

      let addressToAdd = {
        emailAddress: addedNotificationEmailAddress.value,
      };

      notificationEmailAddresses.value.push(addressToAdd);

      try {
        if (isUpdate.value) {
          notificationEmailAddressResponse.value =
            await paymentProviderSettingsService.updateNotificationEmailAddresses(
              tenantId.value,
              paymentProviderSettingsId.value,
              notificationEmailAddresses.value
            );
        }
      } catch (error) {
        toast.error("Unable to Add Notification Email Address");
        isLoading.value = false;
        return emitNotificationEmailAddressModalDataEvent();
      }

      isLoading.value = false;
      emitNotificationEmailAddressModalDataEvent();
      if (isUpdate.value) {
        toast.success("Notification Email Address Added");
      }
    }

    function emitNotificationEmailAddressModalDataEvent() {
      setTimeout(emit("resetNotificationEmailAddressModal", false), 1500);
      v$.value.addedNotificationEmailAddress.$reset();
      addedNotificationEmailAddress.value = undefined;
      isLoading.value = false;
    }

    function validateAddDuplicateEmailAddress() {
      var existingEmailAddress = notificationEmailAddresses.value.filter(
        function (elem) {
          if (
            elem.emailAddress.toLowerCase() ==
            addedNotificationEmailAddress.value.toLowerCase()
          )
            return elem.emailAddress;
        }
      );

      return existingEmailAddress.length > 0 ? false : true;
    }

    return {
      isLoading,
      isModalVisible,
      addedNotificationEmailAddress,
      v$,
      onSubmitNotificationEmailAddress,
      emitNotificationEmailAddressModalDataEvent,
    };
  },
};
</script>
