import userClient from "../api/users.api";
import store from "../store";
import { formatDate, calculateNumberDaysPastFromDate } from "../utility/helpers";
import { Role } from "../utility/role";

export default {
    createUser: async function (emailAddress, firstName, lastName) {
        const requiredParams = tryGetUrlParams();
        if (requiredParams) {
            let response;
            const createUserDto = {
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName,
            };

            try {
                response = await userClient.postUser(
                    requiredParams.tenantId,
                    requiredParams.clientId,
                    createUserDto
                );
            } catch (error) {
                Promise.reject("Unable to create user - " + error);
                return error;
            }

            if (response && response.status === 200) {
                return response.data.enabled;
            }
            if (response && response.status === 400 && response.data.error) {
                return new Error(response.data.error);
            } else {
                return new Error("Invalid email address");
            }
        } else {
            return new Error("Unable to create user - invalid requirements");
        }
    },

    getUsers: async function () {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            response = await userClient.getUsers(tenantId, clientId);
        } catch (error) {
            response = error;
        }
        return response.data;
    },

    getUserById: async function () {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            const userId = store.getters.userId;

            response = await userClient.getUserById(tenantId, clientId, userId);
        } catch (error) {
            const ex = new Error("Unable to retrieve user");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve user");
            throw ex.message;
        }

        return response.data;
    },

    adminUpdateUser: async function (updateUserModel) {
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;
        let response;
        let jsonPatchDocument = fetchAdminJsonPatchDocument(updateUserModel);

        try {
            response = await userClient.adminUpdateUser(
                tenantId,
                clientId,
                updateUserModel.userName,
                jsonPatchDocument
            );
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    updateUser: async function (firstName, lastName) {
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;
        let response;
        let jsonPatchDocument = fetchJsonPatchDocument(firstName, lastName);

        try {
            response = await userClient.updateUser(
                tenantId,
                clientId,
                jsonPatchDocument
            );
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    adminResetPassword: async function (userId) {
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;
        let response;
        const adminResetDto = {
            userId: userId,
        };
        try {
            response = await userClient.adminResetPassword(
                tenantId,
                clientId,
                adminResetDto
            );
        } catch (error) {
            response = error;
        }

        return response.status;
    },

    resetPassword: async function (currentPassword, newPassword) {
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;
        let response;
        const resetDto = {
            previousPassword: currentPassword,
            proposedPassword: newPassword,
        };

        try {
            response = await userClient.resetPassword(tenantId, clientId, resetDto);
        } catch (error) {
            response = error;
        }

        return response.status;
    },

    isUserRoleClientReadonlyAndSetCreatedDate: async function () {
        let isUserRoleClientReadonly = false;
        let userRole = store.getters.userRole;
        let userCreateDate = store.getters.userCreateDate;
        if (userRole && userRole === Role.ClientReadOnly) {
            if (!userCreateDate) {
                let user = await this.getUserById();
                userCreateDate = formatDate(user.userCreateDate);
                store.commit("setUserCreateDate", userCreateDate);
                const daysSinceCreated = calculateNumberDaysPastFromDate(userCreateDate);
                if (daysSinceCreated <= 14) {
                    isUserRoleClientReadonly = true;
                }
            }
            else {
                const daysSinceCreated = calculateNumberDaysPastFromDate(userCreateDate);
                if (daysSinceCreated <= 14) {
                    isUserRoleClientReadonly = true;
                }
            }
        }
        return isUserRoleClientReadonly;
    },
};

function tryGetUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    let requiredParams = {
        tenantId: null,
        clientId: null,
    };
    if (urlParams) {
        if (urlParams.has("tenantId")) {
            requiredParams.tenantId = urlParams.get("tenantId");
            if (urlParams.has("clientId")) {
                requiredParams.clientId = urlParams.get("clientId");
                return requiredParams;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

function fetchAdminJsonPatchDocument(updateUserModel) {
    let jsonPatchDocument = [];
    let jsonPatchEnabledObject = {};
    let jsonPatchCurrentRoleObject = {};
    let jsonPatchNewRoleObject = {};

    let jsonPatchNameObject = {
        op: "replace",
        path: "/attributes",
        value: [
            {
                name: "given_name",
                value: updateUserModel.firstName,
            },
            {
                name: "family_name",
                value: updateUserModel.lastName,
            },
        ],
    };

    jsonPatchDocument.push(jsonPatchNameObject);

    if (typeof updateUserModel.enabled !== "undefined") {
        jsonPatchEnabledObject = {
            op: "replace",
            path: "/enabled",
            value: updateUserModel.enabled.toString(),
        };

        jsonPatchDocument.push(jsonPatchEnabledObject);
    }

    if (typeof updateUserModel.newRole !== "undefined") {
        jsonPatchCurrentRoleObject = {
            op: "replace",
            path: "/currentrole",
            value: updateUserModel.currentRole,
        };

        jsonPatchNewRoleObject = {
            op: "replace",
            path: "/newrole",
            value: updateUserModel.newRole,
        };

        jsonPatchDocument.push(jsonPatchCurrentRoleObject);
        jsonPatchDocument.push(jsonPatchNewRoleObject);
    }

    return jsonPatchDocument;
}

function fetchJsonPatchDocument(firstName, lastName) {
    let jsonPatchDocument = [];

    let jsonPatchObject = {
        op: "replace",
        path: "/attributes",
        value: [
            {
                name: "given_name",
                value: firstName,
            },
            {
                name: "family_name",
                value: lastName,
            },
        ],
    };
    jsonPatchDocument.push(jsonPatchObject);

    return jsonPatchDocument;
}
