"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async fetchToken(accessGrantCode) {
        var response;
        try {
            const callbackUri = await store.getters.callbackUri;
            const urlQueryString = new URLSearchParams([
                ["grant_type", process.env.VUE_APP_USER_POOL_GRANT_TYPE],
                ["client_id", process.env.VUE_APP_USER_POOL_CLIENT_ID],
                ["client_secret", ""],
                ["redirect_uri", callbackUri],
                ["code", accessGrantCode],
                ["scope", ""],
            ]).toString();
            response = await httpClient.post(
                process.env.VUE_APP_TOKEN_BASE_URL,
                urlQueryString, {
                headers: {
                    "Content-Type": process.env.VUE_APP_TOKEN_REQUEST_TYPE,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async fetchAdminToken(accessGrantCode) {
        var response;
        try {
            const callbackUri = await store.getters.callbackUri;
            const urlQueryString = new URLSearchParams([
                ["grant_type", process.env.VUE_APP_USER_POOL_GRANT_TYPE],
                ["client_id", process.env.VUE_APP_MANAGER_CLIENT_ID],
                ["client_secret", ""],
                ["redirect_uri", callbackUri],
                ["code", accessGrantCode],
                ["scope", ""],
            ]).toString();
            response = await httpClient.post(
                process.env.VUE_APP_TOKEN_BASE_URL,
                urlQueryString, {
                headers: {
                    "Content-Type": process.env.VUE_APP_TOKEN_REQUEST_TYPE,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};