<template>
  <div>
    <h2>Signout Successful</h2>
  </div>
</template>

<script>
import router from "@/router";
export default {
  setup() {
    router.push({ name: "AdminLogin" });
  },
};
</script>
