let dayjs = require("dayjs");
let duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

module.exports = {
    calculateNumberDaysPastFromDate: function (dateFrom) {
        const formatDateFrom = dayjs(dateFrom);
        const hoursPast = dayjs();

        let hours = hoursPast.diff(formatDateFrom, "hours");
        return Math.floor(hours / 24);
    },
    calculateNumberDaysUntilDate: function (dateUntil) {
        const formatDateUntil = dayjs(dateUntil);
        const hoursPast = dayjs();

        let hours = formatDateUntil.diff(hoursPast, "hours");
        return Math.ceil(hours / 24);
    },
    formatToCurrency: function (value) {
        const valueToCurrency = value;
        if (typeof valueToCurrency !== "number") {
            parseFloat(valueToCurrency);
        }
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        return formatter.format(valueToCurrency);
    },
    formatDate: function (value) {
        return dayjs(value).format("YYYY-MM-DD");
    },
    formatCalendarDate: function (value) {
        return dayjs(value).format("MM/DD/YYYY");
    },
    formatTableDate: function (value) {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    },
    formatInvoiceNumber: function (value) {
        return value.replace(/^0+/, '');
    },
    fetchCardExpirationYears: function () {
        const currentYear = new Date().getFullYear();
        let addNumberOfYears = currentYear + 15;
        let expirationYearsOptions = [];

        for (let year = currentYear; year < addNumberOfYears; year++) {
            expirationYearsOptions.push({
                name: year.toString(),
                code: year.toString(),
            });
        }
        return expirationYearsOptions;
    },
    fetchCardExpirationMonths: function () {
        const cardExpireMonthsOptions = [
            { name: "01 - January", code: "01" },
            { name: "02 - February", code: "02" },
            { name: "03 - March", code: "03" },
            { name: "04 - April", code: "04" },
            { name: "05 - May", code: "05" },
            { name: "06 - June", code: "06" },
            { name: "07 - July", code: "07" },
            { name: "08 - August", code: "08" },
            { name: "09 - September", code: "09" },
            { name: "10 - October", code: "10" },
            { name: "11 - November", code: "11" },
            { name: "12 - December", code: "12" },
        ];

        return cardExpireMonthsOptions;
    },
    fetchBankAccountTypes: function () {
        const bankAccountTypes = [
            { name: "Checking", code: "Checking" },
            { name: "Savings", code: "Savings" },
        ];

        return bankAccountTypes;
    },
    getEnvironment: function () {
        switch (process.env.VUE_APP_DEPLOY_ENVIRONMENT) {
            case "development":
                return ".dev";
            case "staging":
                return ".uat";
            case "production":
                return "";
            default:
                return ".dev";
        }
    }
};