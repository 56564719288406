"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async getScheduledPaymentsByClient(
        tenantId,
        clientId,
        paymentProviderSettingsId,
        status = "",
        date = ""
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/scheduled-payments?paymentProviderSettingsId=${paymentProviderSettingsId}&status=${status}&date=${date}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getScheduledPaymentsByInvoiceId(tenantId, clientId, invoiceId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/scheduled-payments`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getScheduledPaymentById(
        tenantId,
        clientId,
        invoiceId,
        scheduledPaymentId
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/scheduled-payments/${scheduledPaymentId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async postScheduledPayment(
        tenantId,
        clientId,
        invoiceId,
        createScheduledPaymentDto
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/scheduled-payments`,
                createScheduledPaymentDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async updateScheduledPayment(
        tenantId,
        clientId,
        invoiceId,
        scheduledPaymentId,
        updateScheduledPaymentDto
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.patch(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/scheduled-payments/${scheduledPaymentId}`,
                updateScheduledPaymentDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};