"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async createClientInvoicePayment(
        tenantId,
        clientId,
        invoiceId,
        createPaymentDto
    ) {
        try {
            const bearerToken = store.getters.accessToken;
            var response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/payments`,
                createPaymentDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async createPaymentFromSignedInvoiceId(signedInvoiceId, createPaymentDto) {
        try {
            var response = await httpClient.post(
                `/payments/${signedInvoiceId}`,
                createPaymentDto, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getClientPayments(
        tenantId,
        clientId,
        paymentProviderSettingsId,
        paginationToken = "",
        limit = 10000
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/payments?paymentProviderSettingsId=${paymentProviderSettingsId}&paginationToken=${paginationToken}&limit=${limit}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};