<template>
  <div
    :class="{ error: v.checkboxAgreement.$errors.length }"
    class="text-center"
  >
    <span>
      <Checkbox
        class="accept-terms-conditions-checkbox"
        v-model="checkboxAgreement"
        @change="emitAgreementCheckboxClicked()"
        :binary="true"
      />
      By continuing, you have reviewed the "Payment Detail" section
    </span>
    <br />
    <span>and agree to the</span>
    <a
      class="modal-payment-agreement"
      href="#"
      target="_self"
      @click="openTermsAndConditions()"
    >
      <span>Terms &amp; Conditions of Payment</span>
      <Dialog
        header="Please Read &amp; Accept the Terms and Conditions"
        v-model:visible="displayTermsAndConditions"
        :style="{ width: '50vw' }"
      >
        <p>
          FULL TERMS AND CONDITIONS - By entering my routing and account number
          and clicking “Pay Now”, I authorize the Company providing the billing
          portal to my invoice, hereinafter referred to as “COMPANY”, to debit
          entries in the amount indicated and to be processed as an electronic
          funds transfer, or draft drawn from my account. My account is
          indicated with my financial institution, routing number, and account
          number. If a debit or credit entry is scheduled to take place on a
          non-business date or the payment is made after the cut-off time, the
          transaction will be applied on the next business day. I acknowledge
          the origination of Automated Clearing House (ACH) transactions to my
          account must comply with provisions of U.S. law, and I agree to be
          bound by the NACHA Rules and Regulations. I agree not to dispute this
          transaction with my financial institution so long as the transaction
          corresponds to the terms indicated in this Authorization. If the
          payment is returned unpaid for any reason, I authorize COMPANY to
          collect the payment and any associated return fees, non-sufficient
          funds (NSF) fees, chargeback fees, or any and all other fines and/or
          penalties imposed on COMPANY, by electronic funds transfer(s) or
          draft(s) drawn from my account. By selecting the “make default payment
          method” box and clicking “Submit Payment”, I authorize COMPANY to
          store and save my information for future transactions on my account
          and that such Authorization will remain in full force and effect until
          I notify COMPANY in writing that I wish to terminate this
          Authorization at least fifteen (15) business days prior to the next
          billing date. I authorize all payments which are recurring,
          subscription, or auto-pay payments, to be automatically debited from
          my account using this stored payment method, on the due date or next
          available business date, for the amount as it appears on the
          associated invoice. I agree not to dispute these transactions with my
          financial institution so long as the transaction corresponds to the
          terms indicated in this Authorization. I understand that I may update
          or change my stored payment information through this billing portal
          and that any future information entered into the billing portal will
          be bound by the terms &amp; conditions of this Authorization and that
          such future information, if saved and stored in the billing portal,
          will be automatically used in place of the previous information and
          will be bound by the terms &amp; conditions of this Authorization.
          Payments cannot be changed or canceled once submitted. I understand
          that in order to cancel or change a payment which is scheduled for the
          future, I must notify COMPANY, in writing, of such termination at
          least fifteen (15) days prior to the next billing date. I understand
          that if I cancel a payment or place a stop payment on my transaction
          without proper termination and notice to COMPANY, I will be subject to
          fees and/or fines by COMPANY. I agree to pay these fees pursuant to
          the terms above. If a payment is from a corporate account, I hereby
          acknowledge that I am an authorized corporate representative with the
          authority to make the aforementioned authorizations on behalf of my
          corporation and that no other approvals or authorizations are
          required. I agree that the entity will comply with provisions of U.S.
          law and be bound by the terms and conditions of this Authorization and
          by the NACHA Rules and Regulations. To complete the payment process
          and submit your acceptance to the terms and conditions of this
          Authorization, click the “I Agree” button and the “Submit Payment”
          button. If your payment is successful, your account will be debited
          within a few business days. If your payment is unsuccessful,
          additional attempts will be made to debit any fees pursuant to the
          terms above. For additional questions, please contact COMPANY. NACHA
          Rules. Originator shall comply with, and be bound by, the NACHA Rules,
          as are in effect from time to time. Originator acknowledges that it
          has the capability to purchase a copy of the NACHA Rules if it so
          desires. Originator agrees to assume the responsibilities under the
          NACHA Rules. In the event that Originator violates any of the
          applicable NACHA Rules, and NACHA imposes a fine to the ODFI / COMPANY
          because of the violation, COMPANY may charge the fine to the Sender /
          Originator. COMPANY / Sender shall comply with the NACHA rules to the
          extent such NACHA rules are applicable to Sender / COMPANY. See also:
          http://www.nacha.org and http://www.nachaoperatingrulesonline.org It
          is recommended that you print a copy of this Authorization and
          maintain it for your records.
        </p>
      </Dialog>
    </a>
    <div
      class="input-errors"
      v-for="error of v.checkboxAgreement.$errors"
      :key="error.$uid"
    >
      <div class="error-msg text-pink-700">
        Please accept terms and conditions
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef, computed, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { sameAs } from "@vuelidate/validators";

export default {
  emits: ["isAgreementCheckboxClicked"],
  props: {
    resetCheckBoxAgreementProp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const setCheckBoxAgreement = toRef(props, "resetCheckBoxAgreementProp");
    const displayTermsAndConditions = ref(false);
    const checkboxAgreement = ref(false);
    const openTermsAndConditions = () => {
      displayTermsAndConditions.value = true;
    };

    const rules = computed(() => ({
      checkboxAgreement: {
        sameAs: sameAs(true),
      },
    }));

    const v = useVuelidate(
      rules,
      {
        checkboxAgreement,
      },
      { $lazy: true }
    );

    function emitAgreementCheckboxClicked() {
      emit("isAgreementCheckboxClicked", checkboxAgreement.value);
    }

    watch(setCheckBoxAgreement, (newValue) => {
      checkboxAgreement.value = newValue;
    });

    return {
      displayTermsAndConditions,
      checkboxAgreement,
      openTermsAndConditions,
      emitAgreementCheckboxClicked,
      v,
    };
  },
};
</script>
