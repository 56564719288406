<template>
  <div class="card ev-fluid-card">
    <div class="card-header">
      <h4>Erp Integration Settings Details</h4>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="erpIntegrationType">Integration Type:</label>
        <div>
          <Dropdown
            v-model="erpIntegrationSettings.erpIntegrationType"
            :options="erpIntegrationTypes"
            optionLabel="name"
            optionValue="code"
            class="inputfield w-full"
          />
        </div>
      </div>

      <div
        v-if="erpIntegrationSettings.erpIntegrationType == 0"
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.userName">User Name:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVisionConnectionSettings.userName"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings.userName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.userName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings.userName
              .$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.userName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.password">Password:</label>
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVisionPasswordVisibility"
          ></Button>
          <InputText
            id="deltekVisionConnectionSettings.password"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings.password
            "
            class="inputfield w-full"
            :type="visionPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.password'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings.password
              .$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.password.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.dbName"
          >Database Name:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVisionConnectionSettings.dbName"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings.dbName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.dbName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings.dbName
              .$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.dbName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.encPassword"
          >Enc Password:</label
        >
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVisionEncPasswordVisibility"
          ></Button>
          <InputText
            id="deltekVisionConnectionSettings.encPassword"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings.encPassword
            "
            class="inputfield w-full"
            :type="visionEncPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.encPassword'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings.encPassword
              .$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.encPassword.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.baseUrl">Base Url:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVisionConnectionSettings.baseUrl"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings.baseUrl
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.baseUrl'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings.baseUrl
              .$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.baseUrl.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.integratedUserName"
          >Integrated User Name:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVisionConnectionSettings.integratedUserName"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings
                .integratedUserName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.integratedUserName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings
              .integratedUserName.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.integratedUserName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.integratedPassword"
          >Integrated Password:</label
        >
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVisionIntegratedPasswordVisibility"
          ></Button>
          <InputText
            id="deltekVisionConnectionSettings.integratedPassword"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings
                .integratedPassword
            "
            class="inputfield w-full"
            :type="visionIntegratedPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.integratedPassword'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings
              .integratedPassword.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.integratedPassword.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 0 &&
          erpIntegrationSettings.deltekVisionConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVisionConnectionSettings.integratedDomain"
          >Integrated Domain:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVisionConnectionSettings.integratedDomain"
            v-model="
              erpIntegrationSettings.deltekVisionConnectionSettings
                .integratedDomain
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVisionConnectionSettings.integratedDomain'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVisionConnectionSettings
              .integratedDomain.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVisionConnectionSettings.integratedDomain.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.userName"
          >User Name:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.userName"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .userName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.userName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .userName.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.userName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.password"
          >Password:</label
        >
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVantagepointPasswordVisibility"
          ></Button>
          <InputText
            id="deltekVantagepointConnectionSettings.password"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .password
            "
            class="inputfield w-full"
            :type="vantagepointPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.password'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .password.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.password.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.dbName"
          >Database Name:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.dbName"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings.dbName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.dbName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .dbName.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.dbName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.clientId"
          >Client Id:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.clientId"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .clientId
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.clientId'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .clientId.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.clientId.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.clientSecret"
          >Client Secret:</label
        >
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVantagepointClientSecretVisibility"
          ></Button>
          <InputText
            id="deltekVantagepointConnectionSettings.clientSecret"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .clientSecret
            "
            class="inputfield w-full"
            :type="vantagepointClientSecretPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.clientSecret'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .clientSecret.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.clientSecret.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.baseUrl"
          >Base Url:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.baseUrl"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .baseUrl
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.baseUrl'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .baseUrl.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.baseUrl.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.integratedUserName"
          >Integrated User Name:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.integratedUserName"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .integratedUserName
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.integratedUserName'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .integratedUserName.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.integratedUserName
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.integratedPassword"
          >Integrated Password:</label
        >
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchVantagepointIntegratedPasswordVisibility"
          ></Button>
          <InputText
            id="deltekVantagepointConnectionSettings.integratedPassword"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .integratedPassword
            "
            class="inputfield w-full"
            :type="vantagepointIntegratedPasswordFieldType"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.integratedPassword'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .integratedPassword.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.integratedPassword
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div
        v-if="
          erpIntegrationSettings.erpIntegrationType == 1 &&
          erpIntegrationSettings.deltekVantagepointConnectionSettings
        "
        class="field col-12 md:col-6"
      >
        <label for="deltekVantagepointConnectionSettings.integratedDomain"
          >Integrated Domain:</label
        >
        <div class="p-inputgroup flex-1">
          <InputText
            id="deltekVantagepointConnectionSettings.integratedDomain"
            v-model="
              erpIntegrationSettings.deltekVantagepointConnectionSettings
                .integratedDomain
            "
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="
              updateErpIntegrationSettingsField(
                'deltekVantagepointConnectionSettings.integratedDomain'
              )
            "
          ></Button>
        </div>
        <template
          v-if="
            v$.erpIntegrationSettings.deltekVantagepointConnectionSettings
              .integratedDomain.$dirty
          "
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.erpIntegrationSettings
              .deltekVantagepointConnectionSettings.integratedDomain
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
    </div>
    <div v-if="!isUpdate" class="text-center pt-3">
      <Button label="Submit" @click="onSubmit()" />
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted, computed } from "vue";
import erpIntegrationSettingsService from "@/services/Admin/erpIntegrationSettings";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import router from "@/router";

export default {
  props: {
    tenantIdProp: { type: String },
    paymentProviderSettingsIdProp: { type: String },
    erpIntegrationSettingsIdProp: { type: String },
  },
  setup(props) {
    const toast = useToast();
    const tenantId = toRef(props, "tenantIdProp");
    const paymentProviderSettingsId = toRef(
      props,
      "paymentProviderSettingsIdProp"
    );
    const erpIntegrationSettingsId = toRef(
      props,
      "erpIntegrationSettingsIdProp"
    );
    const erpIntegrationSettings = ref({});
    const initialErpIntegrationSettings = ref({
      initialVisionConnectionSettings: {},
      initialVantagepointConnectionSettings: {},
    });
    const visionPasswordFieldType = ref("password");
    const visionEncPasswordFieldType = ref("password");
    const visionIntegratedPasswordFieldType = ref("password");
    const vantagepointPasswordFieldType = ref("password");
    const vantagepointClientSecretPasswordFieldType = ref("password");
    const vantagepointIntegratedPasswordFieldType = ref("password");
    const createErpIntegrationSettingsResponse = ref();
    const createErpIntegrationSettingsDto = ref();
    const updateErpIntegrationSettingsDto = ref({});
    const updateErpIntegrationSettingsResponse = ref();
    const loadingIconType = ref("pi pi-check");
    const isUpdate = computed(() => {
      return props.paymentProviderSettingsIdProp ? false : true;
    });

    const erpIntegrationTypes = [
      { name: "Vision", code: 0 },
      { name: "Vantagepoint", code: 1 },
    ];

    const rules = computed(() => ({
      erpIntegrationSettings: {
        deltekVisionConnectionSettings: {
          userName: {
            maxLength: helpers.withMessage(
              "User Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          password: {
            maxLength: helpers.withMessage(
              "Password cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          dbName: {
            maxLength: helpers.withMessage(
              "Database Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          encPassword: {
            maxLength: helpers.withMessage(
              "Enc Password cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          baseUrl: {
            maxLength: helpers.withMessage(
              "Base Url cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedUserName: {
            maxLength: helpers.withMessage(
              "Integrated User Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedPassword: {
            maxLength: helpers.withMessage(
              "Integrated Password cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedDomain: {
            maxLength: helpers.withMessage(
              "Integrated Domain cannot be more than 200 characters",
              maxLength(200)
            ),
          },
        },
        deltekVantagepointConnectionSettings: {
          userName: {
            maxLength: helpers.withMessage(
              "User Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          password: {
            maxLength: helpers.withMessage(
              "Password cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          dbName: {
            maxLength: helpers.withMessage(
              "Database Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          clientId: {
            maxLength: helpers.withMessage(
              "Client Id cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          clientSecret: {
            maxLength: helpers.withMessage(
              "Client Secret cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          baseUrl: {
            maxLength: helpers.withMessage(
              "Base Url cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedUserName: {
            maxLength: helpers.withMessage(
              "Integrated User Name cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedPassword: {
            maxLength: helpers.withMessage(
              "Integrated Password cannot be more than 200 characters",
              maxLength(200)
            ),
          },
          integratedDomain: {
            maxLength: helpers.withMessage(
              "Integrated Domain cannot be more than 200 characters",
              maxLength(200)
            ),
          },
        },
      },
    }));
    const v$ = useVuelidate(
      rules,
      {
        erpIntegrationSettings,
      },
      { $lazy: true }
    );

    onMounted(fetchErpIntegrationSettings);

    async function fetchErpIntegrationSettings() {
      if (isUpdate.value) {
        erpIntegrationSettings.value =
          await erpIntegrationSettingsService.getErpIntegrationSettingsById(
            tenantId.value,
            erpIntegrationSettingsId.value
          );

        erpIntegrationSettings.value.erpIntegrationType == 0
          ? (erpIntegrationSettings.value.deltekVantagepointConnectionSettings =
              {})
          : (erpIntegrationSettings.value.deltekVisionConnectionSettings = {});

        Object.assign(
          initialErpIntegrationSettings.value.initialVisionConnectionSettings,
          erpIntegrationSettings.value.deltekVisionConnectionSettings
        );
        Object.assign(
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings,
          erpIntegrationSettings.value.deltekVantagepointConnectionSettings
        );
      } else {
        erpIntegrationSettings.value = {
          erpIntegrationType: 1,
          deltekVisionConnectionSettings: {},
          deltekVantagepointConnectionSettings: {},
        };
      }
    }

    async function onSubmit() {
      if (!(await v$.value.$validate())) return;
      try {
        erpIntegrationSettings.value.paymentProviderSettingsId =
          paymentProviderSettingsId.value;
        createErpIntegrationSettingsResponse.value =
          await erpIntegrationSettingsService.createErpIntegrationSettings(
            tenantId.value,
            erpIntegrationSettings.value
          );
      } catch (error) {
        toast.error("Unable to create Erp Integration Settings");
      }
      if (createErpIntegrationSettingsResponse.value == 200) {
        toast.success("Created Erp Integration Settings");
        await router.push({
          name: "TenantCompanies",
          params: {
            tenantId: tenantId.value
          },
        });
      } else {
        toast.error("Unable to create Erp Integration Settings");
      }
    }

    async function updateErpIntegrationSettingsField(propertyName) {
      if (!(await v$.value.$validate())) {
        return;
      }
      loadingIconType.value = "pi pi-spinner";
      let properties = propertyName.split(".");
      let fieldValue = "";

      if (erpIntegrationSettings.value.erpIntegrationType == 0) {
        fieldValue =
          erpIntegrationSettings.value.deltekVisionConnectionSettings[
            properties[1]
          ];

        let updateDeltekVisionConnectionSettings = {};

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .userName != null ||
          properties[1] == "userName"
        ) {
          updateDeltekVisionConnectionSettings.userName =
            properties[1] == "userName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.userName;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .password != null ||
          properties[1] == "password"
        ) {
          updateDeltekVisionConnectionSettings.password =
            properties[1] == "password"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.password;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .dbName != null ||
          properties[1] == "dbName"
        ) {
          updateDeltekVisionConnectionSettings.dbName =
            properties[1] == "dbName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.dbName;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .encPassword != null ||
          properties[1] == "encPassword"
        ) {
          updateDeltekVisionConnectionSettings.encPassword =
            properties[1] == "encPassword"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.encPassword;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .baseUrl != null ||
          properties[1] == "baseUrl"
        ) {
          updateDeltekVisionConnectionSettings.baseUrl =
            properties[1] == "baseUrl"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.baseUrl;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .integratedUserName != null ||
          properties[1] == "integratedUserName"
        ) {
          updateDeltekVisionConnectionSettings.integratedUserName =
            properties[1] == "integratedUserName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.integratedUserName;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .integratedPassword != null ||
          properties[1] == "integratedPassword"
        ) {
          updateDeltekVisionConnectionSettings.integratedPassword =
            properties[1] == "integratedPassword"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.integratedPassword;
        }

        if (
          initialErpIntegrationSettings.value.initialVisionConnectionSettings
            .integratedDomain != null ||
          properties[1] == "integratedDomain"
        ) {
          updateDeltekVisionConnectionSettings.integratedDomain =
            properties[1] == "integratedDomain"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVisionConnectionSettings.integratedDomain;
        }

        updateErpIntegrationSettingsDto.value = {
          erpIntegrationType: erpIntegrationSettings.value.erpIntegrationType,
          deltekVisionConnectionSettings: updateDeltekVisionConnectionSettings,
        };
      } else if (erpIntegrationSettings.value.erpIntegrationType == 1) {
        fieldValue =
          erpIntegrationSettings.value.deltekVantagepointConnectionSettings[
            properties[1]
          ];

        let updateDeltekVantagepointConnectionSettings = {};
        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.userName != null ||
          properties[1] == "userName"
        ) {
          updateDeltekVantagepointConnectionSettings.userName =
            properties[1] == "userName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.userName;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.password != null ||
          properties[1] == "password"
        ) {
          updateDeltekVantagepointConnectionSettings.password =
            properties[1] == "password"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.password;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.dbName != null ||
          properties[1] == "dbName"
        ) {
          updateDeltekVantagepointConnectionSettings.dbName =
            properties[1] == "dbName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.dbName;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.clientId != null ||
          properties[1] == "clientId"
        ) {
          updateDeltekVantagepointConnectionSettings.clientId =
            properties[1] == "clientId"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.clientId;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.clientSecret != null ||
          properties[1] == "clientSecret"
        ) {
          updateDeltekVantagepointConnectionSettings.clientSecret =
            properties[1] == "clientSecret"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.clientSecret;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.baseUrl != null ||
          properties[1] == "baseUrl"
        ) {
          updateDeltekVantagepointConnectionSettings.baseUrl =
            properties[1] == "baseUrl"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.baseUrl;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.integratedUserName != null ||
          properties[1] == "integratedUserName"
        ) {
          updateDeltekVantagepointConnectionSettings.integratedUserName =
            properties[1] == "integratedUserName"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.integratedUserName;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.integratedPassword != null ||
          properties[1] == "integratedPassword"
        ) {
          updateDeltekVantagepointConnectionSettings.integratedPassword =
            properties[1] == "integratedPassword"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.integratedPassword;
        }

        if (
          initialErpIntegrationSettings.value
            .initialVantagepointConnectionSettings.integratedDomain != null ||
          properties[1] == "integratedDomain"
        ) {
          updateDeltekVantagepointConnectionSettings.integratedDomain =
            properties[1] == "integratedDomain"
              ? fieldValue
              : initialErpIntegrationSettings.value
                  .initialVantagepointConnectionSettings.integratedDomain;
        }

        updateErpIntegrationSettingsDto.value = {
          erpIntegrationType: erpIntegrationSettings.value.erpIntegrationType,

          deltekVantagepointConnectionSettings:
            updateDeltekVantagepointConnectionSettings,
        };
      } else {
        loadingIconType.value = "pi pi-check";
        return toast.error("Unable to Update Erp Integration Settings value");
      }

      updateErpIntegrationSettingsResponse.value =
        await erpIntegrationSettingsService.updateErpIntegrationSettings(
          props.tenantIdProp,
          props.erpIntegrationSettingsIdProp,
          updateErpIntegrationSettingsDto.value
        );

      if (updateErpIntegrationSettingsResponse.value == 200) {
        toast.success("Erp Integration Settings Updated");
      } else {
        toast.error("Unable to Update Erp Integration Settings");
      }
      fetchErpIntegrationSettings();
      loadingIconType.value = "pi pi-check";
    }

    function switchVisionPasswordVisibility() {
      visionPasswordFieldType.value =
        visionPasswordFieldType.value === "password" ? "text" : "password";
    }

    function switchVisionEncPasswordVisibility() {
      visionEncPasswordFieldType.value =
        visionEncPasswordFieldType.value === "password" ? "text" : "password";
    }

    function switchVisionIntegratedPasswordVisibility() {
      visionIntegratedPasswordFieldType.value =
        visionIntegratedPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    function switchVantagepointPasswordVisibility() {
      vantagepointPasswordFieldType.value =
        vantagepointPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    function switchVantagepointClientSecretVisibility() {
      vantagepointClientSecretPasswordFieldType.value =
        vantagepointClientSecretPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    function switchVantagepointIntegratedPasswordVisibility() {
      vantagepointIntegratedPasswordFieldType.value =
        vantagepointIntegratedPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    return {
      erpIntegrationSettings,
      visionPasswordFieldType,
      visionEncPasswordFieldType,
      visionIntegratedPasswordFieldType,
      vantagepointPasswordFieldType,
      vantagepointClientSecretPasswordFieldType,
      vantagepointIntegratedPasswordFieldType,
      createErpIntegrationSettingsResponse,
      createErpIntegrationSettingsDto,
      updateErpIntegrationSettingsDto,
      updateErpIntegrationSettingsResponse,
      loadingIconType,
      erpIntegrationTypes,
      isUpdate,
      v$,
      switchVisionPasswordVisibility,
      switchVisionEncPasswordVisibility,
      switchVisionIntegratedPasswordVisibility,
      switchVantagepointPasswordVisibility,
      switchVantagepointClientSecretVisibility,
      switchVantagepointIntegratedPasswordVisibility,
      onSubmit,
      updateErpIntegrationSettingsField,
    };
  },
};
</script>