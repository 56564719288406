<template>
  <div>
    <div>
      <PayInvoicesSummary />
    </div>
    <div class="pt-5">
      <ClientPaymentMethod />
    </div>
  </div>
</template>

<script>
import PayInvoicesSummary from "../components/Client/PayInvoicesSummary.vue";
import ClientPaymentMethod from "../components/Client/PaymentMethod.vue";

export default {
  components: {
    PayInvoicesSummary,
    ClientPaymentMethod
  },
};
</script>
