<template>
  <div>
    <Dialog
      header="Create Tenant"
      v-model:visible="isModalVisible"
      :style="{ width: '40vw' }"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
      :draggable="false"
    >
      <div class="card ev-fluid-card">
        <div class="formgrid grid">
          <div class="field col-12">
            <label for="addedTenant">Tenant Name: </label>
            <InputText
              id="addedTenant"
              v-model="addedTenant"
              class="inputfield w-full"
            />
            <template v-if="v$.addedTenant.$dirty">
              <div
                class="error-msg text-pink-700"
                v-for="error of v$.addedTenant.$silentErrors"
                :key="error.$message"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          class="p-button-text"
          label="Cancel"
          icon="pi pi-times"
          :disabled="isLoading"
          @click="emitTenantModalDataEvent()"
        />
        <Button
          class="p-button-text"
          label="Add Tenant"
          icon="pi pi-check"
          autofocus
          :loading="isLoading"
          :disabled="addedTenant === undefined"
          @click="onSubmitTenant()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
import tenantService from "@/services/Admin/tenant";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";

export default {
  props: {
    isModalVisibleProp: {
      type: Boolean,
    },
    tenantsProp: {
      type: Object,
    },
  },
  emits: ["resetTenantModal"],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const isModalVisible = toRef(props, "isModalVisibleProp");
    const addedTenant = ref();
    const addTenantResponse = ref();
    const toast = useToast();
    const tenants = toRef(props, "tenantsProp");

    const rules = computed(() => ({
      addedTenant: {
        required: helpers.withMessage("Tenant Name is required", required),
        maxLength: helpers.withMessage(
          "Tenant Name cannot be more than 100 characters",
          maxLength(100)
        ),
        duplicateTenantName: helpers.withMessage(
          "Tenant Name already exists",
          validateDuplicateTenantName
        ),
      },
    }));

    const v$ = useVuelidate(
      rules,
      {
        addedTenant,
      },
      { $lazy: true }
    );

    async function onSubmitTenant() {
      if (!(await v$.value.addedTenant.$validate())) return;
      v$.value.addedTenant.$reset();
      isLoading.value = true;

      let payload = {
        tenantName: addedTenant.value,
      };

      try {
        addTenantResponse.value = await tenantService.createTenant(payload);
      } catch (error) {
        toast.error("Unable to create Tenant");
        isLoading.value = false;
        return emitTenantModalDataEvent();
      }

      if (addTenantResponse.value === 201) {
        toast.success("Tenant created");
      } else {
        toast.error("Unable to create Tenant");
      }
      isLoading.value = false;
      emitTenantModalDataEvent();
    }

    function validateDuplicateTenantName() {
      var existingTenant = tenants.value.filter(function (elem) {
        if (elem.tenantName.toLowerCase() == addedTenant.value.toLowerCase())
          return elem.tenantName;
      });
      return existingTenant.length > 0 ? false : true;
    }

    function emitTenantModalDataEvent() {
      setTimeout(emit("resetTenantModal", false), 1500);
      v$.value.addedTenant.$reset();
      addedTenant.value = undefined;
      isLoading.value = false;
    }

    return {
      isLoading,
      isModalVisible,
      addedTenant,
      v$,
      onSubmitTenant,
      emitTenantModalDataEvent,
    };
  },
};
</script>
