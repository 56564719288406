<template>
  <div class="card ev-user-profile-card">
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="currentPassword">Current Password:</label>
        <div>
          <InputText
            id="currentPassword"
            placeholder="Current Password"
            v-model="currentPassword"
            class="inputfield w-full"
            type="password"
          />
        </div>
        <template v-if="v$.currentPassword.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.currentPassword.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="field col-12">
        <label for="newPassword">New Password:</label>
        <div>
          <InputText
            id="newPassword"
            placeholder="New Password"
            v-model="newPassword"
            class="inputfield w-full"
            type="password"
            v-on:keyup="checkPasswordValidation"
          />
        </div>
        <template v-if="v$.newPassword.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.newPassword.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="field col-12">
        <label for="confirmPassword">Confirm Password:</label>
        <div>
          <InputText
            id="confirmPassword"
            placeholder="Confirm Password"
            v-model="confirmPassword"
            class="inputfield w-full"
            type="password"
          />
        </div>
        <template v-if="v$.confirmPassword.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.confirmPassword.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
    </div>
    <div class="pt-3 text-center">
      <Button
        class="justify-content-center button-submit-payment"
        label="Reset Password"
        type="submit"
        @click="onPasswordReset"
        :disabled="
          v$.currentPassword.$invalid ||
          v$.newPassword.$invalid ||
          v$.confirmPassword.$invalid
        "
        :loading="isLoading"
      />
    </div>
    <div class="pt-3">
      <ul>
        <li
          v-bind:class="
            lowerCaseValidation ? 'ev-success-color' : 'ev-failure-color'
          "
        >
          Password must contain a lower case letter
        </li>
        <li
          v-bind:class="
            upperCaseValidation ? 'ev-success-color' : 'ev-failure-color'
          "
        >
          Password must contain an upper case letter
        </li>
        <li
          v-bind:class="
            specialCharacterValidation ? 'ev-success-color' : 'ev-failure-color'
          "
        >
          Password must contain a special character
        </li>
        <li
          v-bind:class="
            numberValidation ? 'ev-success-color' : 'ev-failure-color'
          "
        >
          Password must contain a number
        </li>
        <li
          v-bind:class="
            minLengthValidation ? 'ev-success-color' : 'ev-failure-color'
          "
        >
          Password must contain at least 8 characters
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import userService from "../services/user";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    const currentPassword = ref();
    const newPassword = ref();
    const confirmPassword = ref();
    const resetPasswordResponse = ref();
    const isLoading = ref(false);
    let lowerCaseValidation = ref(false);
    let upperCaseValidation = ref(false);
    let specialCharacterValidation = ref(false);
    let numberValidation = ref(false);
    let minLengthValidation = ref(false);

    function newPasswordRegexValidator() {
      let regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><':;|_~`])\S{8,99}$/;
      let match = regex.exec(newPassword.value);
      if (match == null) {
        return false;
      } else {
        return true;
      }
    }

    const rules = computed(() => ({
      currentPassword: {
        required: helpers.withMessage("Current password is required", required),
      },
      newPassword: {
        newPasswordRegexValidator: helpers.withMessage(
          "Password is invalid",
          newPasswordRegexValidator
        ),
        required: helpers.withMessage("New password is required", required),
      },
      confirmPassword: {
        sameAs: helpers.withMessage(
          "New Password must be the same value as Confirm Password",
          sameAs(newPassword.value)
        ),
        required: helpers.withMessage("Confirm password is required", required),
      },
    }));
    const v$ = useVuelidate(
      rules,
      {
        currentPassword,
        newPassword,
        confirmPassword,
      },
      { $lazy: true }
    );

    async function checkPasswordValidation() {
      let newPasswordValue = newPassword.value;
      CheckMinLengthValidation(newPasswordValue);
      CheckLowerCaseValidation(newPasswordValue);
      CheckUpperCaseValidation(newPasswordValue);
      CheckNumberValidation(newPasswordValue);
      CheckSpecialCharacterValidation(newPasswordValue);
    }

    async function CheckMinLengthValidation(newPassword) {
      let minLengthRegex = /^.{8,}$/;
      let minLengthMatch = minLengthRegex.exec(newPassword);
      minLengthValidation.value = minLengthMatch != null ? true : false;
    }

    async function CheckLowerCaseValidation(newPassword) {
      let lowerCaseRegex = /(?=.*[a-z])/;
      let lowerCaseMatch = lowerCaseRegex.exec(newPassword);
      lowerCaseValidation.value = lowerCaseMatch != null ? true : false;
    }

    async function CheckUpperCaseValidation(newPassword) {
      let upperCaseRegex = /(?=.*[A-Z])/;
      let upperCaseMatch = upperCaseRegex.exec(newPassword);
      upperCaseValidation.value = upperCaseMatch != null ? true : false;
    }

    async function CheckNumberValidation(newPassword) {
      let numberRegex = /(?=.*[0-9])/;
      let numberMatch = numberRegex.exec(newPassword);
      numberValidation.value = numberMatch != null ? true : false;
    }

    async function CheckSpecialCharacterValidation(newPassword) {
      let specialCharacterRegex = /(?=.*[\^$*.[\]{}()?\-“!@#%&/,><':;|_~`])\S/;
      let specialCharacterMatch = specialCharacterRegex.exec(newPassword);
      specialCharacterValidation.value =
        specialCharacterMatch != null ? true : false;
    }

    async function ClearPasswordForm() {
      currentPassword.value = "";
      newPassword.value = "";
      confirmPassword.value = "";
      v$.value.$reset();
      minLengthValidation.value = false;
      lowerCaseValidation.value = false;
      upperCaseValidation.value = false;
      numberValidation.value = false;
      specialCharacterValidation.value = false;
    }

    async function onPasswordReset() {
      if (!(await v$.value.$validate())) {
        return;
      } else {
        isLoading.value = true;
        resetPasswordResponse.value = await userService.resetPassword(
          currentPassword.value,
          newPassword.value
        );

        if (resetPasswordResponse.value == 200) {
          toast.success("Updated User Password");
        } else {
          toast.error("Error Resetting User Password");
        }

        ClearPasswordForm();
        isLoading.value = false;
      }
    }

    return {
      currentPassword,
      newPassword,
      confirmPassword,
      resetPasswordResponse,
      isLoading,
      lowerCaseValidation,
      upperCaseValidation,
      specialCharacterValidation,
      numberValidation,
      minLengthValidation,
      checkPasswordValidation,
      ClearPasswordForm,
      newPasswordRegexValidator,
      onPasswordReset,
      v$,
    };
  },
};
</script>
