<template>
  <div>
    <PayInvoicesConfirmation />
  </div>
</template>

<script>
import PayInvoicesConfirmation from "../components/Client/PayInvoicesConfirmation.vue";

export default {
  components: {
    PayInvoicesConfirmation,
  },
};
</script>
