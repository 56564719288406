<template>
  <div class="card ev-user-profile-card">
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="firstName">First Name:</label>
        <div>
          <InputText
            id="firstName"
            placeholder="First Name"
            v-model="firstName"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v$.firstName.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.firstName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="field col-12">
        <label for="lastName">Last Name:</label>
        <div>
          <InputText
            id="lastName"
            placeholder="Last Name"
            v-model="lastName"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v$.lastName.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.lastName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
    </div>
    <div class="pt-3 text-center">
      <Button
        class="justify-content-center button-submit-payment"
        label="Submit"
        type="submit"
        @click="onSubmit"
        :loading="isLoading"
        :disabled="v$.firstName.$invalid || v$.lastName.$invalid"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import store from "../store";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";
import userService from "../services/user";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    let firstName = ref(store.getters.given_name);
    let lastName = ref(store.getters.family_name);
    const isLoading = ref(false);
    const updateUserResponse = ref();
    const rules = computed(() => ({
      firstName: {
        maxLength: helpers.withMessage(
          "First Name cannot be more than 30 characters",
          maxLength(30)
        ),
        required: helpers.withMessage("First Name is required", required),
      },
      lastName: {
        maxLength: helpers.withMessage(
          "Last Name cannot be more than 30 characters",
          maxLength(30)
        ),
        required: helpers.withMessage("Last Name is required", required),
      },
    }));
    const v$ = useVuelidate(
      rules,
      {
        firstName,
        lastName,
      },
      { $lazy: true }
    );

    async function onSubmit() {
      if (!(await v$.value.$validate())) {
        return;
      } else {
        isLoading.value = true;

        updateUserResponse.value = await userService.updateUser(
          firstName.value,
          lastName.value
        );

        if (updateUserResponse.value == 200) {
          store.commit("setGiven_Name", firstName.value);
          store.commit("setFamily_Name", lastName.value);
          toast.success("Updated First/Last Name");
        } else {
          firstName.value = store.getters.given_name;
          lastName.value = store.getters.family_name;
          toast.error("Unable to Update First/Last Name");
        }

        isLoading.value = false;
      }
    }

    return {
      firstName,
      lastName,
      isLoading,
      updateUserResponse,
      onSubmit,
      v$,
    };
  },
};
</script>
