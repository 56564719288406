<template>
  <div class="grid guest-checkout-header-container">
    <div class="col-12 md:col-6">
      <div v-if="imagePath" class="guest-checkout-header-logo">
        <img
          class="guest-checkout-header-logo-img"
          :src="imagePath"
          alt="company-logo"
        />
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="guest-checkout-header-address">
        <span v-if="name">
          <strong>{{ name }}</strong>
        </span>
        <br v-if="name" />
        <span v-if="addressLine1">{{ addressLine1 }} </span>
        <br v-if="addressLine1" />
        <span v-if="addressLine2">{{ addressLine2 }}</span>
        <br v-if="addressLine2" />
        <span
          >{{ city }},
          {{ state }}
          {{ zipCode }}</span
        >
        <br v-if="city || state || zipCode" />
        <span v-if="phoneNumber"> {{ phoneNumber }}</span>
        <br v-if="phoneNumber" />
        <span v-if="contactEmailAddress">{{ contactEmailAddress }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import paymentProviderSettingsService from "@/services/paymentProviderSetting";
export default {
  setup() {
    const route = useRoute();
    const imagePath = computed(
      () => window.location.origin + "/" + store.getters.imagePath
    );
    const name = computed(() => store.getters.name);
    const addressLine1 = computed(() => store.getters.addressLine1);
    const addressLine2 = computed(() => store.getters.addressLine2);
    const city = computed(() => store.getters.city);
    const state = computed(() => store.getters.state);
    const zipCode = computed(() => store.getters.zipCode);
    const phoneNumber = computed(() => store.getters.phoneNumber);
    const contactEmailAddress = computed(
      () => store.getters.contactEmailAddress
    );
    const isGuestCheckoutLink = computed(() => {
      return route.params.id ? true : false;
    });

    if (isGuestCheckoutLink.value === true) {
      onMounted(async () => {
        await paymentProviderSettingsService.fetchPaymentProviderSettingsBySignedInvoiceId(
          route.params.id
        );
      });
    }

    return {
      imagePath,
      name,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      phoneNumber,
      contactEmailAddress,
    };
  },
};
</script>
