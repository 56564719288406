import store from "../store";
import invoiceService from "../services/invoice";
import paymentService from "../services/payment";
import { 
    formatInvoiceNumber, 
    formatToCurrency, 
    formatCalendarDate, 
    calculateNumberDaysPastFromDate 
} from "../utility/helpers";

var dayjs = require("dayjs");
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

export default {
    getAllClientInvoices: async function() {
        return await invoiceService.getAllClientInvoices();
    },

    getDashboardData: async function(invoices) {
        let dashboardData = {};
        formatInvoicePropsForDisplay(invoices);
        let paidInvoices = getPaidInvoices(invoices);
        let unpaidInvoices = getUnpaidInvoices(invoices);
        let pastDueInvoices = getPastDueInvoices(unpaidInvoices); 
        let scheduledInvoices = getScheduledInvoices(invoices);
        let lastClientPayment = await getClientPayment();

        dashboardData.unpaidInvoicesCount = unpaidInvoices.length;
        dashboardData.paidInvoicesCount = paidInvoices.length;
        dashboardData.pastDueInvoicesCount = pastDueInvoices.length;
        dashboardData.scheduledInvoicesCount = scheduledInvoices.length;

        dashboardData.unpaidInvoicesAmount = getInvoicesTotal(unpaidInvoices);
        dashboardData.paidInvoicesAmount = getInvoicesTotal(paidInvoices);
        dashboardData.pastDueInvoicesAmount = getInvoicesTotal(pastDueInvoices);
        dashboardData.scheduledInvoicesAmount = getInvoicesTotal(scheduledInvoices);

        dashboardData.paidInvoicesCountOver60Days = 0;
        dashboardData.paidInvoicesAmountOver60Days = 0;
        dashboardData.unpaidInvoicesCountOver60Days = 0;
        dashboardData.unpaidInvoicesAmountOver60Days = 0;

        if(lastClientPayment.length != 0) {
            dashboardData.lastClientPaymentAmount = formatToCurrency(lastClientPayment[0].paymentAmount);
            dashboardData.lastClientPaymentDate = formatCalendarDate(lastClientPayment[0].paymentDateTime);
        }
        
        setPaidInvoiceCards(paidInvoices, dashboardData);
        setUnpaidInvoiceCards(unpaidInvoices, dashboardData);
        setShowInvoiceDueDate(unpaidInvoices);


        dashboardData.invoiceChartUnpaid = getInvoiceChartData(unpaidInvoices);
        dashboardData.invoiceChartPaid = getInvoiceChartData(paidInvoices);
        dashboardData.invoiceChartScheduled = getInvoiceChartData(scheduledInvoices);
        dashboardData.invoiceTable = getInvoiceTableData(invoices);
        return dashboardData;
    },
};

async function getClientPayment() {
    let fetchLimit = 1;
    let payment = await paymentService.fetchClientPayments(fetchLimit);
    return payment.data;
}

function getUnpaidInvoices(invoices) {
    let unpaidInvoices = [];
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].invoiceStatus === "unpaid") {
            unpaidInvoices.push(invoices[i]);
        }
    }
    return unpaidInvoices;
}

function getPaidInvoices(invoices) {
    let paidInvoices = [];
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].invoiceStatus === "paid" || invoices[i].invoiceStatus === "paidexternal") {
            paidInvoices.push(invoices[i]);
        }
    }
    return paidInvoices;
}

function getPastDueInvoices(unpaidInvoices) {
    let pastDueInvoices = [];
    for (let i = 0; i < unpaidInvoices.length; i++) {
        if(unpaidInvoices[i].dueDate != null) {
            if (new Date(unpaidInvoices[i].dueDate) < new Date().setHours(0,0,0,0)) {
                pastDueInvoices.push(unpaidInvoices[i]);
            }
        }
    }
    return pastDueInvoices;
}

function getScheduledInvoices(invoices) {
    let scheduledInvoices = [];
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].invoiceStatus === "scheduled") {
            scheduledInvoices.push(invoices[i]);
        }
    }
    return scheduledInvoices;
}

function getInvoiceTableData(invoices) {
    let nonVoidedInvoices = [];
    for (let i = 0; i < invoices.length; i++) {
        if (invoices[i].invoiceStatus !== "void") {
            invoices[i].invoiceStatus = formatInvoiceStatus(invoices[i].invoiceStatus);
            invoices[i].invoiceNumber = invoices[i].erpProjectNumber + "-" + formatInvoiceNumber(invoices[i].erpInvoiceNumber);
            nonVoidedInvoices.push(invoices[i]);
        }
    }
    return nonVoidedInvoices;
}

function setPaidInvoiceCards(invoices, cardData) {
    for (let i = 0; i < invoices.length; i++) {
        if(invoices[i].invoiceDaysPast > 60) {
            cardData.paidInvoicesCountOver60Days++;
            cardData.paidInvoicesAmountOver60Days += invoices[i].invoiceAmount;
        }
    }
    cardData.paidInvoicesAmountOver60Days = formatToCurrency(cardData.paidInvoicesAmountOver60Days);
}

function setUnpaidInvoiceCards(invoices, cardData) {
    for (let i = 0; i < invoices.length; i++) {
        if(invoices[i].invoiceDaysPast > 60)
        {
            cardData.unpaidInvoicesCountOver60Days++;
            cardData.unpaidInvoicesAmountOver60Days += invoices[i].invoiceAmount;
        }
    }
    cardData.unpaidInvoicesAmountOver60Days = formatToCurrency(cardData.unpaidInvoicesAmountOver60Days);
}

function setShowInvoiceDueDate(unpaidInvoices) {
    let totalInvoices = unpaidInvoices.length;
    const invoiceDueDateCount = unpaidInvoices.reduce(
        (prior, current) => (current.dueDate !== null && current.dueDate !== '' ? prior + 1 : prior), 0
    );
    if(totalInvoices == 0) {
        store.commit("setShowInvoiceDueDate", false);
    }
    else {
        (invoiceDueDateCount / totalInvoices) >= .3 ? store.commit("setShowInvoiceDueDate", true) : store.commit("setShowInvoiceDueDate", false);
    }
}

function getInvoiceChartData(invoices) {
    let invoiceChartData = [];
    let invoiceUnder30 = 0;
    let invoice30to60 = 0;
    let invoice60to90 = 0;
    let invoice90to120 = 0;

    for (let i = 0; i < invoices.length; i++) {
        if(invoices[i].invoiceDaysPast <= 30 && invoices[i].invoiceStatus) {
            invoiceUnder30 += invoices[i].invoiceAmount;
        }
        else if(invoices[i].invoiceDaysPast > 30 && invoices[i].invoiceDaysPast <= 60) {
            invoice30to60 += invoices[i].invoiceAmount;
        }
        else if(invoices[i].invoiceDaysPast > 60 && invoices[i].invoiceDaysPast <= 90) {
            invoice60to90 += invoices[i].invoiceAmount;
        }
        else if(invoices[i].invoiceDaysPast > 90 && invoices[i].invoiceDaysPast <= 120) {
            invoice90to120 += invoices[i].invoiceAmount;
        } 
    }

    invoiceChartData.push(invoiceUnder30);
    invoiceChartData.push(invoice30to60);
    invoiceChartData.push(invoice60to90);
    invoiceChartData.push(invoice90to120);

    return invoiceChartData;
}

function formatInvoicePropsForDisplay(invoices) {
    for (let i = 0; i < invoices.length; i++) {
        invoices[i].invoiceDaysPast = calculateNumberDaysPastFromDate(invoices[i].invoiceDate);
    }
}

function formatInvoiceStatus(status) {
    if(status === "paidexternal") {
        return "Paid";
    }
    else {
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
}

function getInvoicesTotal(invoices) {
    const totalInvoiceAmount = invoices.reduce(
        (prior, current) => prior + current.invoiceAmount, 0,
    );
    return formatToCurrency(totalInvoiceAmount);
}