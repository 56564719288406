<template>
  <div class="card ev-table-edit-fluid-card">
    <div class="grid">
      <div class="col-12">
        <div class="card-header">
          <h4>Users</h4>
        </div>
        <DataTable
          :value="users"
          editMode="row"
          dataKey="userName"
          stripedRows
          :paginator="true"
          :rows="10"
          v-model:editingRows="editingRows"
          @row-edit-save="onUserEditSave"
          :loading="isLoading"
          responsiveLayout="scroll"
        >
          <Column field="givenName" header="First Name" :sortable="true">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column field="familyName" header="Last Name" :sortable="true">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column field="email" header="Email" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Email</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="userRole" header="Role" :sortable="true">
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="userRoles"
                optionLabel="name"
                optionValue="code"
                placeholder="Select User Role"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.name }}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.userRole }}
            </template>
          </Column>
          <Column field="enabled" header="Enabled" :sortable="true">
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="enabledYesNo"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Enabled"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.label }}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ getEnabledLabel(slotProps.data.enabled) }}
            </template>
          </Column>
          <Column :rowEditor="true" style="min-width: 8rem"></Column>
          <Column bodyStyle="text-align:right">
            <template #body="slotProps">
              <Button
                label="Reset Password"
                @click="confirmResetPassword(slotProps.data.userName)"
              />
            </template>
          </Column>
          <template #empty>
            <p style="text-align: center">No records found.</p>
          </template>
        </DataTable>
        <div>
          <Dialog
            v-model:visible="resetPasswordDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="confirmation-content">
              <i
                class="pi pi-exclamation-triangle p-mr-3"
                style="font-size: 2rem"
              />
              <strong
                >Are you sure you want to reset the password for this
                user?</strong
              >
              <p>
                Resetting a user password will require them to attempt to login
                with their old password to begin the process of resetting their
                password.
              </p>
              <p>
                If the user forgot their password, they should attempt to login
                and click the 'forgot password' link at login.
              </p>
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="resetPasswordDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="resetPassword()"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import userService from "@/services/user";

export default {
  setup() {
    const toast = useToast();
    const users = ref([]);
    const isLoading = ref(false);
    const editingRows = ref([]);
    const updateUserResponse = ref();
    const resetPasswordDialog = ref(false);
    const resetPasswordUserName = ref();
    const resetPasswordResponse = ref();
    const enabledYesNo = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
    const userRoles = [
      { name: "Client Admin", code: "clientadmin" },
      { name: "Client User", code: "clientuser" },
      { name: "Client Readonly", code: "clientreadonly" },
    ];

    async function fetchUsers() {
      isLoading.value = true;
      users.value = await userService.getUsers();
      users.value.forEach((user) => {
        user.givenName = user.attributes.find(
          ({ name }) => name === "given_name"
        ).value;
        user.familyName = user.attributes.find(
          ({ name }) => name === "family_name"
        ).value;
        user.email = user.attributes.find(({ name }) => name === "email").value;
      });
      isLoading.value = false;
    }

    async function onUserEditSave(event) {
      let isDataValid = validateData(event.newData);
      if (!isDataValid) {
        return toast.error("Data is Invalid");
      }
      let updateUserModel = buildUser(event.data, event.newData);
      isLoading.value = true;
      updateUserResponse.value = await userService.adminUpdateUser(
        updateUserModel
      );

      isLoading.value = false;

      if (updateUserResponse.value === 200) {
        toast.success("User Updated");
      } else {
        toast.error("Unable to Update User");
      }
      return await fetchUsers();
    }

    async function resetPassword() {
      resetPasswordDialog.value = false;
      isLoading.value = true;
      resetPasswordResponse.value = await userService.adminResetPassword(
        resetPasswordUserName.value
      );
      isLoading.value = false;

      if (resetPasswordResponse.value === 200) {
        toast.success("User Password is Reset");
      } else {
        toast.error("Unable to Reset User Password");
      }
    }

    function buildUser(currentData, newData) {
      let dataToUpdate = {};
      dataToUpdate.userName = currentData.userName;
      dataToUpdate.firstName = newData.givenName;
      dataToUpdate.lastName = newData.familyName;

      if (currentData.enabled !== newData.enabled) {
        dataToUpdate.enabled = newData.enabled;
      }

      if (currentData.userRole !== newData.userRole) {
        dataToUpdate.currentRole = currentData.userRole;
        dataToUpdate.newRole = newData.userRole;
      }
      return dataToUpdate;
    }

    function validateData(dataToValidate) {
      if (
        !dataToValidate.givenName ||
        !dataToValidate.familyName ||
        dataToValidate.givenName.length > 30 ||
        dataToValidate.familyName.length > 30
      ) {
        return false;
      }
      return true;
    }

    function confirmResetPassword(userName) {
      resetPasswordUserName.value = userName;
      resetPasswordDialog.value = true;
    }

    function getEnabledLabel(status) {
      switch (status) {
        case true:
          return "Yes";
        case false:
          return "No";
        default:
          return "NA";
      }
    }

    onMounted(fetchUsers);

    return {
      enabledYesNo,
      userRoles,
      users,
      isLoading,
      editingRows,
      resetPasswordDialog,
      confirmResetPassword,
      fetchUsers,
      resetPassword,
      resetPasswordUserName,
      getEnabledLabel,
      onUserEditSave,
    };
  },
};
</script>
