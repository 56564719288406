<template>
  <div class="login-container">
    <div class="login-page">
      <div class="p-4 card shadow border-round">
        <div class="login-logo-img-wrapper">
          <img
            v-if="subdomainSettings && subdomainSettings.imagePath"
            :src="`${subdomainSettings.imagePath}`"
            class="login-logo-img"
          />
        </div>
        <div
          v-if="subdomainSettings && subdomainSettings.name"
          class="text-center"
        >
          <h4>{{ subdomainSettings.name }}</h4>
        </div>
        <div class="align-items-center clear-left pt-5">
          <div class="ev-login-form">
            <div class="login-card-wrapper">
              <h6 v-if="tenantIdQueryParameter && clientIdQueryParameter">
                Already have a login?
              </h6>
              <div
                v-if="!tenantIdQueryParameter && !clientIdQueryParameter"
                class="padding-top-5em"
              ></div>
              <Button
                label="Sign In"
                type="submit"
                @click="onLogin()"
                class="login-button"
                :loading="isLoading"
                :disabled="isDisabled"
              ></Button>
            </div>

            <div
              v-if="tenantIdQueryParameter && clientIdQueryParameter"
              class="pt-5 login-card-wrapper"
            >
              <h6>Need an account?</h6>
              <div>
                <InputText
                  id="emailAddress"
                  placeholder="Email Address"
                  v-model="emailAddress"
                />
                <template v-if="v$.emailAddress.$dirty">
                  <div
                    class="error-msg text-pink-700"
                    v-for="error of v$.emailAddress.$silentErrors"
                    :key="error.$message"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="pt-3">
                <InputText
                  id="firstName"
                  placeholder="First Name"
                  v-model="firstName"
                />
                <template v-if="v$.firstName.$dirty">
                  <div
                    class="error-msg text-pink-700"
                    v-for="error of v$.firstName.$silentErrors"
                    :key="error.$message"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="pt-3">
                <InputText
                  id="lastName"
                  placeholder="Last Name"
                  v-model="lastName"
                />
                <template v-if="v$.lastName.$dirty">
                  <div
                    class="error-msg text-pink-700"
                    v-for="error of v$.lastName.$silentErrors"
                    :key="error.$message"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <div class="pt-3 text-center">
                <Button
                  label="Create Account"
                  type="submit"
                  @click="onSubmit()"
                  class="login-button"
                  :loading="isLoading"
                  :disabled="
                    v$.emailAddress.$invalid ||
                    v$.firstName.$invalid ||
                    v$.lastName.$invalid
                  "
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          header="New User Sign Up Confirmation"
          v-model:visible="isModalVisible"
          :style="{ width: '50vw' }"
          :modal="true"
          :closeOnEscape="false"
          :closable="false"
          :draggable="false"
        >
          <p>
            <strong
              >Thank you for signing up to our EVPay&trade; Payment
              Portal.</strong
            >
          </p>
          <p>
            Please check your email for a confirmation email containing your
            username and a temporary password. Click 'OK' to be redirected to
            the login page.
          </p>
          <template #footer>
            <Button
              label="OK"
              icon="pi pi-check"
              @click="redirectToLogin()"
              autofocus
            />
          </template>
        </Dialog>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { email, required, maxLength, helpers } from "@vuelidate/validators";
import AppFooter from "@/AppFooter.vue";
import router from "@/router";
import subdomainService from "@/services/subdomain";
import userService from "@/services/user";

export default {
  // eslint-disable-next-line
  name: "SignIn",
  components: {
    AppFooter,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const isLoading = ref(false);
    const subdomainSettings = ref({});
    const currentYear = ref(new Date().getFullYear());
    const emailAddress = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const tenantIdQueryParameter = ref(route.query.tenantId);
    const clientIdQueryParameter = ref(route.query.clientId);
    const isDisabled = ref(false);
    const isModalVisible = ref(false);
    const rules = computed(() => ({
      emailAddress: {
        required: helpers.withMessage("Email Address is required", required),
        email: helpers.withMessage("Email Address is invalid", email),
      },
      firstName: {
        maxLength: helpers.withMessage(
          "First Name cannot be more than 30 characters",
          maxLength(30)
        ),
        required: helpers.withMessage("First Name is required", required),
      },
      lastName: {
        maxLength: helpers.withMessage(
          "Last Name cannot be more than 30 characters",
          maxLength(30)
        ),
        required: helpers.withMessage("Last Name is required", required),
      },
    }));
    const v$ = useVuelidate(
      rules,
      {
        emailAddress,
        firstName,
        lastName,
      },
      { $lazy: true }
    );

    onMounted(fetchSubdomainSettings);

    async function fetchSubdomainSettings() {
      isDisabled.value = true;
      subdomainSettings.value = await subdomainService.verifySubdomain();
      isDisabled.value = false;
    }

    async function onSubmit() {
      if (!(await v$.value.$validate())) {
        return;
      } else {
        isLoading.value = true;
        const createUser = await userService.createUser(
          emailAddress.value,
          firstName.value,
          lastName.value
        );
        if (createUser === true) {
          isLoading.value = false;
          showSignupModal();
        } else {
          isLoading.value = false;
          return toast.error(createUser.message);
        }
      }
    }

    async function showSignupModal() {
      isModalVisible.value = true;
    }

    async function redirectToLogin() {
      await router.push({ path: "/login" });
    }

    async function onLogin() {
      await router.push({ path: "/login" });
    }

    return {
      isLoading,
      subdomainSettings,
      currentYear,
      emailAddress,
      firstName,
      lastName,
      tenantIdQueryParameter,
      clientIdQueryParameter,
      isDisabled,
      isModalVisible,
      v$,
      fetchSubdomainSettings,
      showSignupModal,
      redirectToLogin,
      onSubmit,
      onLogin,
    };
  },
};
</script>
