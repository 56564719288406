<template>
  <div class="card ev-fluid-card">
    <div class="grid">
      <div class="col-12">
        <div class="card-header">
          <h4>Payment Detail</h4>
        </div>
        <DataTable
          v-if="invoicesToPay.length"
          :value="invoicesToPay"
          :loading="isLoading"
          responsiveLayout="scroll"
          dataKey="invoiceId"
        >
          <Column
            v-if="isCombinedInvoiceFormatEnabled === true"
            field="invoiceNumber"
            header="Invoice"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{ slotProps.data.invoiceNumber }}
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            field="erpProjectNumber"
            header="Project"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{ slotProps.data.erpProjectNumber }}
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            field="erpInvoiceNumber"
            header="Invoice"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{ slotProps.data.erpInvoiceNumber }}
            </template>
          </Column>
          <Column
            field="invoiceDate"
            header="Invoice Date"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{ formatCalendarDate(slotProps.data.invoiceDate) }}
            </template>
          </Column>
          <Column
            v-if="showInvoiceDueDate === true"
            field="dueDate"
            header="Due Date"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              <span
                v-if="
                  slotProps.data.dueDate != null && slotProps.data.dueDate != ''
                "
                >{{ formatCalendarDate(slotProps.data.dueDate) }}</span
              >
            </template>
          </Column>
          <Column bodyStyle="text-align:left">
            <template #body="slotProps">
              <Button
                v-if="isInvoiceFileViewerEnabled && slotProps.data.filePath"
                v-tooltip="'View Invoice'"
                icon="pi pi-file-pdf"
                @click="fetchInvoiceFileUrl(slotProps.data.invoiceId)"
              />
            </template>
          </Column>
          <Column
            field="invoiceAmount"
            header="Invoice Amount"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{ formatToCurrency(slotProps.data.invoiceAmount) }}
            </template>
          </Column>
          <Column
            v-if="isColumnVisible"
            field="nonCashAdjustment.nonCashAdjustmentAmount"
            :header="
              cardProcessingPriceProgramType ===
              CARD_PROCESSING_PRICE_PROGRAM.Surcharge
                ? 'Surcharge'
                : 'Non-Cash Adjustment'
            "
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{
                formatToCurrency(
                  slotProps.data.nonCashAdjustment.nonCashAdjustmentAmount
                )
              }}
            </template>
          </Column>
          <Column
            v-if="isColumnVisible"
            field="nonCashAdjustment.paymentAmountAdjusted"
            header="Payment Amount"
            :sortable="!isGuestCheckoutLink"
          >
            <template #body="slotProps">
              {{
                formatToCurrency(
                  slotProps.data.nonCashAdjustment.paymentAmountAdjusted
                )
              }}
            </template>
          </Column>
          <ColumnGroup type="footer">
            <Row>
              <Column
                footer="Total:"
                footerStyle="text-align:right"
                :colspan="colSpan"
              />
              <Column :footer="invoicesTotalAmount" />
              <Column
                v-if="invoicesToPayNonCashAdjustmentTotal"
                :hidden="!isColumnVisible"
                :footer="invoicesToPayNonCashAdjustmentTotal"
              />
              <Column
                v-if="invoicesTotalAmountAdjusted"
                :hidden="!isColumnVisible"
                :footer="invoicesTotalAmountAdjusted"
                footerStyle="font-weight:bold; text-decoration: underline;"
              />
            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
    <div v-if="isWebViewerModalVisible === true">
      <WebViewerModal
        :document="invoiceFileUrl"
        :showModalProp="isWebViewerModalVisible"
        @resetIsVisible="onWebViewerModalDataEvent()"
      />
    </div>
    <div
      v-if="
        guestCheckoutInvoiceStatus === 'paid' ||
        guestCheckoutInvoiceStatus === 'paidexternal'
      "
    >
      <Message severity="success" :sticky="true" :closable="false">
        <strong>This Invoice Is Already Paid</strong>
      </Message>
    </div>
    <div v-if="guestCheckoutInvoiceStatus === 'scheduled'">
      <Message severity="info" :sticky="true" :closable="false">
        <strong>This Invoice Is Scheduled For Payment</strong>
      </Message>
    </div>
    <div v-if="guestCheckoutInvoiceStatus === 'void'">
      <Message severity="warn" :sticky="true" :closable="false">
        <strong>This Invoice Is No Longer Valid</strong>
      </Message>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, toRef, watch } from "vue";
import store from "@/store";
import { formatCalendarDate, formatToCurrency } from "@/utility/helpers";
import { CARD_PROCESSING_PRICE_PROGRAM } from "@/utility/cardProcessingPriceProgram";
import invoiceService from "@/services/invoice";
import WebViewerModal from "../WebViewerModal.vue";

export default {
  components: {
    WebViewerModal,
  },
  props: {
    signedInvoiceIdProp: {
      type: String,
      default: null,
    },
  },
  emits: ["invoiceStatusEvent"],
  setup(props, { emit }) {
    const signedInvoiceId = toRef(props, "signedInvoiceIdProp");
    const cardProcessingPriceProgramType = computed(
      () => store.getters.cardProcessingPriceProgramType
    );
    const isGuestCheckoutLink = computed(() => {
      return signedInvoiceId.value ? true : false;
    });
    const invoicesToPay = computed(() => store.getters.invoicesToPay);
    const invoicesTotalAmount = computed(
      () => store.getters.invoicesToPayTotalAmount
    );
    const invoicesTotalAmountAdjusted = computed(
      () => store.getters.invoicesToPayTotalAmountAdjusted
    );
    const invoicesToPayNonCashAdjustmentTotal = computed(
      () => store.getters.invoicesToPayNonCashAdjustmentTotal
    );
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const isInvoiceFileViewerEnabled = computed(
      () => store.getters.invoiceFileViewerEnabled
    );
    const colSpan = computed(() => {
      if (showInvoiceDueDate.value === true) {
        if (isCombinedInvoiceFormatEnabled.value === true) {
          return 4;
        } else {
          return 5;
        }
      } else {
        if (isCombinedInvoiceFormatEnabled.value === true) {
          return 3;
        } else {
          return 4;
        }
      }
    });
    const isColumnVisible = computed(() => {
      return cardProcessingPriceProgramType.value &&
        cardProcessingPriceProgramType.value !==
          CARD_PROCESSING_PRICE_PROGRAM.None &&
        invoicesToPay.value[0].nonCashAdjustment
        ? true
        : false;
    });
    const showInvoiceDueDate = computed(() => store.getters.showInvoiceDueDate);
    const isLoading = ref(false);
    const guestCheckoutInvoiceStatus = ref();
    const invoiceFileUrl = ref();
    const isWebViewerModalVisible = ref(false);

    watch(isCombinedInvoiceFormatEnabled, () => {
      if (
        isCombinedInvoiceFormatEnabled.value === true &&
        isGuestCheckoutLink.value == true
      ) {
        invoiceService.getInvoiceBySignedId(
          signedInvoiceId.value,
          isCombinedInvoiceFormatEnabled.value
        );
      }
    });

    watch(invoicesToPay, (newValue) => {
      if (newValue.length && isGuestCheckoutLink.value === true) {
        setInvoiceStatusAndEmitInvoiceStatusEvent(newValue[0].invoiceStatus);
      }
    });

    if (signedInvoiceId.value) {
      isLoading.value = true;
      onMounted(async () => {
        const invoiceData = await invoiceService.getInvoiceBySignedId(
          signedInvoiceId.value,
          isCombinedInvoiceFormatEnabled.value
        );
        setInvoiceStatusAndEmitInvoiceStatusEvent(invoiceData.invoiceStatus);
      });
      isLoading.value = false;
    }

    async function fetchInvoiceFileUrl(invoiceId) {
      if (
        store.getters.isAuthenticated === false &&
        isGuestCheckoutLink.value === true
      ) {
        try {
          invoiceFileUrl.value =
            await invoiceService.getInvoiceFileUrlBySignedInvoiceId(
              signedInvoiceId.value
            );
        } catch (error) {
          isWebViewerModalVisible.value = false;
          return;
        }
        isWebViewerModalVisible.value = true;
      } else {
        try {
          invoiceFileUrl.value = await invoiceService.getInvoiceFileUrl(
            invoiceId
          );
        } catch (error) {
          isWebViewerModalVisible.value = false;
          return;
        }
        isWebViewerModalVisible.value = true;
      }
    }

    function onWebViewerModalDataEvent() {
      isWebViewerModalVisible.value = false;
    }

    function setInvoiceStatusAndEmitInvoiceStatusEvent(invoiceStatus) {
      if (invoiceStatus !== "unpaid") {
        guestCheckoutInvoiceStatus.value = invoiceStatus;
        emit("invoiceStatusEvent", false);
      } else {
        guestCheckoutInvoiceStatus.value = invoiceStatus;
        emit("invoiceStatusEvent", true);
      }
    }

    return {
      CARD_PROCESSING_PRICE_PROGRAM,
      cardProcessingPriceProgramType,
      invoicesToPay,
      invoicesTotalAmount,
      invoicesTotalAmountAdjusted,
      invoicesToPayNonCashAdjustmentTotal,
      isCombinedInvoiceFormatEnabled,
      isColumnVisible,
      formatCalendarDate,
      colSpan,
      showInvoiceDueDate,
      isGuestCheckoutLink,
      isLoading,
      guestCheckoutInvoiceStatus,
      isInvoiceFileViewerEnabled,
      invoiceFileUrl,
      isWebViewerModalVisible,
      fetchInvoiceFileUrl,
      onWebViewerModalDataEvent,
      formatToCurrency,
    };
  },
};
</script>
