import store from "../store";
import paymentsClient from "../api/payments.api";
import invoiceService from "../services/invoice";
import { formatCalendarDate, formatToCurrency } from "../utility/helpers";
import { useToast } from "vue-toastification";

export default {
    createClientInvoicePayment: async function (createPaymentDto) {
        const invoicesToPay = await store.getters.invoicesToPay;
        let response = null;
        if (Array.isArray(invoicesToPay) && invoicesToPay.length) {
            try {
                const tenantId = store.getters.tenantId;
                const clientId = store.getters.clientId;
                for (let i = 0; i < invoicesToPay.length; i++) {
                    if (invoicesToPay[i].invoiceStatus === "unpaid") {
                        if (clientId === invoicesToPay[i].clientId) {
                            response = await paymentsClient.createClientInvoicePayment(
                                tenantId,
                                clientId,
                                invoicesToPay[i].invoiceId,
                                createPaymentDto
                            );
                            if (typeof response === "undefined") {
                                let errorMessage = {
                                    error: "Error: EvPay resources are not available at this time - please try again later"
                                }
                                const paymentErrorResponse = await createErrorResponseObject(
                                    invoicesToPay[i],
                                    errorMessage
                                );
                                store.commit("setPaymentData", paymentErrorResponse);
                            } else if (response.status === 201) {
                                const paymentSuccessResponse =
                                    await createSuccessResponseObject(
                                        response.data,
                                        invoicesToPay[i],
                                        createPaymentDto.paymentType
                                    );
                                store.commit("setPaymentData", paymentSuccessResponse);
                            } else if (response.status >= 400) {
                                if (response.status === 400) {
                                    if (response.data.errors && response.data.errors["CardAccount.Number"]) {
                                        if (response.data.errors["CardAccount.Number"][0]) {
                                            let errorMessage = {
                                                error: "Error: " + response.data.errors["CardAccount.Number"][0]
                                            }
                                            const paymentErrorResponse = await createErrorResponseObject(
                                                invoicesToPay[i],
                                                errorMessage
                                            );
                                            store.commit("setPaymentData", paymentErrorResponse);
                                        } else {
                                            let errorMessage = {
                                                error: "Error: One or more credit card fields is invalid. Please verify & update the fields and try submitting payment again"
                                            }
                                            const paymentErrorResponse = await createErrorResponseObject(
                                                invoicesToPay[i],
                                                errorMessage
                                            );
                                            store.commit("setPaymentData", paymentErrorResponse);
                                        }
                                    }
                                    else {
                                        const paymentErrorResponse = await createErrorResponseObject(
                                            invoicesToPay[i],
                                            response.data
                                        );
                                        store.commit("setPaymentData", paymentErrorResponse);
                                    }
                                }
                                if (response.status === 404) {
                                    let errorMessage = {
                                        error: "Error: Unable to locate payment record within EvPay"
                                    }
                                    const paymentErrorResponse = await createErrorResponseObject(
                                        invoicesToPay[i],
                                        errorMessage
                                    );
                                    store.commit("setPaymentData", paymentErrorResponse);
                                }
                            }
                        } else {
                            let errorMessage = {
                                error: "Unable to create payment - User clientId mismatch - clientId: " +
                                    invoicesToPay[i].clientId +
                                    " , invoiceId: " +
                                    invoicesToPay[i].invoiceId
                            }
                            const paymentErrorResponse = await createErrorResponseObject(
                                invoicesToPay[i],
                                errorMessage
                            );
                            store.commit("setPaymentData", paymentErrorResponse);
                        }
                    } else {
                        let errorMessage = {
                            error: "Invoice is already paid - invoice payment not submitted for invoice: " +
                                invoicesToPay[i].erpInvoiceNumber
                        }
                        const paymentErrorResponse = await createErrorResponseObject(
                            invoicesToPay[i],
                            errorMessage
                        );
                        store.commit("setPaymentData", paymentErrorResponse);
                    }
                }
            } catch (error) {
                response = error;
            }
        } else {
            response = new Error(["No invoices selected for payment"]);
        }
        return response;
    },

    createInvoicePaymentFromSignedInvoiceId: async function (signedInvoiceId, createPaymentDto) {
        let response;
        try {
            response = await paymentsClient.createPaymentFromSignedInvoiceId(signedInvoiceId, createPaymentDto);
        }
        catch (error) {
            throw new Error("Unable to submit payment - Message: " + error.message);
        }

        if (response && response.status !== 201) {
            let ex;
            if (response.data.errors) {
                ex = new Error("One or more values entered is invalid");
            }
            else {
                ex = new Error(response.data.error);
            }
            const toast = useToast();
            toast.error(ex.message);
            throw ex.message;
        }

        const invoicesToPay = await store.getters.invoicesToPay;
        const paymentSuccessResponse =
            await createSuccessResponseObject(
                response.data,
                invoicesToPay[0],
                createPaymentDto.paymentType
            );
        store.commit("setPaymentData", paymentSuccessResponse);
    },

    fetchClientPayments: async function (limit) {
        let clientPayments = await fetchPayments(limit);
        if (clientPayments && clientPayments.status !== 200) {
            const ex = new Error("Unable to retrieve payments");
            throw ex.message;
        }
        return clientPayments.data;
    },

    fetchClientPaymentHistory: async function (limit, clearPaginationToken) {
        let clientPayments;
        let payments;
        clientPayments = await fetchPayments(limit, clearPaginationToken);

        if (clientPayments && clientPayments.status !== 200) {
            const ex = new Error("Unable to retrieve payments");
            throw ex.message;
        } else {
            payments = await getInvoiceDataAndFormatPaymentObjects(
                clientPayments.data.data
            );
        }
        return payments;
    },

    fetchPaymentsProcessedTotalAmount: async function () {
        const payments = await store.getters.payments;
        let paymentsTotalAmount = 0;

        for (let i = 0; i < payments.length; i++) {
            if (payments[i].referenceId && !payments[i].errorMessage) {
                if (
                    payments[i].paymentType === "Card" &&
                    payments[i].paymentAmountAdjusted
                ) {
                    paymentsTotalAmount += Number(payments[i].paymentAmountAdjusted);
                } else {
                    paymentsTotalAmount += Number(payments[i].paymentAmount);
                }
            }
        }
        return formatToCurrency(paymentsTotalAmount);
    },

    fetchCardExpirationYears: async function () {
        const currentYear = new Date().getFullYear();
        let addNumberOfYears = currentYear + 15;
        let expirationYearsOptions = [];

        for (let year = currentYear; year < addNumberOfYears; year++) {
            expirationYearsOptions.push({
                name: year.toString(),
                code: year.toString(),
            });
        }
        return expirationYearsOptions;
    },

    fetchCardExpirationMonths: async function () {
        const cardExpireMonthsOptions = [
            { name: "01 - January", code: "01" },
            { name: "02 - February", code: "02" },
            { name: "03 - March", code: "03" },
            { name: "04 - April", code: "04" },
            { name: "05 - May", code: "05" },
            { name: "06 - June", code: "06" },
            { name: "07 - July", code: "07" },
            { name: "08 - August", code: "08" },
            { name: "09 - September", code: "09" },
            { name: "10 - October", code: "10" },
            { name: "11 - November", code: "11" },
            { name: "12 - December", code: "12" },
        ];

        return cardExpireMonthsOptions;
    },

    fetchBankAccountTypes: async function () {
        const bankAccountTypes = [
            { name: "Checking", code: "Checking" },
            { name: "Savings", code: "Savings" },
        ];

        return bankAccountTypes;
    },
};

async function fetchPayments(limit, clearPaginationToken = true) {
    const tenantId = store.getters.tenantId;
    const clientId = store.getters.clientId;
    const paymentProviderSettingsId = store.getters.paymentProviderSettingsId;
    if (clearPaginationToken) {
        store.commit("setClientPaymentPaginationToken", null);
    }
    let paginationToken = store.getters.clientPaymentPaginationToken == null ? "" : store.getters.clientPaymentPaginationToken;
    let response = null;
    try {
        response = await paymentsClient.getClientPayments(
            tenantId,
            clientId,
            paymentProviderSettingsId,
            paginationToken,
            limit
        );
    } catch (error) {
        response = error;
    }

    if (response?.data?.paginationToken != null) {
        store.commit("setClientPaymentPaginationToken", response.data.paginationToken);
    }
    else {
        store.commit("setClientPaymentPaginationToken", null);
    }
    return response;
}

async function createErrorResponseObject(invoice, error) {
    return {
        paymentId: null,
        invoiceId: invoice.invoiceId,
        clientId: invoice.clientId,
        erpInvoiceNumber: invoice.erpInvoiceNumber,
        erpProjectNumber: invoice.erpProjectNumber,
        paymentAmount: invoice.invoiceAmount,
        paymentDateTime: null,
        invoiceDate: invoice.invoiceDate,
        referenceId: null,
        batchId: null,
        errorMessage: error.error,
        paymentStatus: "Payment Failed",
        dueDate: invoice.dueDate,
    };
}

async function createSuccessResponseObject(data, invoice, paymentType) {
    return {
        paymentId: data.paymentId,
        invoiceId: invoice.invoiceId,
        clientId: data.clientId,
        erpInvoiceNumber: invoice.erpInvoiceNumber,
        erpProjectNumber: invoice.erpProjectNumber,
        paymentType: paymentType,
        paymentTypeDetail: data.paymentTypeDetail,
        last4: data.last4,
        paymentAmount: data.paymentAmountAdjusted ?
            data.paymentAmountAdjusted :
            data.paymentAmount,
        paymentDateTime: data.paymentDateTime,
        invoiceDate: invoice.invoiceDate,
        referenceId: data.referenceId,
        batchId: data.batchId,
        errorMessage: null,
        paymentStatus: "Payment Successful",
        dueDate: invoice.dueDate,
    };
}

async function getInvoiceDataAndFormatPaymentObjects(payments) {
    for (let i = 0; i < payments.length; i++) {
        let invoice = await invoiceService.getInvoiceById(
            payments[i].invoiceId
        );

        payments[i].erpInvoiceNumber = invoice.erpInvoiceNumber;
        payments[i].erpProjectNumber = invoice.erpProjectNumber;
        payments[i].paymentAmountCurrency = formatToCurrency(payments[i].paymentAmount);
        payments[i].paymentDate = new Date(payments[i].paymentDateTime);
        payments[i].paymentDateCalendar = formatCalendarDate(payments[i].paymentDate);
        payments[i].invoiceDate = new Date(invoice.invoiceDate.split('-'))
        payments[i].invoiceDateCalendar = formatCalendarDate(payments[i].invoiceDate);
        payments[i].filePath = invoice.filePath;
    }
    return payments;
}