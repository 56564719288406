export const Role = {
    SuperAdmin: 'superadmin',
    InternalAdmin: 'internaladmin',
    ClientAdmin: 'clientadmin',
    ClientUser: 'clientuser',
    ClientReadOnly: 'clientreadonly'
}

export const ADMIN_ROLE_NAMES = ["superadmin", "internaladmin"];
export const CLIENT_ADMIN_ROLE_NAMES = ["superadmin", "internaladmin", "clientadmin"];
export const CLIENT_USER_ROLE_NAMES = ["superadmin", "internaladmin", "clientadmin", "clientuser"];
export const CLIENT_READONLY_ROLE_NAMES = ["superadmin", "internaladmin", "clientadmin", "clientuser", "clientreadonly"];
export const CLIENT_READONLY_ROLE_NAME = ["clientreadonly"];