<template>
  <div>
    <div class="pt-5">
      <div class="card ev-fluid-card">
        <GuestCheckoutHeader />
      </div>
    </div>
    <div class="pt-5">
      <PayInvoicesConfirmation />
    </div>
    <div class="pt-5">
      <div class="card ev-fluid-card">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import GuestCheckoutHeader from "../components/Client/GuestCheckoutHeader.vue";
import PayInvoicesConfirmation from "../components/Client/PayInvoicesConfirmation.vue";
import AppFooter from "../AppFooter.vue";

export default {
  components: {
    GuestCheckoutHeader,
    PayInvoicesConfirmation,
    AppFooter,
  },
};
</script>
