"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async getUsers(tenantId, clientId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/users`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getUserById(tenantId, clientId, userId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/users/${userId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async postUser(tenantId, clientId, createUserDto) {
        let response;
        try {
            response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/users`,
                createUserDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async adminUpdateUser(tenantId, clientId, userId, updateUserDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.patch(
                `/tenants/${tenantId}/clients/${clientId}/users/${userId}`,
                updateUserDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async updateUser(tenantId, clientId, updateUserDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.patch(
                `/tenants/${tenantId}/clients/${clientId}/users`,
                updateUserDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async adminResetPassword(tenantId, clientId, adminResetDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/users/${adminResetDto.userId}/admin-reset-password`,
                adminResetDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async resetPassword(tenantId, clientId, resetDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.post(
                `/tenants/${tenantId}/clients/${clientId}/users/reset-password`,
                resetDto, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};