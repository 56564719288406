<template>
  <div>
    <a
      class="modal-account-image"
      href="#"
      target="_self"
      @click="openAccountInfoImage()"
    >
      <span>Help Finding Account Information</span>
      <Dialog
        header="Bank Account Information"
        v-model:visible="displayAccountInfoImage"
        :style="{ width: '50vw' }"
      >
        <img
          class="account-info-image"
          src="/images/evpay_assets/account_infor.jpg"
          alt="account_information"
          style="
            max-width: 75%;
            max-height: 75%;
            height: auto;
            margin: auto;
            display: flex;
            justify-content: center;
          "
        />
      </Dialog>
    </a>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const displayAccountInfoImage = ref(false);

    const openAccountInfoImage = () => {
      displayAccountInfoImage.value = true;
    };

    return {
      displayAccountInfoImage,
      openAccountInfoImage,
    };
  },
};
</script>
