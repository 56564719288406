"use strict";
import httpClient from "../httpClient";
import store from "@/store";

export default {
    async createPaymentProviderSettings(
        tenantId,
        createPaymentProviderSettingsDto
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.post(
                `/tenants/${tenantId}/payment-provider-settings`,
                createPaymentProviderSettingsDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getPaymentProviderSettingsByTenantId(tenantId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/payment-provider-settings`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getPaymentProviderSettingsById(tenantId, paymentProviderSettingsId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getMerchantSettings(tenantId, paymentProviderSettingsId) {
            const bearerToken = store.getters.accessToken;
            var response = await httpClient.get(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}/merchant-settings`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        return response;
    },

    async updateMerchantSettings(tenantId, paymentProviderSettingsId, merchantSettingsDto) {
        try {
            const bearerToken = store.getters.accessToken;
            var response = await httpClient.put(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}/merchant-settings`,
                merchantSettingsDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async updatePaymentProviderSettings(
        tenantId,
        paymentProviderSettingsId,
        updatePaymentProviderSettingsDto
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = await httpClient.patch(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}`,
                updatePaymentProviderSettingsDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async uploadLogo(tenantId, paymentProviderSettingsId, formData) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.put(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}/logo`,
                formData, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async updateNotificationEmailAddresses(tenantId, paymentProviderSettingsId, emailDtos) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.put(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}/notification-email-addresses`,
                emailDtos, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
}