"use strict";
import httpClient from "../httpClient";
import store from "@/store";

export default {
    async createErpIntegrationSettings(tenantId, createErpIntegrationSettingsDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.post(
                `/tenants/${tenantId}/erp-integration-settings`,
                createErpIntegrationSettingsDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getErpIntegrationSettingsByTenantId(tenantId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/erp-integration-settings`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getErpIntegrationSettingsById(tenantId, erpIntegrationSettingsId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/erp-integration-settings/${erpIntegrationSettingsId}`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async updateErpIntegrationSettings(tenantId, erpIntegrationSettingsId, updateErpIntegrationSettingsDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.put(
                `/tenants/${tenantId}/erp-integration-settings/${erpIntegrationSettingsId}`,
                updateErpIntegrationSettingsDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
}