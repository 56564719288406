<template>
  <div class="card ev-fluid-card">
    <div class="card-header">
      <h4>Merchant Settings</h4>
    </div>
    <div class="formgrid grid">
      <div v-if="isValidMerchantSettings" class="field col-12 md:col-6">
        <label for="enableAchPayments">ACH Payments Enabled:</label>
        <div>
          <InputSwitch
            v-model="merchantSettings.enableAchPayments"
            @change="updateMerchantSettings()"
          />
        </div>
      </div>
      <div v-if="isValidMerchantSettings" class="field col-12 md:col-6">
        <label for="enableCardPayments">Card Payments Enabled:</label>
        <div>
          <InputSwitch
            v-model="merchantSettings.enableCardPayments"
            @change="updateMerchantSettings()"
          />
        </div>
      </div>
      <div v-if="!isValidMerchantSettings" class="field col-12">
        <Message severity="warn" :sticky="true" :closable="false">
          <strong
            >There was a problem retrieving Merchant Settings for this
            Company.</strong
          >
        </Message>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted } from "vue";
import paymentProviderSettingsService from "@/services/Admin/paymentProviderSettings";
import { useToast } from "vue-toastification";

export default {
  props: {
    tenantIdProp: { type: String },
    paymentProviderSettingsIdProp: { type: String },
  },
  setup(props) {
    const toast = useToast();
    const tenantId = toRef(props, "tenantIdProp");
    const isValidMerchantSettings = ref(true);
    const paymentProviderSettingsId = toRef(
      props,
      "paymentProviderSettingsIdProp"
    );
    const merchantSettings = ref({});
    const updateMerchantSettingsDto = ref({
      payment: {},
    });
    const updateMerchantSettingsResponse = ref();

    const fetchMerchantSettings = async () => {
      try {
        merchantSettings.value =
          await paymentProviderSettingsService.getMerchantSettings(
            tenantId.value,
            paymentProviderSettingsId.value
          );
      } catch {
        isValidMerchantSettings.value = false;
      }
    };

    onMounted(fetchMerchantSettings);

    async function updateMerchantSettings() {
      updateMerchantSettingsDto.value.payment.enableAchPayments =
        merchantSettings.value.enableAchPayments;
      updateMerchantSettingsDto.value.payment.enableCardPayments =
        merchantSettings.value.enableCardPayments;

      updateMerchantSettingsResponse.value =
        await paymentProviderSettingsService.updateMerchantSettings(
          tenantId.value,
          paymentProviderSettingsId.value,
          updateMerchantSettingsDto.value
        );

      if (updateMerchantSettingsResponse.value == 200) {
        toast.success("Merchant Settings Updated");
      } else {
        toast.error("Unable to Update Merchant Settings");
      }
    }

    return {
      merchantSettings,
      isValidMerchantSettings,
      updateMerchantSettings,
    };
  },
};
</script>