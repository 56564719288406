import store from "../store";
import invoicesClient from "../api/invoices.api";
import helperFunctions from "../utility/helpers";

export default {
    getInvoiceSurcharge: async function (bin, customerId, cardId) {
        store.commit("setNonCashAdjustmentPercent", null);

        const invoicesToPay = await store.getters.invoicesToPay;
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;

        let invoicesToPayWithNonCashAdjustments = [];
        let response;

        for (let i = 0; i < invoicesToPay.length; i++) {
            try {
                if (bin) {
                    response = await invoicesClient.getSurchargeByBin(
                        tenantId,
                        clientId,
                        invoicesToPay[i].invoiceId,
                        bin
                    );
                }
                if (customerId && cardId) {
                    response = await invoicesClient.getSurchargeByStoredCardId(
                        tenantId,
                        clientId,
                        invoicesToPay[i].invoiceId,
                        customerId,
                        cardId
                    )
                }

                response.data.invoiceNumber = invoicesToPay[i]?.invoiceNumber;
                if (response.data.nonCashAdjustment) {
                    invoicesToPayWithNonCashAdjustments.push(response.data);
                }
            }
            catch (error) {
                await RestoreInvoicesWithoutNonCashAdjument(tenantId, clientId, invoicesToPay);
                const ex = new Error("Invalid card number");
                throw ex.message;
            }
        }
        if (!Array.isArray(invoicesToPayWithNonCashAdjustments) || !invoicesToPayWithNonCashAdjustments.length) {
            await RestoreInvoicesWithoutNonCashAdjument(tenantId, clientId, invoicesToPay);
        }
        else {
            store.commit("setInvoices", invoicesToPayWithNonCashAdjustments);
            CalculateInvoicesTotalWithNonCashAdjustmentsAndCommitToStore(invoicesToPayWithNonCashAdjustments);
        }
    },
    getInvoiceSurchargeFromSignedInvoiceId: async function (signedInvoiceId, bin) {
        store.commit("setNonCashAdjustmentPercent", null);
        const invoicesToPay = await store.getters.invoicesToPay;

        let response;
        try {
            response = await invoicesClient.getSurchargeBySignedInvoiceId(signedInvoiceId, bin);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve surcharge amount for invoice");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const invoice = await invoicesClient.getInvoiceBySignedInvoiceId(signedInvoiceId);
            invoice.data.invoiceNumber = invoicesToPay[0].invoiceNumber;
            let invoiceArray = [];
            invoiceArray.push(invoice.data);
            store.commit("setInvoices", invoiceArray);
            throw response.data.error;
        }

        response.data.invoiceNumber = invoicesToPay[0]?.invoiceNumber;
        let invoiceArray = [];
        invoiceArray.push(response.data);
        store.commit("setInvoices", invoiceArray);

        if (response.data.nonCashAdjustment) {
            CalculateInvoicesTotalWithNonCashAdjustmentsAndCommitToStore(invoiceArray);
        }
    },
    getInvoiceNonCashAdjustment: async function () {
        const invoicesToPay = await store.getters.invoicesToPay;
        const tenantId = store.getters.tenantId;
        const clientId = store.getters.clientId;

        let invoicesToPayWithNonCashAdjustments = [];
        let response;
        try {
            for (let i = 0; i < invoicesToPay.length; i++) {
                response = await invoicesClient.getNonCashAdjustment(
                    tenantId,
                    clientId,
                    invoicesToPay[i].invoiceId
                );

                if (response && response.status === 200) {
                    response.data.invoiceNumber = invoicesToPay[i]?.invoiceNumber;
                    invoicesToPayWithNonCashAdjustments.push(response.data);
                }
            }
            store.commit("setInvoices", invoicesToPayWithNonCashAdjustments);
            CalculateInvoicesTotalWithNonCashAdjustmentsAndCommitToStore(invoicesToPayWithNonCashAdjustments);
        } catch (error) {
            const ex = new Error("Unable to retrieve non-cash adjustment amount for invoice");
            throw ex.message;
        }
    },
    getInvoiceNonCashAdjustmentFromSignedInvoiceId: async function (signedInvoiceId) {
        const invoicesToPay = await store.getters.invoicesToPay;
        let invoiceToPayWithNonCashAdjustment = [];
        let response;
        try {
            response = await invoicesClient.getNonCashAdjustmentBySignedInvoiceId(signedInvoiceId);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve surcharge amount for invoice");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve surcharge amount for invoice");
            throw ex.message;
        }
        response.data.invoiceNumber = invoicesToPay[0]?.invoiceNumber;
        invoiceToPayWithNonCashAdjustment.push(response.data);
        store.commit("setInvoices", invoiceToPayWithNonCashAdjustment);
        CalculateInvoicesTotalWithNonCashAdjustmentsAndCommitToStore(invoiceToPayWithNonCashAdjustment);
    },
};

function CalculateInvoicesTotalWithNonCashAdjustmentsAndCommitToStore(invoices) {

    const nonCashAdjustmentPercent = invoices[0].nonCashAdjustment.nonCashAdjustmentPercent * 100;
    store.commit("setNonCashAdjustmentPercent", nonCashAdjustmentPercent);

    const totalPaymentAmountAdjusted = invoices.reduce(
        (prior, current) => prior + current.nonCashAdjustment.paymentAmountAdjusted, 0,
    );

    const totalNonCashAdjustmentAmount = invoices.reduce(
        (prior, current) => prior + current.nonCashAdjustment.nonCashAdjustmentAmount, 0,
    );

    const totalPaymentAmountAdjustedCurrency = helperFunctions.formatToCurrency(totalPaymentAmountAdjusted);
    store.commit(
        "setInvoicesToPayTotalAmountAdjusted",
        totalPaymentAmountAdjustedCurrency
    );

    const totalNonCashAdjustmentAmountCurrency = helperFunctions.formatToCurrency(totalNonCashAdjustmentAmount);
    store.commit(
        "setInvoicesToPayNonCashAdjustmentTotal",
        totalNonCashAdjustmentAmountCurrency
    );
}

async function RestoreInvoicesWithoutNonCashAdjument(tenantId, clientId, invoices) {
    let rolledBackInvoices = [];
    let response;
    for (let i = 0; i < invoices.length; i++) {
        response = await invoicesClient.getInvoiceById(tenantId, clientId, invoices[i].invoiceId);
        response.data.invoiceNumber = invoices[i]?.invoiceNumber;
        rolledBackInvoices.push(response.data);
    }
    store.commit("setInvoices", rolledBackInvoices);
}