<template>
  <div>
    <div class="card ev-fluid-card-wide">
      <DataTable
        :value="payments"
        :paginator="true"
        :rows="10"
        :loading="isLoading"
        :globalFilterFields="[
          'erpInvoiceNumber',
          'erpProjectNumber',
          'invoiceNumber',
          'paymentAmount',
          'paymentMethod',
          'referenceId',
          'invoiceDateCalendar',
          'paymentDateCalendar',
        ]"
        :multiSortMeta="[{ field: 'paymentDate', order: -1 }]"
        sortMode="multiple"
        class="p-datatable-payments"
        responsiveLayout="scroll"
        v-model:filters="filters"
        filterDisplay="menu"
      >
        <template #header>
          <div class="flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Keyword Search"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <p style="text-align: center">Loading payment data...</p>
        </template>

        <Column
          v-if="isCombinedInvoiceFormatEnabled === true"
          header="Invoice"
          field="invoiceNumber"
          style="min-width: 10rem"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="image-text">{{ data.invoiceNumber }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by invoice"
            />
          </template>
        </Column>
        <Column
          v-if="isCombinedInvoiceFormatEnabled === false"
          header="Project"
          field="erpProjectNumber"
          style="min-width: 10rem"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="image-text">{{ data.erpProjectNumber }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by project number"
            />
          </template>
        </Column>
        <Column
          v-if="isCombinedInvoiceFormatEnabled === false"
          header="Invoice"
          field="erpInvoiceNumber"
          style="min-width: 10rem"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="image-text">{{ data.erpInvoiceNumber }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by invoice number"
            />
          </template>
        </Column>
        <Column
          header="Invoice Date"
          field="invoiceDate"
          dataType="date"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="{ data }">
            {{ data.invoiceDateCalendar }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
            />
          </template>
        </Column>
        <Column
          header="Amount"
          field="paymentAmount"
          dataType="numeric"
          style="min-width: 10rem"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.paymentAmountCurrency }}
          </template>
          <template #filter="{ filterModel }">
            <InputNumber
              v-model="filterModel.value"
              mode="currency"
              currency="USD"
              locale="en-US"
              placeholder="Search by payment amount"
            />
          </template>
        </Column>
        <Column
          header="Payment Date"
          field="paymentDate"
          dataType="date"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="{ data }">
            {{ data.paymentDateCalendar }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
            />
          </template>
        </Column>
        <Column
          header="Method"
          field="paymentMethod"
          style="min-width: 10rem"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="image-text">{{ data.paymentMethod }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by payment method"
            />
          </template>
        </Column>
        <Column v-if="isInvoiceFileViewerEnabled" bodyStyle="text-align:center">
          <template #body="{ data }">
            <Button
              v-if="data.filePath"
              v-tooltip="'View Invoice'"
              icon="pi pi-file-pdf"
              @click="fetchInvoiceFileUrl(data.invoiceId)"
            />
          </template>
        </Column>
        <Column
          header="Reference"
          field="referenceId"
          style="min-width: 10rem"
          dataType="text"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="image-text">{{ data.referenceId }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by reference id"
            />
          </template>
        </Column>
        <template #empty>
          <p style="text-align: center">No records found.</p>
        </template>
      </DataTable>
      <div class="pt-2 text-center">
        <Button
          v-if="payments.length && clientPaymentPaginationToken"
          label="More Payments"
          :loading="isLoading"
          @click="getPaymentHistory(false)"
        />
      </div>
      <div v-if="isWebViewerModalVisible === true">
        <WebViewerModal
          :document="invoiceFileUrl"
          :showModalProp="isWebViewerModalVisible"
          @resetIsVisible="onWebViewerModalDataEvent()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import paymentService from "@/services/payment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useToast } from "vue-toastification";
import { formatInvoiceNumber } from "@/utility/helpers";
import invoiceService from "@/services/invoice";
import WebViewerModal from "../WebViewerModal.vue";
import store from "@/store";

export default {
  components: {
    WebViewerModal,
  },
  setup() {
    const toast = useToast();
    const paginatedResults = ref([]);
    const payments = ref([]);
    const isLoading = ref(true);
    const invoiceFileUrl = ref();
    const clientPaymentPaginationToken = computed(
      () => store.getters.clientPaymentPaginationToken
    );
    const isWebViewerModalVisible = ref(false);
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const isInvoiceFileViewerEnabled = computed(
      () => store.getters.invoiceFileViewerEnabled
    );

    onMounted(getPaymentHistory);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      erpInvoiceNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      erpProjectNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      invoiceNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      invoiceDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      paymentAmount: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      paymentDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      paymentMethod: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      referenceId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    async function getPaymentHistory(clearPaginationToken) {
      isLoading.value = true;
      let limit = 10;
      try {
        paginatedResults.value = await paymentService.fetchClientPaymentHistory(
          limit,
          clearPaginationToken
        );
        paginatedResults.value.forEach((result) => {
          result.paymentMethod =
            (result.paymentTypeDetail == null ? "" : result.paymentTypeDetail) +
            " - " +
            (result.last4 == null ? "" : result.last4);
          result.invoiceNumber =
            result.erpProjectNumber +
            "-" +
            formatInvoiceNumber(result.erpInvoiceNumber);
          payments.value.push(result);
        });
      } catch (error) {
        toast.error(error);
      }
      isLoading.value = false;
    }

    async function fetchInvoiceFileUrl(invoiceId) {
      invoiceFileUrl.value = await invoiceService.getInvoiceFileUrl(invoiceId);
      isWebViewerModalVisible.value = true;
    }

    function onWebViewerModalDataEvent() {
      isWebViewerModalVisible.value = false;
    }

    return {
      payments,
      isLoading,
      filters,
      clientPaymentPaginationToken,
      isWebViewerModalVisible,
      isCombinedInvoiceFormatEnabled,
      isInvoiceFileViewerEnabled,
      invoiceFileUrl,
      getPaymentHistory,
      fetchInvoiceFileUrl,
      onWebViewerModalDataEvent,
    };
  },
};
</script>