<template>
  <div>
    <h2 v-if="loading === false">Loading...</h2>
    <h2 v-if="loading === true">Redirecting to Login Page...</h2>
  </div>
</template>

<script>
import { ref } from "vue";
import auth from "@/services/auth";
import subdomainService from "@/services/subdomain";
import store from "@/store";

export default {
  setup() {
    const loading = ref(false);

    if (store.getters.paymentProviderSettingsId) {
      loading.value = true;
      auth.authenticate();
    } else {
      async () => await subdomainService.verifySubdomain();
      loading.value = true;
      auth.authenticate();
    }

    return {
      loading,
    };
  },
};
</script>