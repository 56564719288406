"use strict";
import axios from "axios";
import router from "../router";
import {
    useToast
} from "vue-toastification";
import store from "../store";
import {
    Role
} from "@/utility/role";

axios.defaults.baseURL = process.env.VUE_APP_EVPAY_DEFAULT_API_BASE_URL;
axios.defaults.responseType = process.env.VUE_APP_RESPONSE_TYPE;
axios.defaults.timeout = process.env.VUE_APP_TIMEOUT;

const httpClient = axios.create();

export default httpClient;

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response.status === 401) {
            const userRole = await store.getters.userRole;
            const toast = useToast();
            toast.error("Session has expired. Please log in again.")
            if (userRole === Role.InternalAdmin) {
                setTimeout(function () {
                    router.push({
                        name: "AdminLogout"
                    })
                }, 3000);
            } else {
                setTimeout(function () {
                    router.push({
                        name: "Logout"
                    })
                }, 3000);
            }
            return Promise.reject(error);
        }

        let errorComponent = 'Error';
        switch (error.response.status) {
            case 400:
                return Promise.reject(error);
            case 403:
                errorComponent = 'Forbidden';
                break;
            case 404:
                errorComponent = 'NotFound';
                break;
        }

        await router.push({
            name: errorComponent,
        });
    }
);