<template>
  <div>
    <Dialog
      :style="{ width: '60vw' }"
      v-model:visible="isModalVisible"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
      :draggable="false"
    >

    <WebViewerWrapper :initialDoc="doc" />

    <template #footer>
      <Button
        label="OK"
        icon="pi pi-check"
        @click="emitWebViewerModalDataEvent()"
      />
    </template>
    </Dialog>
  </div>
</template>

<script>
import { toRef } from "vue";
import WebViewerWrapper from "./WebViewerWrapper.vue";

export default {
  components: {
    WebViewerWrapper
  },
  props: {
    document: {
      type: String,
    },
    showModalProp: {
      type: Boolean,
    },
  },
  emits: ["resetIsVisible"],
  setup(props, context) {
    const doc = toRef(props, "document");
    const isModalVisible = toRef(props, "showModalProp");

    function emitWebViewerModalDataEvent() {
      context.emit("resetIsVisible", false);
    }

    return {
      doc,
      isModalVisible,
      emitWebViewerModalDataEvent,
    };
  },
};
</script>
