import tenantsClient from "@/api/Admin/tenants.api";

export default {
    createTenant: async function (createTenantDto) {
        let response;
        try {
            response = await tenantsClient.createTenant(createTenantDto);
        } catch (error) {
            const ex = new Error("Unable to create Tenant");
            throw ex.message;
        }
        return response.status;
    },

    getTenantDetail: async function (tenantId) {
        let tenant;
        try {
            tenant = await this.getTenantById(tenantId);

            if (tenant.appClientId != null) {
                let userPoolAppClientData = await this.getUserPoolAppClient(tenant.tenantId, tenant.appClientId);
                tenant.appClientSecret = userPoolAppClientData.clientSecret;
            }

            if (tenant.apiKeyId != null) {
                let apiKeyData = await this.getApiKeyConfiguration(tenant.tenantId, tenant.apiKeyId);
                tenant.apiValue = apiKeyData.value;
            }

        } catch (error) {
            const ex = new Error("Unable to retrieve tenant detail");
            throw ex.message;
        }
        return tenant;
    },

    getAllTenants: async function () {
        let response;
        try {
            response = await tenantsClient.getAllTenants();
        } catch (error) {
            const ex = new Error("Unable to retrieve tenants");
            throw ex.message;
        }
        return response.data;
    },

    getTenantById: async function (tenantId) {
        let response;
        try {
            response = await tenantsClient.getTenantById(tenantId);
        } catch (error) {
            const ex = new Error("Unable to retrieve tenant");
            throw ex.message;
        }
        return response.data;
    },

    updateTenant: async function (updateTenantDto) {
        let response;
        try {
            response = await tenantsClient.updateTenant(updateTenantDto);
        } catch (error) {
            const ex = new Error("Unable to update Tenant");
            throw ex.message;
        }

        if (response && response.status !== 204) {
            const ex = new Error("Unable to update Tenant");
            throw ex.message;
        }
        return response.status;
    },

    getUserPoolAppClient: async function (tenantId, appClientId) {
        let response;
        try {
            response = await tenantsClient.getUserPoolAppClient(tenantId, appClientId);
        } catch (error) {
            const ex = new Error("Unable to retrieve UserPoolApplClient");
            throw ex.message;
        }
        return response.data;
    },

    getApiKeyConfiguration: async function (tenantId, apiKeyId) {
        let response;
        try {
            response = await tenantsClient.getApiKeyConfiguration(tenantId, apiKeyId);
        } catch (error) {
            const ex = new Error("Unable to retrieve Api Key");
            throw ex.message;
        }
        return response.data;
    },
};