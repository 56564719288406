<template>
  <div class="card ev-fluid-card-wide">
    <DataTable
      :value="paymentProviderSettings"
      :paginator="true"
      :rows="15"
      :loading="isLoading"
      :globalFilterFields="['paymentProviderSettingsId', 'name', 'subDomain']"
      :multiSortMeta="[{ field: 'name', order: 1 }]"
      sortMode="multiple"
      class="p-datatable-customers"
      responsiveLayout="scroll"
      dataKey="paymentProviderSettingsId"
      v-model:filters="filters"
      filterDisplay="menu"
    >
      <template #header>
        <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </template>
      <template #loading>
        <p style="text-align: center">
          Loading tenant payment provider settings...
        </p>
      </template>
      <Column
        field="paymentProviderSettingsId"
        header="PaymentProviderSettingsId"
        sortable
      ></Column>
      <Column field="name" header="Name" sortable></Column>
      <Column field="subDomain" header="Subdomain" sortable></Column>
      <Column bodyStyle="text-align:left">
        <template #body="slotProps">
          <Button
            v-tooltip="'View Tenant Summary'"
            icon="pi pi-pencil"
            @click="
              viewTenantSummary(
                slotProps.data.paymentProviderSettingsId,
                slotProps.data.erpIntegrationSettingsId
              )
            "
          />
        </template>
      </Column>
      <template #empty>
        <p style="text-align: center">No records found.</p>
      </template>
    </DataTable>
    <div>
      <Button label="Add New Company" @click="addCompany()" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import paymentProviderSettingsService from "@/services/Admin/paymentProviderSettings";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import router from "@/router";

export default {
  name: "TenantCompanies",
  setup() {
    const route = useRoute();
    const tenantId = ref(route.params.tenantId);
    const paymentProviderSettings = ref([]);
    const isLoading = ref(true);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      paymentProviderSettingsId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      subDomain: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    onMounted(fetchTenantPaymentProviderSettingsDetail);

    async function fetchTenantPaymentProviderSettingsDetail() {
      paymentProviderSettings.value =
        await paymentProviderSettingsService.getTenantPaymentProviderSettingsDetail(
          tenantId.value
        );
      isLoading.value = false;
    }

    async function viewTenantSummary(
      paymentProviderSettingsId,
      erpIntegrationSettingsId
    ) {
      await router.push({
        name: "TenantSummary",
        params: {
          tenantId: tenantId.value,
          paymentProviderSettingsId: paymentProviderSettingsId,
          erpIntegrationSettingsId: erpIntegrationSettingsId,
        },
      });
    }

    async function addCompany() {
      await router.push({
        name: "PaymentProviderSettingsDetail",
        params: {
          tenantId: tenantId.value
        },
      });
    }

    return {
      tenantId,
      paymentProviderSettings,
      filters,
      isLoading,
      viewTenantSummary,
      addCompany,
    };
  },
};
</script>