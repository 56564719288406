<template>
  <div v-if="isAuthenticated === true" class="layout-topbar">
    <div v-if="imagePath" class="client-logo-wrapper">
      <img class="client-logo-img" :src="imagePath" alt="client-logo" />
    </div>
    <div class="topbar-left">
      <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
        <i class="pi pi-chevron-left"></i>
      </a>
      <span class="topbar-separator"></span>
      <div class="topbar-left-title">
        <div
          class="layout-breadcrumb viewname"
          style="text-transform: uppercase"
        >
          <template v-if="$route.meta.breadcrumb">
            <span>{{ $route.meta.breadcrumb[0].label }}</span>
          </template>
        </div>
      </div>
    </div>

    <AppMenu
      :model="menu"
      :layoutMode="layoutMode"
      :active="menuActive"
      :mobileMenuActive="staticMenuMobileActive"
      @menu-click="onMenuClick"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    ></AppMenu>

    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
      <ul class="topbar-menu">
        <li
          class="profile-item"
          :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }"
        >
          <a href="#" @click="onTopbarUserMenuButtonClick">
            <span class="profile-name">{{ firstName }} {{ lastName }}</span>
          </a>
          <ul class="profile-menu fade-in-up">
            <li v-if="!isInternalUser">
              <a href="/stored-payment-methods">
                <i class="pi pi-wallet"></i>
                <span>Stored Payment Methods</span>
              </a>
            </li>
            <li>
              <a href="/profile">
                <i class="pi pi-user-edit"></i>
                <span>Profile</span>
              </a>
            </li>
            <li v-if="!isInternalUser">
              <a href="#" @click="showContactModal">
                <i class="pi pi-building"></i>
                <span>Contact</span>
              </a>
            </li>
            <li v-if="!isInternalUser">
              <a href="/logout">
                <i class="pi pi-power-off"></i>
                <span>Logout</span>
              </a>
            </li>
            <li v-if="isInternalUser">
              <a href="/admin-logout">
                <i class="pi pi-power-off"></i>
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <Dialog
      header="Contact Information"
      v-model:visible="displayContactModal"
      :style="{ width: '50vw' }"
    >
      <div class="card ev-fluid-card">
        <div class="grid">
          <div class="col-12">
            <div class="contact-logo" v-if="imagePath">
              <img
                class="contact-logo-img"
                :src="imagePath"
                alt="company-logo"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="contact-address">
              <span v-if="name" class="contact-address-name">
                <strong>{{ name }}</strong>
              </span>
              <br v-if="name" />
              <span v-if="addressLine1">{{ addressLine1 }} </span>
              <br v-if="addressLine1" />
              <span v-if="addressLine2">{{ addressLine2 }}</span>
              <br v-if="addressLine2" />
              <span
                >{{ city }},
                {{ state }}
                {{ zipCode }}</span
              >
              <br v-if="city || state || zipCode" />
              <span v-if="phoneNumber"> {{ phoneNumber }}</span>
              <br v-if="phoneNumber" />
              <span v-if="contactEmailAddress">{{
                contactEmailAddress
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import AppMenu from "./AppMenu";
import paymentProviderSettingService from "./services/paymentProviderSetting";
import subdomainService from "./services/subdomain";
import store from "./store";
import { ADMIN_ROLE_NAMES } from "./utility/role";

export default {
  name: "AppTopbar",
  components: {
    AppMenu,
  },
  emits: [
    "menu-click",
    "menuitem-click",
    "root-menuitem-click",
    "menu-button-click",
    "search-click",
    "topbar-notification",
    "topbar-user-menu",
    "right-menubutton-click",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    layoutMode: String,
    menu: Array,
    menuActive: Boolean,
    staticMenuMobileActive: Boolean,
  },
  setup() {
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const imagePath = computed(() => store.getters.imagePath);
    const firstName = computed(() => store.getters.given_name);
    const lastName = computed(() => store.getters.family_name);

    const displayContactModal = ref(false);
    const name = computed(() => store.getters.name);
    const addressLine1 = computed(() => store.getters.addressLine1);
    const addressLine2 = computed(() => store.getters.addressLine2);
    const city = computed(() => store.getters.city);
    const state = computed(() => store.getters.state);
    const zipCode = computed(() => store.getters.zipCode);
    const phoneNumber = computed(() => store.getters.phoneNumber);
    const contactEmailAddress = computed(
      () => store.getters.contactEmailAddress
    );
    const isInternalUser = computed(() =>
      ADMIN_ROLE_NAMES.includes(store.getters.userRole)
    );

    const showContactModal = () => {
      displayContactModal.value = true;
    };

    async function fetchPaymentProviderDetail() {
      if (
        isAuthenticated.value === true &&
        store.getters.paymentProviderSettingsId == null &&
        isInternalUser.value === false
      ) {
        await subdomainService.verifySubdomain();
      }

      await paymentProviderSettingService.fetchPaymentProviderDetail();
    }

    onMounted(fetchPaymentProviderDetail);

    return {
      isAuthenticated,
      imagePath,
      firstName,
      lastName,
      name,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      phoneNumber,
      contactEmailAddress,
      displayContactModal,
      isInternalUser,
      showContactModal,
    };
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
    onMenuButtonClick(event) {
      this.$emit("menu-button-click", event);
    },
    onSearchClick(event) {
      this.$emit("search-click", event);
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event) {
      this.$emit("topbar-user-menu", event);
    },
    onRightMenuClick(event) {
      this.$emit("right-menubutton-click", event);
    },
    isMobile() {
      return window.innerWidth <= 1091;
    },
  },
};
</script>