<template>
  <div><UpdateUserInformation /></div>
  <div class="pt-5"><ResetUserPassword /></div>
</template>

<script>
import UpdateUserInformation from "../components/UpdateUserInformation.vue";
import ResetUserPassword from "../components/ResetUserPassword.vue";

export default {
  // eslint-disable-next-line
  name: "Profile",
  components: {
    UpdateUserInformation,
    ResetUserPassword,
  },
};
</script>