<template>
  <div class="card ev-fluid-card">
    <div class="grid">
      <div class="col-12">
        <div class="card-header">
          <h4>Current Unpaid Invoices</h4>
        </div>

        <DataTable
          :value="invoices"
          :paginator="true"
          :rows="10"
          responsiveLayout="scroll"
          v-model:selection="invoicesToPay"
          dataKey="invoiceId"
          sortField="dueDate"
          :sortOrder="1"
          :loading="loading"
        >
          <Column
            v-if="isCombinedInvoiceFormatEnabled === true"
            field="invoiceNumber"
            header="Invoice"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Invoice</span>
              {{ slotProps.data.invoiceNumber }}
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            field="erpProjectNumber"
            header="Project"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">erpProjectNumber</span>
              {{ slotProps.data.erpProjectNumber }}
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            field="erpInvoiceNumber"
            header="Invoice"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">erpInvoiceNumber</span>
              {{ slotProps.data.erpInvoiceNumber }}
            </template>
          </Column>
          <Column field="invoiceDate" header="Invoice Date" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">invoiceDate</span>
              {{ formatCalendarDate(slotProps.data.invoiceDate) }}
            </template>
          </Column>
          <Column
            v-if="showInvoiceDueDate === false"
            field="invoiceDate"
            header="Days Past"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">invoiceDaysPast</span>
              {{ calculateNumberDaysPastFromDate(slotProps.data.invoiceDate) }}
            </template>
          </Column>
          <Column
            v-if="showInvoiceDueDate === true"
            field="dueDate"
            header="Due Date"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">dueDate</span>
              <span
                v-if="
                  slotProps.data.dueDate != null && slotProps.data.dueDate != ''
                "
                >{{ formatCalendarDate(slotProps.data.dueDate) }}</span
              >
            </template>
          </Column>
          <Column field="invoiceAmount" header="Amount" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">invoiceAmount</span>
              {{ formatToCurrency(slotProps.data.invoiceAmount) }}
            </template>
          </Column>
          <Column
            v-if="isInvoiceFileViewerEnabled"
            bodyStyle="text-align:center"
          >
            <template #body="slotProps">
              <Button
                v-if="slotProps.data.filePath"
                v-tooltip="'View Invoice'"
                icon="pi pi-file-pdf"
                @click="fetchInvoiceFileUrl(slotProps.data.invoiceId)"
              />
            </template>
          </Column>
          <Column
            v-if="isColumnRoleVisible"
            bodyStyle="text-align:center"
            field="payInvoice"
            header="Pay Invoices"
            :sortable="true"
            selectionMode="multiple"
          >
          </Column>
          <template #empty>
            <p style="text-align: center">No records found.</p>
          </template>
        </DataTable>
      </div>
    </div>
    <div v-if="isColumnRoleVisible" class="text-center">
      <Button class="button-paynow" label="Pay Invoices" @click="onSubmit()" />
    </div>
    <div v-if="isWebViewerModalVisible === true">
      <WebViewerModal
        :document="invoiceFileUrl"
        :showModalProp="isWebViewerModalVisible"
        @resetIsVisible="onWebViewerModalDataEvent()"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {
  formatCalendarDate,
  calculateNumberDaysPastFromDate,
  formatToCurrency,
  formatInvoiceNumber,
} from "@/utility/helpers";
import { CLIENT_USER_ROLE_NAMES } from "@/utility/role";
import invoiceService from "@/services/invoice";
import router from "@/router";
import WebViewerModal from "../WebViewerModal.vue";
import store from "@/store";

export default {
  components: {
    WebViewerModal,
  },
  setup() {
    const invoices = ref([]);
    const invoicesToPay = ref([]);
    const error = ref();
    const loading = ref(false);
    const invoiceFileUrl = ref();
    const isWebViewerModalVisible = ref(false);
    const isInvoiceFileViewerEnabled = computed(
      () => store.getters.invoiceFileViewerEnabled
    );
    const isColumnRoleVisible = computed(() =>
      CLIENT_USER_ROLE_NAMES.includes(store.getters.userRole)
    );
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const showInvoiceDueDate = computed(() => store.getters.showInvoiceDueDate);

    const fetchUnpaidInvoices = async () => {
      loading.value = true;
      invoices.value = [];
      var results = await invoiceService.getUnpaidClientInvoices();
      results.forEach((result) => {
        result.invoiceNumber =
          result.erpProjectNumber +
          "-" +
          formatInvoiceNumber(result.erpInvoiceNumber);
        invoices.value.push(result);
      });
      loading.value = false;
    };

    onMounted(fetchUnpaidInvoices);

    async function fetchInvoiceFileUrl(invoiceId) {
      invoiceFileUrl.value = await invoiceService.getInvoiceFileUrl(invoiceId);
      isWebViewerModalVisible.value = true;
    }

    function onWebViewerModalDataEvent() {
      isWebViewerModalVisible.value = false;
    }

    async function onSubmit() {
      if (Array.isArray(invoicesToPay.value) && invoicesToPay.value.length) {
        invoiceService.storeInvoicesToPay(invoicesToPay.value);
        await router.push({ path: "/payments" });
      } else {
        error.value = new Error("No invoices selected for payment");
      }
    }

    return {
      calculateNumberDaysPastFromDate,
      formatToCurrency,
      formatCalendarDate,
      fetchUnpaidInvoices,
      fetchInvoiceFileUrl,
      onWebViewerModalDataEvent,
      isWebViewerModalVisible,
      isInvoiceFileViewerEnabled,
      onSubmit,
      invoices,
      invoicesToPay,
      loading,
      invoiceFileUrl,
      isColumnRoleVisible,
      isCombinedInvoiceFormatEnabled,
      showInvoiceDueDate,
    };
  },
};
</script>
