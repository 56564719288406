import store from "../store";
import subdomainClient from "../api/paymentProviderSettings.api";

export default {
    verifySubdomain: async function() {
        if (!store.getters.paymentProviderSettingsId) {
            let response;
            try {
                response = await subdomainClient.getSubdomainSettings();
            } catch (error) {
                Promise.reject("Unable to retrieve subdomain settings - " + error);
            }
            if (response.status == 200 && response.data.paymentProviderSettingsId) {
                store.commit(
                    "setPaymentProviderSettingsId",
                    response.data.paymentProviderSettingsId
                );
                store.dispatch("subDomainSettingsDetail", {
                    name: response.data.name,
                    imagePath: response.data.imagePath,
                    subDomain: response.data.subDomain,
                });
            }
            return response.data;
        }

        if ((await isSubDomainSettingsLocal()) === true) {
            const subDomainSettings = {
                subDomain: store.getters.subDomain,
                name: store.getters.name,
                imagePath: store.getters.imagePath,
            };
            return subDomainSettings;
        }
    },
};

async function isSubDomainSettingsLocal() {
    const hostNameParts = window.location.host.split(".");
    if (hostNameParts[0] === store.getters.subDomain) {
        return true;
    }
    return false;
}