<template>
  <div class="pt-3">
    <div class="pb-3 text-center">
      <AccountInfoImageModal />
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="routingNumber">Routing Number: *</label>
        <div>
          <InputText
            id="routingNumber"
            v-model="form.routingNumber"
            type="text"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.routingNumber.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.routingNumber.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="field col-12 md:col-6">
        <label for="accountNumber">Account Number: *</label>
        <div>
          <InputText
            id="accountNumber"
            v-model="form.accountNumber"
            type="text"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.accountNumber.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.accountNumber.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="field col-12 md:col-6">
        <label for="accountTypes">Account Type: *</label>
        <div>
          <Dropdown
            v-model="form.selectedAccountType"
            :options="accountTypes"
            optionLabel="name"
            optionValue="code"
            placeholder="Select Account Type"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.selectedAccountType.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.selectedAccountType.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="field col-12 md:col-6">
        <label for="companyName">Corporation Name: *</label>
        <div>
          <InputText
            id="companyName"
            v-model="form.companyName"
            type="text"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.companyName.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.companyName.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="field col-12 md:col-6">
        <label for="emailAddress">Email Address: *</label>
        <div>
          <InputText
            id="emailAddress"
            v-model="form.emailAddress"
            type="text"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v.emailAddress.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v.emailAddress.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
    </div>
    <div v-if="isGuestCheckoutLink === false">
      <span>
        <Checkbox v-model="storePaymentMethod" :binary="true" />
        Store this account for future payment.
      </span>
    </div>
    <div class="pt-3">
      <PaymentTermsAndConditions
        :resetCheckBoxAgreementProp="isAgreementCheckboxClicked"
        @isAgreementCheckboxClicked="onAgreementCheckboxDataEvent"
      />
    </div>

    <div
      class="pt-3 text-center"
      v-if="isGuestCheckoutLink === true && paymentMethod === 'ACH'"
    >
      <vue-recaptcha
        ref="recaptcha"
        :sitekey="recaptchaSiteKey"
        :load-recaptcha-script="true"
        @verify="onVerifyChallenge"
        @error="onErrorChallenge"
        size="invisible"
      >
      </vue-recaptcha>
      <Button
        class="justify-content-center button-submit-payment"
        label="Submit Payment"
        @click="onSubmitChallenge"
        :loading="isLoading"
      />
    </div>
    <div class="pt-3 text-center" v-if="isGuestCheckoutLink === false">
      <Button
        class="justify-content-center button-submit-payment"
        label="Submit Payment"
        @click="onSubmitPayment"
        :loading="isLoading"
      />
    </div>
    <div
      v-if="scheduledPaymentEnabled === true && isGuestCheckoutLink === false"
      class="pt-3 text-center"
    >
      <p><strong>OR</strong></p>
      <Button
        class="justify-content-center button-submit-scheuled-payment"
        label="Schedule Payment"
        @click="showScheduledPaymentModal()"
        :loading="isLoading"
      />
    </div>
    <div>
      <SchedulePayment
        :isModalVisibleProp="isScheduledPaymentModalVisible"
        :paymentAccountProp="createPaymentDto"
        :paymentMethodProp="paymentMethod"
        :termsAndConditionsAcceptedProp="isAgreementCheckboxClicked"
        @resetScheduledPaymentModal="onScheduledPaymentModalDataEvent()"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, toRef, computed, reactive } from "vue";
import store from "@/store";
import router from "@/router";
import paymentService from "@/services/payment";
import clientService from "@/services/client";
import PaymentTermsAndConditions from "./PaymentTermsAndConditions.vue";
import AccountInfoImageModal from "./PaymentMethodAchAccountInfoAsset.vue";
import SchedulePayment from "./SchedulePayment.vue";
import { VueRecaptcha } from "vue-recaptcha";
import reCaptchaClient from "@/api/reCaptcha.api";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
  email,
} from "@vuelidate/validators";

export default {
  components: {
    PaymentTermsAndConditions,
    AccountInfoImageModal,
    SchedulePayment,
    "vue-recaptcha": VueRecaptcha,
  },
  props: {
    paymentMethodProp: {
      type: String,
    },
    signedInvoiceIdProp: {
      type: String,
      default: null,
    },
  },
  emits: ["isRadioDisabled"],
  setup(props, { emit }) {
    const paymentMethod = toRef(props, "paymentMethodProp");
    const signedInvoiceId = toRef(props, "signedInvoiceIdProp");
    const scheduledPaymentEnabled = computed(
      () => store.getters.scheduledPaymentEnabled
    );
    const isGuestCheckoutLink = ref(signedInvoiceId.value ? true : false);
    const accountTypes = ref([]);
    const isLoading = ref(false);
    const storePaymentMethod = ref(false);
    const isScheduledPaymentModalVisible = ref(false);
    const isAgreementCheckboxClicked = ref(false);
    const initialFormState = {
      accountNumber: "",
      routingNumber: "",
      selectedAccountType: "",
      companyName: "",
      emailAddress: "",
    };
    const form = reactive({ ...initialFormState });
    let createPaymentDto = computed(() => {
      return {
        paymentType: paymentMethod.value,
        paymentUserEmail: form.emailAddress,
        termsAndConditionsAccepted: isAgreementCheckboxClicked.value,
        bankAccount: {
          accountNumber: form.accountNumber,
          routingNumber: form.routingNumber,
          type: form.selectedAccountType,
          accountHolderName: form.companyName,
        },
      };
    });

    const recaptcha = ref(null);
    const recaptchaSiteKey = computed(() => {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    });

    async function onSubmitPayment() {
      if (!(await v.value.$validate())) {
        return;
      } else {
        isLoading.value = true;
        emit("isRadioDisabled", true);

        if (isGuestCheckoutLink.value === true) {
          try {
            await paymentService.createInvoicePaymentFromSignedInvoiceId(
              signedInvoiceId.value,
              createPaymentDto.value
            );
          } catch (error) {
            await onErrorChallenge();
            return resetFormState();
          }
        } else {
          try {
            await paymentService.createClientInvoicePayment(
              createPaymentDto.value
            );
          } catch (error) {
            return resetFormState();
          }
        }

        if (storePaymentMethod.value === true) {
          const bankAccountDto = {
            bankAccount: {
              accountNumber: form.accountNumber,
              routingNumber: form.routingNumber,
              type: form.selectedAccountType,
              accountHolderName: form.companyName,
            },
          };
          await clientService.createClientVaultedAccount(bankAccountDto);
        }
        isLoading.value = false;
        emit("isRadioDisabled", false);

        if (isGuestCheckoutLink.value === true) {
          await router.push({ path: "/guest-checkout-payment-confirmation" });
        } else {
          await router.push({ path: "/payment-confirmation" });
        }
      }
    }

    async function showScheduledPaymentModal() {
      if (!(await v.value.$validate())) return;
      isScheduledPaymentModalVisible.value = true;
    }

    async function onSubmitChallenge() {
      if (!(await v.value.$validate())) {
        return;
      }
      await recaptcha.value.execute();
    }
    async function onVerifyChallenge(challengeResult) {
      isLoading.value = true;
      const response = await reCaptchaClient.postSiteVerify({
        response: challengeResult,
      });
      if (response && response.data.success === false) {
        await recaptcha.value.reset();
      } else {
        await onSubmitPayment();
      }
      isLoading.value = false;
    }
    async function onErrorChallenge() {
      await recaptcha.value.reset();
    }

    function onScheduledPaymentModalDataEvent() {
      setTimeout((isScheduledPaymentModalVisible.value = false), 1500);
    }

    async function fetchBankAccountTypes() {
      accountTypes.value = await paymentService.fetchBankAccountTypes();
    }

    function onAgreementCheckboxDataEvent(event) {
      isAgreementCheckboxClicked.value = event;
    }

    function resetFormState() {
      isAgreementCheckboxClicked.value = false;
      emit("isRadioDisabled", false);
      v.value.$reset();
      Object.assign(form, initialFormState);
      isLoading.value = false;
    }

    onMounted(fetchBankAccountTypes);

    const rules = computed(() => ({
      accountNumber: {
        numeric: helpers.withMessage("Account Number must be numeric", numeric),
        minLength: helpers.withMessage(
          "Account Number must be at least 4 digits",
          minLength(4)
        ),
        maxLength: helpers.withMessage(
          "Account Number cannot be more than 17 digits",
          maxLength(17)
        ),
        required: helpers.withMessage("Account Number is required", required),
      },
      routingNumber: {
        numeric: helpers.withMessage("Routing Number must be numeric", numeric),
        minLength: helpers.withMessage(
          "Routing Number must be 9 digits",
          minLength(9)
        ),
        maxLength: helpers.withMessage(
          "Routing Number must be 9 digits",
          maxLength(9)
        ),
        required: helpers.withMessage("Routing Number is required", required),
      },
      selectedAccountType: { required },
      companyName: {
        minLength: helpers.withMessage(
          "Corporation Name must be at least 2 characters",
          minLength(2)
        ),
        maxLength: helpers.withMessage(
          "Corporation Name cannot be more than 30 characters",
          maxLength(30)
        ),
        required: helpers.withMessage("Corporation Name is required", required),
      },
      emailAddress: {
        email: helpers.withMessage("Email Address is not valid", email),
        required: helpers.withMessage("Email Address is required", required),
      },
    }));
    const v = useVuelidate(rules, form, { $lazy: true });

    return {
      scheduledPaymentEnabled,
      isGuestCheckoutLink,
      accountTypes,
      isLoading,
      storePaymentMethod,
      isScheduledPaymentModalVisible,
      paymentMethod,
      createPaymentDto,
      form,
      isAgreementCheckboxClicked,
      recaptcha,
      recaptchaSiteKey,
      onVerifyChallenge,
      onSubmitChallenge,
      onErrorChallenge,
      fetchBankAccountTypes,
      onSubmitPayment,
      showScheduledPaymentModal,
      onScheduledPaymentModalDataEvent,
      onAgreementCheckboxDataEvent,
      v,
    };
  },
};
</script>
