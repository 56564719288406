import erpIntegrationSettingsClient from "@/api/Admin/erpIntegrationSettings.api";

export default {
    createErpIntegrationSettings: async function (tenantId, createErpIntegrationSettingsDto) {
        let response;
        try {
            response = await erpIntegrationSettingsClient.createErpIntegrationSettings(
                tenantId,
                createErpIntegrationSettingsDto
            );
        } catch (error) {
            const ex = new Error("Unable to create erp integration settings");
            throw ex.message;
        }
        return response.status;  
    },

    getErpIntegrationSettingsByTenantId: async function (tenantId) {
        let response;
        try {
            response = await erpIntegrationSettingsClient.getErpIntegrationSettingsByTenantId(tenantId);
        } catch (error) {
            const ex = new Error("Unable to retrieve ErpIntegrationSettings");
            throw ex.message;
        }
        return response.data;
    },

    getErpIntegrationSettingsById: async function (tenantId, erpIntegrationSettingsId) {
        let response;
        try {
            response = await erpIntegrationSettingsClient.getErpIntegrationSettingsById(tenantId, erpIntegrationSettingsId);
        } catch (error) {
            const ex = new Error("Unable to retrieve ErpIntegrationSettings");
            throw ex.message;
        }
        return response.data;
    },

    updateErpIntegrationSettings: async function (tenantId, erpIntegrationSettingsId, updateErpIntegrationSettingsDto) {
        let response;
        try {
            response = await erpIntegrationSettingsClient.updateErpIntegrationSettings(
                tenantId,
                erpIntegrationSettingsId,
                updateErpIntegrationSettingsDto
            );
        } catch (error) {
            const ex = new Error("Unable to update payment provider settings");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to update payment provider settings");
            throw ex.message;
        }
        return response.status;  
    },
}