export default {
    state: {
        invoices: [],
        invoicesToPayTotalAmount: null,
        invoicesToPayTotalAmountAdjusted: null,
        invoicesToPayNonCashAdjustmentTotal: null,
        showInvoiceDueDate: false,
    },

    getters: {
        invoicesToPay: (state) => state.invoices,
        invoicesToPayTotalAmount: (state) => state.invoicesToPayTotalAmount,
        invoicesToPayTotalAmountAdjusted: (state) =>
            state.invoicesToPayTotalAmountAdjusted,
        invoicesToPayNonCashAdjustmentTotal: (state) =>
            state.invoicesToPayNonCashAdjustmentTotal,
        showInvoiceDueDate: (state) =>
            state.showInvoiceDueDate,
    },

    mutations: {
        setInvoices: (state, invoices) => {
            state.invoices = invoices;
        },
        setInvoicesToPayTotalAmount: (state, invoicesToPayTotalAmount) => {
            state.invoicesToPayTotalAmount = invoicesToPayTotalAmount;
        },
        setInvoicesToPayTotalAmountAdjusted: (
            state,
            invoicesToPayTotalAmountAdjusted
        ) => {
            state.invoicesToPayTotalAmountAdjusted = invoicesToPayTotalAmountAdjusted;
        },
        setInvoicesToPayNonCashAdjustmentTotal: (
            state,
            invoicesToPayNonCashAdjustmentTotal
        ) => {
            state.invoicesToPayNonCashAdjustmentTotal =
                invoicesToPayNonCashAdjustmentTotal;
        },
        setShowInvoiceDueDate: (state, showInvoiceDueDate) => {
            state.showInvoiceDueDate = showInvoiceDueDate;
        },
    },

    actions: {
        flushInvoicesStore: function (context) {
            context.commit("setInvoices", []);
            context.commit("setInvoicesToPayTotalAmount", null);
            context.commit("setInvoicesToPayTotalAmountAdjusted", null);
            context.commit("setInvoicesToPayNonCashAdjustmentTotal", null);
            context.commit("setShowInvoiceDueDate", false);
        },
    },
};