<template>
  <div>
    <div>
      <div class="pt-5">
        <div class="card ev-fluid-card">
          <GuestCheckoutHeader />
        </div>
      </div>
      <div class="pt-5" v-if="signedInvoiceId">
        <PayInvoicesSummary
          @invoiceStatusEvent="isClientPaymentMethodVisibleEvent"
          :signedInvoiceIdProp="signedInvoiceId"
        />
      </div>
    </div>
    <div
      v-if="isClientPaymentMethodVisible === true && signedInvoiceId"
      class="pt-5"
    >
      <ClientPaymentMethod :signedInvoiceIdProp="signedInvoiceId" />
    </div>
    <div class="pt-5">
      <div class="card ev-fluid-card">
        <AppFooter />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import store from "../store";
import GuestCheckoutHeader from "../components/Client/GuestCheckoutHeader.vue";
import PayInvoicesSummary from "../components/Client/PayInvoicesSummary.vue";
import ClientPaymentMethod from "../components/Client/PaymentMethod.vue";
import AppFooter from "../AppFooter.vue";

export default {
  components: {
    GuestCheckoutHeader,
    PayInvoicesSummary,
    ClientPaymentMethod,
    AppFooter,
  },
  setup() {
    const isClientPaymentMethodVisible = ref(false);
    const signedInvoiceId = computed(() => store.getters.signedInvoiceId);

    async function isClientPaymentMethodVisibleEvent(value) {
      isClientPaymentMethodVisible.value = value;
    }

    return {
      isClientPaymentMethodVisible,
      signedInvoiceId,
      isClientPaymentMethodVisibleEvent,
    };
  },
};
</script>
