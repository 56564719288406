import { createStore } from "vuex";
import vuexPersist from "vuex-persist";
import cookies from "js-cookie";
import localforage from "localforage";

import authenticate from "./auth";
import clientDetail from "./client";
import paymentProviderDetail from "./paymentProviderSetting";
import invoiceDetail from "./invoice";
import paymentDetail from "./payment";
import userDetail from "./user";

const vuexAuthCookie = new vuexPersist({
    key: "evpay-user-auth",
    restoreState: (key) => cookies.get(key) ? JSON.parse(cookies.get(key)) : {},
    saveState: (key, state) =>
        cookies.set(key, JSON.stringify(state), {
            secure: true,
            sameSite: "strict",
        }),
    modules: ["auth"],
});

const vuexClientStoredAccountsCookie = new vuexPersist({
    key: "evpay-client-accounts",
    restoreState: (key) => cookies.get(key) ? JSON.parse(cookies.get(key)) : {},
    saveState: (key, state) =>
        cookies.set(key, JSON.stringify(state), {
            secure: true,
            sameSite: "strict",
        }),
    modules: ["storedPaymentMethod"],
});

const vuexUserCookie = new vuexPersist({
    key: "user_create_date",
    restoreState: (key) => cookies.get(key) ? JSON.parse(cookies.get(key)) : {},
    saveState: (key, state) =>
        cookies.set(key, JSON.stringify(state), {
            secure: true,
            sameSite: "strict",
        }),
    modules: ["user"],
});

const vuexPaymentProviderLocalData = new vuexPersist({
    key: "evpay-pps-data",
    asyncStorage: true,
    storage: localforage,
    modules: ["pps"],
});

const vuexInvoicesLocalData = new vuexPersist({
    key: "evpay-invoice-data",
    asyncStorage: true,
    storage: localforage,
    modules: ["invoices"],
    reducer: (state) => JSON.parse(JSON.stringify({ invoices: state.invoices })),
});

const vuexPaymentsLocalData = new vuexPersist({
    key: "evpay-payment-data",
    asyncStorage: true,
    storage: localforage,
    modules: ["payments"],
    reducer: (state) => JSON.parse(JSON.stringify({ payments: state.payments })),
});

const store = createStore({
    modules: {
        auth: authenticate,
        storedPaymentMethod: clientDetail,
        pps: paymentProviderDetail,
        invoices: invoiceDetail,
        payments: paymentDetail,
        user: userDetail,
    },
    plugins: [
        vuexAuthCookie.plugin,
        vuexClientStoredAccountsCookie.plugin,
        vuexPaymentProviderLocalData.plugin,
        vuexInvoicesLocalData.plugin,
        vuexPaymentsLocalData.plugin,
        vuexUserCookie.plugin,
    ],
});

export default store;