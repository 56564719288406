<template>
  <div class="card ev-table-edit-fluid-card">
    <h4>Bank Accounts</h4>
    <div class="client-datatable">
      <DataTable
        :value="storedBankAccounts"
        editMode="row"
        dataKey="id"
        stripedRows
        autoLayout
        responsiveLayout="scroll"
        v-model:editingRows="editingRows"
        @row-edit-save="onBankRowEditSave"
        :loading="isBankLoading"
      >
        <Column field="type" header="Type">
          <template #editor="{ data, field }">
            <Dropdown
              v-model="data[field]"
              :options="accountTypes"
              optionLabel="name"
              optionValue="code"
              placeholder="Select a Type"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.name }}</span>
              </template>
            </Dropdown>
          </template>
          <template #body="slotProps">
            {{ slotProps.data.type }}
          </template>
        </Column>
        <Column field="last4" header="Last 4"></Column>
        <Column field="name" header="Name">
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" />
          </template>
        </Column>
        <Column field="isDefault" header="Default">
          <template #editor="{ data, field }">
            <Dropdown
              v-model="data[field]"
              :options="defaultYesNo"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Default"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
          </template>
          <template #body="slotProps">
            {{ getIsDefaultLabel(slotProps.data.isDefault) }}
          </template>
        </Column>
        <Column
          v-if="isColumnRoleVisible"
          :rowEditor="true"
          bodyStyle="text-align:right"
          headerStyle="width: 20%"
        ></Column>
        <Column v-if="isColumnRoleVisible" bodyStyle="text-align:left" headerStyle="width: 8%">
          <template #body="slotProps">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="confirmDeletePaymentMethod(slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>
          <p style="text-align: center">No records found.</p>
        </template>
      </DataTable>

      <div class="pt-5">
        <h4>Card Accounts</h4>
        <DataTable
          :value="storedCardAccounts"
          editMode="row"
          dataKey="id"
          stripedRows
          autoLayout
          responsiveLayout="scroll"
          v-model:editingRows="editingRows"
          @row-edit-save="onCardRowEditSave"
          :loading="isCardLoading"
        >
          <Column field="cardType" header="Type"></Column>
          <Column field="last4" header="Last 4"></Column>
          <Column field="avsStreet" header="Street Address">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column field="avsZip" header="Zip Code">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" />
            </template>
          </Column>
          <Column field="expiryMonth" header="Month">
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="expiryMonth"
                optionLabel="name"
                optionValue="code"
                placeholder="Select Expiry Month"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.name }}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.expiryMonth }}
            </template>
          </Column>
          <Column field="expiryYear" header="Year">
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="expiryYear"
                optionLabel="name"
                optionValue="code"
                placeholder="Select Expiry Year"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.name }}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.expiryYear }}
            </template>
          </Column>
          <Column field="isDefault" header="Default">
            <template #editor="{ data, field }">
              <Dropdown
                v-model="data[field]"
                :options="defaultYesNo"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Default"
              >
                <template #option="slotProps">
                  <span>{{ slotProps.option.label }}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ getIsDefaultLabel(slotProps.data.isDefault) }}
            </template>
          </Column>
          <Column
            v-if="isColumnRoleVisible"
            :rowEditor="true"
            bodyStyle="text-align:right"
            headerStyle="width: 20%"
          ></Column>
          <Column v-if="isColumnRoleVisible" bodyStyle="text-align:left" headerStyle="width: 8%">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDeletePaymentMethod(slotProps.data)"
              />
            </template>
          </Column>
          <template #empty>
            <p style="text-align: center">No records found.</p>
          </template>
        </DataTable>
      </div>

      <div>
        <Dialog
          v-model:visible="deleteStoredPaymentMethodDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="deleteStoredPaymentMethod"
              >Are you sure you want to delete payment method:
              <strong v-if="deleteStoredPaymentMethod.type">{{
                deleteStoredPaymentMethod.type
              }}</strong
              ><strong v-if="deleteStoredPaymentMethod.cardType">{{
                deleteStoredPaymentMethod.cardType
              }}</strong>
              <strong> - {{ deleteStoredPaymentMethod.last4 }}</strong></span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteStoredPaymentMethodDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedPaymentMethod()"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import clientService from "@/services/client";
import store from "@/store";
import useVuelidate from "@vuelidate/core";
import {
  fetchCardExpirationYears,
  fetchCardExpirationMonths,
  fetchBankAccountTypes,
} from "@/utility/helpers";
import { CLIENT_USER_ROLE_NAMES } from "@/utility/role";

export default {
  setup() {
    const toast = useToast();
    const storedAccountsStore = computed(() => store.getters.accounts);
    const storedClientPaymentMethods = ref([]);
    const storedBankAccounts = ref([]);
    const storedCardAccounts = ref([]);
    const deleteStoredPaymentMethod = ref({});
    const deleteStoredPaymentMethodDialog = ref(false);
    const deleteStoredPaymentResponse = ref();
    const updateStoredBankAccountResponse = ref();
    const updateStoredCardAccountResponse = ref();
    const editingRows = ref([]);
    const isBankLoading = ref(false);
    const isCardLoading = ref(false);
    const accountTypes = ref(fetchBankAccountTypes());
    const expiryMonth = ref(fetchCardExpirationMonths());
    const expiryYear = ref(fetchCardExpirationYears());
    const isColumnRoleVisible = computed(() =>
      CLIENT_USER_ROLE_NAMES.includes(store.getters.userRole)
    );

    const defaultYesNo = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

    async function fetchStoredPaymentMethods() {
      storedBankAccounts.value = await clientService.fetchStoredBankAccounts(
        storedAccountsStore.value
      );

      storedCardAccounts.value = await clientService.fetchStoredCardAccounts(
        storedAccountsStore.value
      );
    }

    async function fetchStoredClientPaymentMethods() {
      isBankLoading.value = true;
      isCardLoading.value = true;

      storedClientPaymentMethods.value =
        await clientService.fetchClientStoredPaymentMethods();

      await fetchStoredPaymentMethods();

      isBankLoading.value = false;
      isCardLoading.value = false;
    }

    async function deleteSelectedPaymentMethod() {
      deleteStoredPaymentMethodDialog.value = false;

      if (
        deleteStoredPaymentMethod.value &&
        deleteStoredPaymentMethod.value.type
      ) {
        deleteStoredPaymentResponse.value =
          await clientService.deleteClientVaultedBankAccount(
            deleteStoredPaymentMethod.value
          );
      } else {
        deleteStoredPaymentResponse.value =
          await clientService.deleteClientVaultedCardAccount(
            deleteStoredPaymentMethod.value
          );
      }

      if (deleteStoredPaymentResponse.value === 204) {
        toast.success("Payment Method Deleted");
      } else {
        toast.error("Unable to Delete Payment Method");
      }
      return await fetchStoredPaymentMethods();
    }

    async function onBankRowEditSave(event) {
      let { newData, index } = event;
      storedBankAccounts.value[index] = newData;
      isBankLoading.value = true;

      updateStoredBankAccountResponse.value =
        await clientService.updateClientVaultedBankAccount(newData);

      isBankLoading.value = false;

      if (updateStoredBankAccountResponse.value === 204) {
        toast.success("Bank Account Updated");
      } else {
        toast.error("Unable to Update Bank Account");
      }

      return await fetchStoredPaymentMethods();
    }

    async function onCardRowEditSave(event) {
      let { newData, index } = event;
      storedCardAccounts.value[index] = newData;
      isCardLoading.value = true;

      updateStoredCardAccountResponse.value =
        await clientService.updateClientVaultedCardAccount(newData);

      isCardLoading.value = false;

      if (updateStoredCardAccountResponse.value === 204) {
        toast.success("Card Account Updated");
      } else {
        toast.error("Unable to Update Card Account");
      }

      return await fetchStoredPaymentMethods();
    }

    function confirmDeletePaymentMethod(deletePaymentMethod) {
      deleteStoredPaymentMethod.value = deletePaymentMethod;
      deleteStoredPaymentMethodDialog.value = true;
    }

    function getIsDefaultLabel(status) {
      switch (status) {
        case true:
          return "Yes";
        case false:
          return "No";
        default:
          return "NA";
      }
    }

    onMounted(fetchStoredClientPaymentMethods);

    return {
      deleteStoredPaymentMethod,
      storedAccountsStore,
      storedBankAccounts,
      storedCardAccounts,
      deleteStoredPaymentMethodDialog,
      deleteStoredPaymentResponse,
      updateStoredBankAccountResponse,
      updateStoredCardAccountResponse,
      accountTypes,
      expiryMonth,
      expiryYear,
      defaultYesNo,
      editingRows,
      getIsDefaultLabel,
      onBankRowEditSave,
      onCardRowEditSave,
      fetchStoredPaymentMethods,
      confirmDeletePaymentMethod,
      deleteSelectedPaymentMethod,
      isBankLoading,
      isCardLoading,
      isColumnRoleVisible,
      v$: useVuelidate({ $lazy: true }),
    };
  },
};
</script>
