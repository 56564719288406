"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async getClientInvoices(
        tenantId,
        clientId,
        paymentProviderSettingsId = "",
        invoiceStatus = ""
    ) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices?paymentProviderSettingsId=${paymentProviderSettingsId}&invoiceStatus=${invoiceStatus}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getInvoiceById(tenantId, clientId, invoiceId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getInvoiceBySignedInvoiceId(signedInvoiceId) {
        let response;
        try {
            response = await httpClient.get(
                `/invoices/${signedInvoiceId}`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        }
        catch (error) {
            response = error.response;
        }
        return response;
    },
    async getSurchargeByBin(tenantId, clientId, invoiceId, bin) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/surcharge?bin=${bin}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getSurchargeByStoredCardId(tenantId, clientId, invoiceId, customerId, cardId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/surcharge?customerId=${customerId}&cardId=${cardId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getSurchargeBySignedInvoiceId(signedInvoiceId, bin) {
        let response;
        try {
            response = await httpClient.get(
                `/invoices/${signedInvoiceId}/surcharge?bin=${bin}`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getNonCashAdjustment(tenantId, clientId, invoiceId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/non-cash-adjustment`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getNonCashAdjustmentBySignedInvoiceId(signedInvoiceId) {
        let response;
        try {
            response = await httpClient.get(
                `/invoices/${signedInvoiceId}/non-cash-adjustment`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getInvoiceFileUrl(tenantId, clientId, invoiceId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;
            response = httpClient.get(
                `/tenants/${tenantId}/clients/${clientId}/invoices/${invoiceId}/file`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getInvoiceFileUrlBySignedInvoiceId(signedInvoiceId) {
        let response;
        try {
            response = await httpClient.get(
                `/invoices/${signedInvoiceId}/file`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};
