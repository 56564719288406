import store from "../store";
import scheduledPaymentsClient from "../api/scheduledPayments.api";
import clientService from "../services/client";
import invoiceService from "../services/invoice";
import { formatDate, formatCalendarDate, formatToCurrency } from "../utility/helpers";

export default {
    createStoredAccountAndRetrieveToken: async function (accountDto) {
        let createStoredAccountResponse;
        let newStoredAccount;
        try {
            // eslint-disable-next-line no-unused-vars
            const { paymentUserEmail, paymentType, termsAndConditionsAccepted, ...createStoredAccountDto } =
                accountDto;

            createStoredAccountResponse =
                await clientService.createClientVaultedAccount(createStoredAccountDto);
        } catch (error) {
            const ex = new Error("Unable to create stored account");
            throw ex.message;
        }

        if (
            createStoredAccountResponse &&
            createStoredAccountResponse.status !== 200
        ) {
            const ex = new Error("Unable to create stored account");
            throw ex.message;
        }

        newStoredAccount = retrieveCreatedStoredAccountFromStoredAccounts(
            accountDto,
            createStoredAccountResponse.data
        );

        return newStoredAccount;
    },
    createScheduledPayment: async function (createScheduledPaymentDto) {
        let response;
        try {
            const invoicesToPay = await store.getters.invoicesToPay;
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            for (let i = 0; i < invoicesToPay.length; i++) {
                createScheduledPaymentDto.paymentAmount =
                    invoicesToPay[i].invoiceAmount;
                response = await scheduledPaymentsClient.postScheduledPayment(
                    tenantId,
                    clientId,
                    invoicesToPay[i].invoiceId,
                    createScheduledPaymentDto
                );
            }
        } catch (error) {
            const ex = new Error("Unable to create scheduled payment");
            throw ex.message;
        }

        if (response && response.status === 201) {
            return response.data;
        } else {
            const ex = new Error("Unable to create scheduled payment");
            throw ex.message;
        }
    },
    getScheduledPaymentsByClient: async function (status = "", date = "") {
        let scheduledPayments;
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            const paymentProviderSettingsId = store.getters.paymentProviderSettingsId;
            response = await scheduledPaymentsClient.getScheduledPaymentsByClient(
                tenantId,
                clientId,
                paymentProviderSettingsId,
                status,
                date
            );
        } catch (error) {
            const ex = new Error("Unable to retrieve scheduled payments");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve scheduled payments");
            throw ex.message;
        } else {
            scheduledPayments = await getInvoiceDataAndFormatScheduledPaymentObjects(
                response.data
            );
        }
        return scheduledPayments;
    },
    updateScheduledPayment: async function (
        invoiceId,
        scheduledPaymentId,
        keyValuesToUpdateData
    ) {
        let response;
        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            let updateScheduledPaymentDto = buildUpdateScheduledPaymentJsonDocument(
                keyValuesToUpdateData
            );

            response = await scheduledPaymentsClient.updateScheduledPayment(
                tenantId,
                clientId,
                invoiceId,
                scheduledPaymentId,
                updateScheduledPaymentDto
            );
        } catch (error) {
            const ex = new Error("Unable to update scheduled payment");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to update scheduled payment");
            throw ex.message;
        } else {
            return response.data;
        }
    },
};

function retrieveCreatedStoredAccountFromStoredAccounts(
    accountDto,
    clientStoredAccounts
) {
    let storedAccount;
    if (accountDto.bankAccount) {
        const accountType = accountDto.bankAccount.type;
        const accountNumber = accountDto.bankAccount.accountNumber;
        const accountNumberLast4 = accountNumber.slice(-4);
        const clientStoredBankAccounts =
            clientStoredAccounts.mxMerchantVaultedBankAccounts;

        for (let i = 0; i < clientStoredBankAccounts.length; i++) {
            if (
                clientStoredBankAccounts[i].type === accountType &&
                clientStoredBankAccounts[i].last4 === accountNumberLast4
            ) {
                storedAccount = clientStoredBankAccounts[i];
            }
        }
    } else if (accountDto.cardAccount) {
        const accountNumber = accountDto.cardAccount.number;
        const accountNumberLast4 = accountNumber.slice(-4);
        const expiryMonth = accountDto.cardAccount.expiryMonth;
        const expiryYear = accountDto.cardAccount.expiryYear;
        const clientStoredCardAccounts =
            clientStoredAccounts.mxMerchantVaultedCardAccounts;

        for (let i = 0; i < clientStoredCardAccounts.length; i++) {
            if (
                clientStoredCardAccounts[i].last4 === accountNumberLast4 &&
                clientStoredCardAccounts[i].expiryMonth === expiryMonth &&
                clientStoredCardAccounts[i].expiryYear === expiryYear
            ) {
                storedAccount = clientStoredCardAccounts[i];
            }
        }
    } else {
        const ex = new Error("Unknown or unsupported payment method");
        throw ex.message;
    }

    return storedAccount;
}

function buildUpdateScheduledPaymentJsonDocument(keyValueData) {
    let jsonPatchDocument = [];
    for (const [key, value] of Object.entries(keyValueData)) {
        let jsonPatchObject = {
            op: "replace",
            path: "/" + key,
            value: value,
        };
        jsonPatchDocument.push(jsonPatchObject);
    }
    return jsonPatchDocument;
}

async function getInvoiceDataAndFormatScheduledPaymentObjects(
    scheduledPayments
) {
    for (let i = 0; i < scheduledPayments.length; i++) {
        let invoice = await invoiceService.getInvoiceById(
            scheduledPayments[i].invoiceId
        );
        scheduledPayments[i].erpInvoiceNumber = invoice.erpInvoiceNumber;
        scheduledPayments[i].erpProjectNumber = invoice.erpProjectNumber;
        scheduledPayments[i].paymentAmountCurrency = formatToCurrency(
            scheduledPayments[i].paymentAmount
        );
        scheduledPayments[i].paymentDate = new Date(scheduledPayments[i].paymentDate.split('-'));
        scheduledPayments[i].paymentDateCalendar = formatCalendarDate(
            scheduledPayments[i].paymentDate
        );
        scheduledPayments[i].invoiceDate = new Date(invoice.invoiceDate.split('-'));
        scheduledPayments[i].invoiceDateCalendar = formatCalendarDate(
            scheduledPayments[i].invoiceDate
        );
        if (invoice.dueDate != null && invoice.dueDate != "") {
            scheduledPayments[i].dueDate = new Date(invoice.dueDate.split('-'));
            scheduledPayments[i].dueDateCalendar = formatCalendarDate(
                scheduledPayments[i].dueDate
            );
            scheduledPayments[i].dueDateSort = formatDate(
                scheduledPayments[i].dueDate
            );
        }
        scheduledPayments[i].filePath = invoice.filePath;
    }
    return scheduledPayments;
}