export default {
    state: {
        subDomain: null,
        name: null,
        address: {
            addressLine1: null,
            addressLine2: null,
            zipCode: null,
            city: null,
            state: null,
            phoneNumber: null,
            contactEmailAddress: null,
        },
        imagePath: null,
        enableACH: false,
        enableCC: false,
        cardProcessingPriceProgramType: null,
        paymentConfirmationMessage: null,
        paymentPageMessage: null,
        scheduledPaymentEnabled: false,
        invoiceFileViewerEnabled: false,
        combinedInvoiceFormatEnabled: false,
        nonCashAdjustmentPercent: null,
    },

    getters: {
        imagePath: (state) => state.imagePath,
        subDomain: (state) => state.subDomain,
        name: (state) => state.name,
        addressLine1: (state) => state.address.addressLine1,
        addressLine2: (state) => state.address.addressLine2,
        zipCode: (state) => state.address.zipCode,
        city: (state) => state.address.city,
        state: (stateValue) => stateValue.address.state,
        phoneNumber: (state) => state.address.phoneNumber,
        contactEmailAddress: (state) => state.address.contactEmailAddress,
        enableACH: (state) => state.enableACH,
        enableCC: (state) => state.enableCC,
        cardProcessingPriceProgramType: (state) => state.cardProcessingPriceProgramType,
        paymentConfirmationMessage: (state) => state.paymentConfirmationMessage,
        paymentPageMessage: (state) => state.paymentPageMessage,
        scheduledPaymentEnabled: (state) => state.scheduledPaymentEnabled,
        invoiceFileViewerEnabled: (state) => state.invoiceFileViewerEnabled,
        combinedInvoiceFormatEnabled: (state) => state.combinedInvoiceFormatEnabled,
        nonCashAdjustmentPercent: (state) => state.nonCashAdjustmentPercent,
    },

    mutations: {
        setImagePath: (state, imagePath) => {
            state.imagePath = imagePath;
        },
        setSubDomain: (state, subDomain) => {
            state.subDomain = subDomain;
        },
        setName: (state, name) => {
            state.name = name;
        },
        setAddressLine1: (state, addressLine1) => {
            state.address.addressLine1 = addressLine1;
        },
        setAddressLine2: (state, addressLine2) => {
            state.address.addressLine2 = addressLine2;
        },
        setcity: (state, city) => {
            state.address.city = city;
        },
        setzipCode: (state, zipCode) => {
            state.address.zipCode = zipCode;
        },
        setstate: (stateValue, state) => {
            stateValue.address.state = state;
        },
        setphoneNumber: (state, phoneNumber) => {
            state.address.phoneNumber = phoneNumber;
        },
        setContactEmailAddress: (state, contactEmailAddress) => {
            state.address.contactEmailAddress = contactEmailAddress;
        },
        setEnableACH: (state, enableACH) => {
            state.enableACH = enableACH;
        },
        setEnableCC: (state, enableCC) => {
            state.enableCC = enableCC;
        },
        setCardProcessingPriceProgramType: (state, cardProcessingPriceProgramType) => {
            state.cardProcessingPriceProgramType = cardProcessingPriceProgramType;
        },
        setPaymentConfirmationMessage: (state, paymentConfirmationMessage) => {
            state.paymentConfirmationMessage = paymentConfirmationMessage;
        },
        setPaymentPageMessage: (state, paymentPageMessage) => {
            state.paymentPageMessage = paymentPageMessage;
        },
        setScheduledPaymentEnabled: (state, scheduledPaymentEnabled) => {
            state.scheduledPaymentEnabled = scheduledPaymentEnabled;
        },
        setInvoiceFileViewerEnabled: (state, invoiceFileViewerEnabled) => {
            state.invoiceFileViewerEnabled = invoiceFileViewerEnabled;
        },
        setCombinedInvoiceFormatEnabled: (state, combinedInvoiceFormatEnabled) => {
            state.combinedInvoiceFormatEnabled = combinedInvoiceFormatEnabled;
        },
        setNonCashAdjustmentPercent: (state, nonCashAdjustmentPercent) => {
            state.nonCashAdjustmentPercent = nonCashAdjustmentPercent;
        },
    },

    actions: {
        paymentProviderSettingsDetail: function (context, payload) {
            context.commit("setImagePath", payload.imagePath);
            context.commit("setSubDomain", payload.subDomain);
            context.commit("setName", payload.name);
            context.commit("setAddressLine1", payload.addressLine1);
            context.commit("setAddressLine2", payload.addressLine2);
            context.commit("setcity", payload.city);
            context.commit("setzipCode", payload.zipCode);
            context.commit("setstate", payload.state);
            context.commit("setphoneNumber", payload.phoneNumber);
            context.commit("setContactEmailAddress", payload.contactEmailAddress);
            context.commit("setCardProcessingPriceProgramType", payload.cardProcessingPriceProgramType);
            context.commit(
                "setPaymentConfirmationMessage",
                payload.paymentConfirmationMessage
            );
            context.commit("setPaymentPageMessage", payload.paymentPageMessage);
            context.commit(
                "setScheduledPaymentEnabled",
                payload.scheduledPaymentEnabled
            );
            context.commit("setInvoiceFileViewerEnabled", payload.invoiceFileViewerEnabled);
            context.commit("setCombinedInvoiceFormatEnabled", payload.combinedInvoiceFormatEnabled);
            context.commit("setNonCashAdjustmentPercent", payload.nonCashAdjustmentPercent);
        },
        subDomainSettingsDetail: function (context, payload) {
            context.commit("setImagePath", payload.imagePath);
            context.commit("setSubDomain", payload.subDomain);
            context.commit("setName", payload.name);
        },
        flushPaymentProviderSettingsDetail: function (context) {
            context.commit("setImagePath", null);
            context.commit("setSubDomain", null);
            context.commit("setName", null);
            context.commit("setAddressLine1", null);
            context.commit("setAddressLine2", null);
            context.commit("setcity", null);
            context.commit("setzipCode", null);
            context.commit("setstate", null);
            context.commit("setphoneNumber", null);
            context.commit("setEnableACH", false);
            context.commit("setEnableCC", false);
            context.commit("setCardProcessingPriceProgramType", null);
            context.commit("setPaymentConfirmationMessage", null);
            context.commit("setPaymentPageMessage", null);
            context.commit("setContactEmailAddress", null);
            context.commit("setScheduledPaymentEnabled", false);
            context.commit("setInvoiceFileViewerEnabled", false);
            context.commit("setCombinedInvoiceFormatEnabled", false);
            context.commit("setNonCashAdjustmentPercent", null);
        },
    },
};