<template>
  <div>
    <Dialog
      header="Customer Notice of Non-Cash Adjustment"
      v-model:visible="isModalVisible"
      :style="{ width: '50vw' }"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
      :draggable="false"
    >
      <p><strong>Customer Notice</strong></p>
      <p>
        Prices listed on invoices and advertisements reflect our cash/e-check
        price. Our regular price includes a {{ nonCashAdjustmentPercent }}%
        Non-Cash Adjustment.
      </p>
      <p><strong>Disclaimer</strong></p>
      <p>
        The purpose of the non-cash adjustment is to incentivize customers to
        pay with cash/e-check. This is an “in-kind incentive” in compliance with
        section (2)(A) of the Durbin Amendment, a provision of United States
        Federal Law, 15 U.S.C &amp; 169o-2. We further provide a Cash/E-Check
        Discount from the regular price in accordance with section (4)(c)(4) of
        the same document. This notice is meant to inform customers of our
        regular price in compliance with this law.
      </p>
      <template #footer>
        <Button
          label="OK"
          icon="pi pi-check"
          @click="emitNonCashAdjustmentModalDataEvent()"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { toRef } from "vue";

export default {
  props: {
    showModalProp: {
      type: Boolean,
    },
    nonCashAdjustmentPercentProp: {
      type: Number,
    },
  },
  emits: ["resetIsVisible"],
  setup(props, { emit }) {
    const isModalVisible = toRef(props, "showModalProp");
    const nonCashAdjustmentPercent = toRef(
      props,
      "nonCashAdjustmentPercentProp"
    );

    function emitNonCashAdjustmentModalDataEvent() {
      setTimeout(emit("resetIsVisible", false), 1500);
    }

    return {
      isModalVisible,
      nonCashAdjustmentPercent,
      emitNonCashAdjustmentModalDataEvent,
    };
  },
};
</script>
