<template>
  <div>
    <h2 v-if="loading === true">Redirecting to Login Page...</h2>
  </div>
</template>

<script>
import { ref } from "vue";
import auth from "@/services/auth";

export default {
  name: "AdminLogin",
  setup() {
    const loading = ref(false);
    loading.value = true;
    auth.adminAuthenticate();
    return {
      loading,
    };
  },
};
</script>