import router from "../router";

export default {
    state: {
        accessToken: null,
        profile: {
            given_name: null,
            family_name: null,
            emailAddress: null,
            userId: null,
            userRole: null,
        },
        verification: null,
        callbackUri: null,
        tenantId: null,
        clientId: null,
        paymentProviderSettingsId: null,
        isAuthenticated: false,
        signedInvoiceId: null,
    },

    getters: {
        given_name: (state) => state.profile.given_name,
        family_name: (state) => state.profile.family_name,
        emailAddress: (state) => state.profile.emailAddress,
        userId: (state) => state.profile.userId,
        userRole: (state) => state.profile.userRole,
        verification: (state) => state.verification,
        callbackUri: (state) => state.callbackUri,
        accessToken: (state) => state.accessToken,
        tenantId: (state) => state.tenantId,
        clientId: (state) => state.clientId,
        paymentProviderSettingsId: (state) => state.paymentProviderSettingsId,
        isAuthenticated: (state) => state.isAuthenticated,
        signedInvoiceId: (state) => state.signedInvoiceId,
    },

    mutations: {
        setGiven_Name: (state, given_name) => {
            state.profile.given_name = given_name;
        },
        setFamily_Name: (state, family_name) => {
            state.profile.family_name = family_name;
        },
        setEmailAddress: (state, emailAddress) => {
            state.profile.emailAddress = emailAddress;
        },
        setUserId: (state, userId) => {
            state.profile.userId = userId;
        },
        setUserRole: (state, userRole) => {
            state.profile.userRole = userRole;
        },
        setAccessToken: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        setVerification: (state, verification) => {
            state.verification = verification;
        },
        setCallbackUri: (state, callbackUri) => {
            state.callbackUri = callbackUri;
        },
        setTenantId: (state, tenantId) => {
            state.tenantId = tenantId;
        },
        setClientId: (state, clientId) => {
            state.clientId = clientId;
        },
        setPaymentProviderSettingsId: (state, paymentProviderSettingsId) => {
            state.paymentProviderSettingsId = paymentProviderSettingsId;
        },
        setAuthenticated: (state, isAuthenticated) => {
            state.isAuthenticated = isAuthenticated;
        },
        setSignedInvoiceId: (state, signedInvoiceId) => {
            state.signedInvoiceId = signedInvoiceId;
        },
    },

    actions: {
        signout: function (context) {
            context.commit("setGiven_Name", null);
            context.commit("setFamily_Name", null);
            context.commit("setEmailAddress", null);
            context.commit("setUserId", null);
            context.commit("setUserRole", null);
            context.commit("setAccessToken", null);
            context.commit("setVerification", null);
            context.commit("setCallbackUri", null);
            context.commit("setTenantId", null);
            context.commit("setClientId", null);
            context.commit("setPaymentProviderSettingsId", null);
            context.commit("setAuthenticated", false);
            context.commit("setSignedInvoiceId", null);
        },
        authenticate: function (context, payload) {
            if (
                payload.verification === null ||
                payload.verification !== context.getters.verification
            ) {
                router.push({
                    name: "Error",
                });
                return;
            }

            context.commit("setGiven_Name", payload.given_name);
            context.commit("setFamily_Name", payload.family_name);
            context.commit("setEmailAddress", payload.emailAddress);
            context.commit("setUserId", payload.userId);
            context.commit("setUserRole", payload.userRole);
            context.commit("setAccessToken", payload.accessToken);
            context.commit("setTenantId", payload.tenantId);
            context.commit("setClientId", payload.clientId);
            context.commit("setAuthenticated", true);
            context.commit("setSignedInvoiceId", null);
        },
    },
};