"use strict";
import httpClient from "./httpClient";
import store from "../store";

export default {
    async getSubdomainSettings() {
        try {
            var response = await httpClient.get("/payment-provider-settings", {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
            });
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getPaymentProviderSettingsById(tenantId, paymentProviderSettingsId) {
        try {
            const bearerToken = store.getters.accessToken;
            var response = await httpClient.get(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getPaymentProviderSettingsBySignedInvoiceId(signedInvoiceId) {
        try {
            var response = await httpClient.get(
                `/payment-provider-settings/${signedInvoiceId}`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getMerchantSettings(tenantId, paymentProviderSettingsId) {
        try {
            const bearerToken = store.getters.accessToken;
            var response = await httpClient.get(
                `/tenants/${tenantId}/payment-provider-settings/${paymentProviderSettingsId}/merchant-settings`, {
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                    Authorization: "Bearer " + bearerToken,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
    async getMerchantSettingsBySignedInvoiceId(signedInvoiceId) {
        try {
            var response = await httpClient.get(
                `/payment-provider-settings/${signedInvoiceId}/merchant-settings`, {
                baseURL: process.env.VUE_APP_EVPAY_PUBLIC_API_BASE_URL,
                headers: {
                    "x-api-key": process.env.VUE_APP_API_KEY,
                },
            }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};