<template>
  <div class="card ev-fluid-card">
    <div class="card-header">
      <h4>Payment Provider Settings Details</h4>
    </div>

    <div class="formgrid grid">
      <div class="field col-12 md:col-6">
        <label for="name">Company Name:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="name"
            v-model="paymentProviderSettings.name"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('name')"
          ></Button>
        </div>
        <template v-if="v$.paymentProviderSettings.name.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.name.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="field col-12 md:col-6">
        <label for="subDomain">Subdomain:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="subDomain"
            v-model="paymentProviderSettings.subDomain"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('subDomain')"
          ></Button>
        </div>
        <template v-if="v$.paymentProviderSettings.subDomain.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.subDomain.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div v-if="!isUpdate" class="field col-12 md:col-6">
        <label for="consumerKey">Consumer Key:</label>
        <div class="p-inputgroup flex-1">
          <InputText
            id="consumerKey"
            v-model="paymentProviderSettings.consumerKey"
            class="inputfield w-full"
          />
        </div>
        <template v-if="v$.paymentProviderSettings.consumerKey.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.consumerKey
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div v-if="!isUpdate" class="field col-12 md:col-6">
        <label for="consumerSecret">Consumer Secret:</label>
        <div class="p-inputgroup flex-1">
          <Button
            icon="pi pi-eye"
            type="password"
            @click="switchConsumerSecretVisibility"
          ></Button>
          <InputText
            id="consumerSecret"
            v-model="paymentProviderSettings.consumerSecret"
            class="inputfield w-full"
            :type="consumerSecretPasswordFieldType"
          />
        </div>
        <template v-if="v$.paymentProviderSettings.consumerSecret.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.consumerSecret
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div v-if="isUpdate" class="field col-12 md:col-6">
        <label for="paymentProviderSettingsId"
          >Payment Provider Settings Id:</label
        >
        <div>
          <InputText
            id="paymentProviderSettingsId"
            v-model="paymentProviderSettings.paymentProviderSettingsId"
            class="inputfield w-full"
            disabled
          />
        </div>
      </div>

      <div v-if="isUpdate" class="field col-12 md:col-6">
        <label for="enabled">Enabled:</label>
        <div>
          <InputSwitch
            v-model="paymentProviderSettings.enabled"
            @change="updateField('enabled')"
          />
        </div>
      </div>

      <div class="col-12 pt-3">
        <h5>Address</h5>
      </div>

      <div class="field col-12">
        <div v-if="paymentProviderSettings.address">
          <label for="address.addressLine1">Address Line 1:</label>
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.addressLine1"
              v-model="paymentProviderSettings.address.addressLine1"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.addressLine1')"
            ></Button>
          </div>
          <template
            v-if="v$.paymentProviderSettings.address.addressLine1.$dirty"
          >
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address.addressLine1
                .$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="field col-12">
        <div v-if="paymentProviderSettings.address">
          <label for="address.addressLine2">Address Line 2:</label>
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.addressLine2"
              v-model="paymentProviderSettings.address.addressLine2"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.addressLine2')"
            ></Button>
          </div>
          <template
            v-if="v$.paymentProviderSettings.address.addressLine2.$dirty"
          >
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address.addressLine2
                .$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div v-if="paymentProviderSettings.address">
          <label for="address.city">City:</label>
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.city"
              v-model="paymentProviderSettings.address.city"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.city')"
            ></Button>
          </div>
          <template v-if="v$.paymentProviderSettings.address.city.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address.city
                .$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="field col-12 md:col-3">
        <div v-if="paymentProviderSettings.address">
          <label for="address.state">State:</label>
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.state"
              v-model="paymentProviderSettings.address.state"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.state')"
            ></Button>
          </div>
          <template v-if="v$.paymentProviderSettings.address.state.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address.state
                .$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="field col-12 md:col-3">
        <div v-if="paymentProviderSettings.address">
          <label for="address.zipCode">Zip Code:</label>
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.zipCode"
              v-model="paymentProviderSettings.address.zipCode"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.zipCode')"
            ></Button>
          </div>
          <template v-if="v$.paymentProviderSettings.address.zipCode.$dirty">
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address.zipCode
                .$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div v-if="paymentProviderSettings.address">
          <label for="address.phoneNumber">Phone Number:</label>
          <div class="p-inputgroup flex-1">
            <InputMask
              id="address.phoneNumber"
              v-model="paymentProviderSettings.address.phoneNumber"
              date="phone"
              mask="(999) 999-9999"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.phoneNumber')"
            ></Button>
          </div>
        </div>
      </div>
      <div class="field col-12 md:col-6">
        <div v-if="paymentProviderSettings.address">
          <label for="address.contactEmailAddress"
            >Contact Email Address:</label
          >
          <div class="p-inputgroup flex-1">
            <InputText
              id="address.contactEmailAddress"
              v-model="paymentProviderSettings.address.contactEmailAddress"
              class="inputfield w-full"
            />
            <Button
              v-if="isUpdate"
              :icon="loadingIconType"
              @click="updateField('address.contactEmailAddress')"
            ></Button>
          </div>
          <template
            v-if="v$.paymentProviderSettings.address.contactEmailAddress.$dirty"
          >
            <div
              class="error-msg text-pink-700"
              v-for="error of v$.paymentProviderSettings.address
                .contactEmailAddress.$silentErrors"
              :key="error.$message"
            >
              {{ error.$message }}
            </div>
          </template>
        </div>
      </div>

      <div class="col-12 pt-3">
        <h5>Company Logo</h5>
      </div>
      <div
        v-if="isUpdate && paymentProviderSettings.imagePath"
        class="field col-12 guest-checkout-header-logo"
      >
        <p>Current uploaded logo:</p>
        <img
          class="guest-checkout-header-logo-img"
          :src="imagePath"
          alt="company-logo"
        />
      </div>
      <div class="field col-12">
        <label for="imagePath"
          >Upload Logo - must be in a .png file format:</label
        >
        <p>
          <sup
            >* Be sure to save all other changes prior to clicking Upload image
            button</sup
          >
        </p>
        <div>
          <FileUpload
            name="fileUpload"
            @uploader="onFileUpload($event)"
            @select="onFileUploadSelectEvent($event)"
            @clear="onClearQueuedFileEvent()"
            @remove="onClearQueuedFileEvent()"
            :customUpload="true"
            :multiple="false"
            :showUploadButton="isUpdate"
            :fileLimit="1"
            accept="image/png"
            :maxFileSize="1000000"
          >
            <template #empty>
              <p>Drag and drop file here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </div>

      <div class="col-12 pt-3">
        <h5>Display Messages</h5>
      </div>

      <div class="field col-12 md:col-6">
        <label for="paymentConfirmationMessage"
          >Payment Confirmation Message:
          <i
            v-tooltip="
              'The custom text to display in the Payment Confirmation email'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div class="p-inputgroup flex-1">
          <Textarea
            id="paymentConfirmationMessage"
            v-model="paymentProviderSettings.paymentConfirmationMessage"
            rows="5"
            cols="30"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('paymentConfirmationMessage')"
          ></Button>
        </div>
        <template
          v-if="v$.paymentProviderSettings.paymentConfirmationMessage.$dirty"
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings
              .paymentConfirmationMessage.$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>
      <div class="field col-12 md:col-6">
        <label for="paymentPageMessage"
          >Payment Page Message:
          <i
            v-tooltip="
              'The custom text to display on the Payment Confirmation page'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div class="p-inputgroup flex-1">
          <Textarea
            id="paymentPageMessage"
            v-model="paymentProviderSettings.paymentPageMessage"
            rows="5"
            cols="30"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('paymentPageMessage')"
          ></Button>
        </div>
        <template v-if="v$.paymentProviderSettings.paymentPageMessage.$dirty">
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.paymentPageMessage
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="col-12 pt-3">
        <h5>Card Processing Price Programs</h5>
      </div>

      <div class="field col-12 md:col-6">
        <label
          >Program Type:
          <i
            v-tooltip="
              'Select the card processing passthrough type to pass on to the client or none'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div>
          <SelectButton
            v-model="paymentProviderSettings.cardProcessingPriceProgramType"
            :options="cardProcessingPriceProgramSelectButtonOptions"
            optionLabel="name"
            optionValue="value"
            v-on="
              isUpdate
                ? {
                    change: () => updateField('cardProcessingPriceProgramType'),
                  }
                : {}
            "
          />
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <label for="nonCashAdjustmentPercent"
          >Non-Cash Adjustment Percent:
          <i
            v-tooltip="
              'The percentage amount to add to an invoice as a convenience of paying with a credit card'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div class="p-inputgroup flex-1">
          <InputNumber
            id="nonCashAdjustmentPercent"
            v-model="paymentProviderSettings.nonCashAdjustmentPercent"
            :maxFractionDigits="2"
            :min="0"
            :max="20"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('nonCashAdjustmentPercent')"
          ></Button>
        </div>
        <template
          v-if="v$.paymentProviderSettings.nonCashAdjustmentPercent.$dirty"
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.nonCashAdjustmentPercent
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="col-12 pt-3">
        <h5>Invoice Files/Formats</h5>
      </div>

      <div class="field col-12 md:col-6">
        <label for="invoiceFileViewerEnabled"
          >Invoice File Viewer Enabled:
          <i
            v-tooltip="
              'If enabled, icons display allowing uploaded invoice files to be viewed'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div>
          <InputSwitch
            v-model="paymentProviderSettings.invoiceFileViewerEnabled"
            v-on="
              isUpdate
                ? { change: () => updateField('invoiceFileViewerEnabled') }
                : {}
            "
          />
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <label for="combinedInvoiceFormatEnabled"
          >Combined Invoice File Format Enabled:
          <i
            v-tooltip="
              'If enabled, invoice numbers will display in the format: \'project number\'-\'invoice number\'. If disabled, project numbers and invoice numbers are displayed separately.'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div>
          <InputSwitch
            v-model="paymentProviderSettings.combinedInvoiceFormatEnabled"
            v-on="
              isUpdate
                ? { change: () => updateField('combinedInvoiceFormatEnabled') }
                : {}
            "
          />
        </div>
      </div>

      <div class="col-12 pt-3">
        <h5>ACH</h5>
      </div>

      <div class="field col-12 md:col-6">
        <label for="achProcessWaitingDays"
          >ACH Waiting Days:
          <i
            v-tooltip="
              'The number of days to wait for funds to settle before automatically creating ACH unposted cash receipts. If left blank, the default is 4 days'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div class="p-inputgroup flex-1">
          <InputNumber
            id="achProcessWaitingDays"
            v-model="paymentProviderSettings.achProcessWaitingDays"
            :min="0"
            :max="14"
            class="inputfield w-full"
          />
          <Button
            v-if="isUpdate"
            :icon="loadingIconType"
            @click="updateField('achProcessWaitingDays')"
          ></Button>
        </div>
        <template
          v-if="v$.paymentProviderSettings.achProcessWaitingDays.$dirty"
        >
          <div
            class="error-msg text-pink-700"
            v-for="error of v$.paymentProviderSettings.achProcessWaitingDays
              .$silentErrors"
            :key="error.$message"
          >
            {{ error.$message }}
          </div>
        </template>
      </div>

      <div class="col-12 pt-3">
        <h5>Scheduled Payments</h5>
      </div>

      <div class="field col-12 md:col-6">
        <label for="scheduledPaymentEnabled"
          >Scheduled Payments Enabled:
          <i
            v-tooltip="
              'If enabled, allows for invoice payments to be scheduled into the future based on invoice due date criteria'
            "
            class="pi pi-info-circle"
          ></i
        ></label>
        <div>
          <InputSwitch
            v-model="paymentProviderSettings.scheduledPaymentEnabled"
            v-on="
              isUpdate
                ? { change: () => updateField('scheduledPaymentEnabled') }
                : {}
            "
          />
        </div>
      </div>
    </div>

    <h5>Notification Email Addresses</h5>

    <template v-if="v$.updatedNotificationEmailAddress.$dirty">
      <div
        class="error-msg text-pink-700"
        v-for="error of v$.updatedNotificationEmailAddress.$silentErrors"
        :key="error.$message"
      >
        {{ error.$message }}
      </div>
    </template>

    <div>
      <DataTable
        :value="paymentProviderSettings.notificationEmailAddresses"
        :paginator="true"
        :rows="15"
        :loading="isEmailAddressLoading"
        editMode="row"
        stripedRows
        autoLayout
        responsiveLayout="scroll"
        v-model:editingRows="editingRows"
        @row-edit-save="onNotificationEmailAddressEditSave"
        @row-edit-cancel="onNotificationEmailAddressEditCancel"
        class="p-datatable-notificationEmailAddresses"
      >
        <template #loading>
          <p style="text-align: center">Loading tenant email addresses...</p>
        </template>
        <div></div>
        <Column field="emailAddress" header="Email Address" sortable>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" class="inputfield w-full" />
          </template>
        </Column>
        <Column
          :rowEditor="true"
          bodyStyle="text-align:right"
          headerStyle="width: 20%"
        ></Column>
        <Column bodyStyle="text-align:left" headerStyle="width: 8%">
          <template #body="slotProps">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="confirmDeleteNotificationEmailAddress(slotProps.data)"
            />
          </template>
        </Column>

        <template #empty>
          <p style="text-align: center">No records found.</p>
        </template>
      </DataTable>
    </div>
    <div>
      <Button
        label="Add New Email Address"
        @click="showNotificationEmailAddressModal()"
      />
    </div>
    <div>
      <NotificationEmail
        :isModalVisibleProp="isNotificationEmailAddressModalVisible"
        :tenantIdProp="paymentProviderSettings.tenantId"
        :paymentProviderSettingsIdProp="
          paymentProviderSettings.paymentProviderSettingsId
        "
        :notificationEmailAddressesProp="
          paymentProviderSettings.notificationEmailAddresses
        "
        :isUpdateProp="isUpdate"
        @resetNotificationEmailAddressModal="
          onNotificationEmailAddressModalDataEvent()
        "
      />
    </div>
    <div v-if="!isUpdate" class="text-center pt-3">
      <Button label="Submit" @click="onSubmit()" />
    </div>
    <div>
      <Dialog
        v-model:visible="deleteNotificationEmailAddressDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
      >
        <div class="confirmation-content">
          <span v-if="deleteNotificationEmailAddress">
            <br />
            Are you sure you want to delete this notification email address:
            <br />
            <strong v-if="deleteNotificationEmailAddress.emailAddress">{{
              deleteNotificationEmailAddress.emailAddress
            }}</strong></span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteNotificationEmailAddressDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="deleteSelectedNotificationEmailAddress()"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { toRef, ref, onMounted, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  minLength,
  maxLength,
  numeric,
  email,
  required,
  between,
  helpers,
} from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import paymentProviderSettingsService from "@/services/Admin/paymentProviderSettings";
import NotificationEmail from "./NotificationEmail.vue";
import router from "@/router";
import { CARD_PROCESSING_PRICE_PROGRAM } from "@/utility/cardProcessingPriceProgram.js";

export default {
  name: "PaymentProviderSettingsDetail",
  components: {
    NotificationEmail,
  },
  props: {
    tenantIdProp: { type: String },
    paymentProviderSettingsIdProp: { type: String },
  },
  setup(props) {
    const route = useRoute();
    const toast = useToast();
    const tenantPaymentProviderSettings = ref([]);
    const paymentProviderSettings = ref({});
    const initialPaymentProviderSettings = ref({
      address: {},
    });
    const tenantId = computed(() => {
      return route.params.tenantId
        ? route.params.tenantId
        : toRef(props, "tenantIdProp");
    });
    const imagePath = computed(
      () =>
        window.location.origin + "/" + paymentProviderSettings.value.imagePath
    );
    const editingRows = ref([]);
    const isEmailAddressLoading = ref(false);
    const updatedNotificationEmailAddress = ref();
    const logoFileSelectedForUpload = ref();
    const loadingIconType = ref("pi pi-check");
    const deleteNotificationEmailAddress = ref({});
    const deleteNotificationEmailAddressDialog = ref(false);
    const isNotificationEmailAddressModalVisible = ref(false);
    const isUpdate = computed(() => {
      return route.params.paymentProviderSettingsId ? true : false;
    });
    const consumerSecretPasswordFieldType = ref("password");
    const cardProcessingPriceProgramSelectButtonOptions = ref();

    async function loadCardProcessingPriceProgramSelectButtonOptions() {
      let options = [];
      for (const [key, value] of Object.entries(
        CARD_PROCESSING_PRICE_PROGRAM
      )) {
        let option = { name: key, value: value };
        options.push(option);
      }

      cardProcessingPriceProgramSelectButtonOptions.value = options;
    }

    const rules = computed(() => ({
      paymentProviderSettings: {
        name: {
          required: helpers.withMessage("Company Name is required", required),
          minLength: helpers.withMessage(
            "Name must be at least 2 characters",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Name cannot be more than 100 characters",
            maxLength(100)
          ),
          duplicatePaymentProviderSettingsName: helpers.withMessage(
            "Company Name already exists",
            validateDuplicatePaymentProviderSettingsName
          ),
        },
        subDomain: {
          maxLength: helpers.withMessage(
            "SubDomain cannot be more than 50 characters",
            maxLength(50)
          ),
        },
        consumerKey: {
          required: helpers.withMessage("Consumer Key is required", required),
        },
        consumerSecret: {
          required: helpers.withMessage(
            "Consumer Secret is required",
            required
          ),
        },
        address: {
          addressLine1: {
            maxLength: helpers.withMessage(
              "Address line 1 cannot be more than 100 characters",
              maxLength(100)
            ),
          },
          addressLine2: {
            maxLength: helpers.withMessage(
              "Address line 2 cannot be more than 100 characters",
              maxLength(100)
            ),
          },
          city: {
            maxLength: helpers.withMessage(
              "City cannot be more than 100 characters",
              maxLength(100)
            ),
          },
          state: {
            minLength: helpers.withMessage(
              "State must be 2 characters",
              minLength(2)
            ),
            maxLength: helpers.withMessage(
              "State must be 2 characters",
              maxLength(2)
            ),
          },
          zipCode: {
            numeric: helpers.withMessage("Zip Code must be numeric", numeric),
            minLength: helpers.withMessage(
              "Zip Code must be 5 characters",
              minLength(5)
            ),
            maxLength: helpers.withMessage(
              "Zip Code must be 5 characters",
              maxLength(5)
            ),
          },
          contactEmailAddress: {
            email: helpers.withMessage(
              "Contact Email Address is in an invalid format",
              email
            ),
          },
        },
        paymentConfirmationMessage: {
          maxLength: helpers.withMessage(
            "Payment Confirmation Message cannot be more than 1000 characters",
            maxLength(1000)
          ),
        },
        paymentPageMessage: {
          maxLength: helpers.withMessage(
            "Payment Page Message cannot be more than 1000 characters",
            maxLength(1000)
          ),
        },
        nonCashAdjustmentPercent: {
          numeric: helpers.withMessage(
            "Surcharge percent must be numeric",
            numeric
          ),
          between: helpers.withMessage(
            "Surcharge percent must be between 0 and 20",
            between(0, 20)
          ),
        },
        cardProcessingPriceProgramType: {
          numeric: helpers.withMessage(
            "Invalid card processing program type",
            numeric
          ),
        },
        achProcessWaitingDays: {
          numeric: helpers.withMessage(
            "ACH Waiting Days must be numeric",
            numeric
          ),
          between: helpers.withMessage(
            "ACH Waiting Days must be between 0 and 14 days",
            between(0, 14)
          ),
        },
      },
      updatedNotificationEmailAddress: {
        required: helpers.withMessage(
          "Notification Email Address is required",
          required
        ),
        email: helpers.withMessage(
          "Notification Email Address is in an invalid format",
          email
        ),
        duplicateEmailAddress: helpers.withMessage(
          "Notification Email Address already exists",
          validateEditDuplicateEmailAddress
        ),
      },
    }));

    const v$ = useVuelidate(
      rules,
      {
        paymentProviderSettings,
        updatedNotificationEmailAddress,
      },
      { $lazy: true }
    );

    onMounted(fetchPaymentProviderSettings);
    onMounted(fetchTenantPaymentProviderSettings);
    onMounted(loadCardProcessingPriceProgramSelectButtonOptions);

    async function fetchPaymentProviderSettings() {
      if (isUpdate.value) {
        isEmailAddressLoading.value = true;
        paymentProviderSettings.value =
          await paymentProviderSettingsService.getPaymentProviderSettingsById(
            tenantId.value,
            props.paymentProviderSettingsIdProp
          );

        Object.assign(
          initialPaymentProviderSettings.value.address,
          paymentProviderSettings.value.address
        );
        isEmailAddressLoading.value = false;
      } else {
        paymentProviderSettings.value = {
          name: "",
          address: {},
          notificationEmailAddresses: [],
        };
      }
    }

    async function fetchTenantPaymentProviderSettings() {
      tenantPaymentProviderSettings.value =
        await paymentProviderSettingsService.getPaymentProviderSettingsByTenantId(
          tenantId.value
        );
    }

    async function onSubmit() {
      if (!(await v$.value.paymentProviderSettings.$validate())) return;
      let response;
      try {
        paymentProviderSettings.value.address.phoneNumber =
          paymentProviderSettings.value.address.phoneNumber === ""
            ? undefined
            : paymentProviderSettings.value.address.phoneNumber;
        response =
          await paymentProviderSettingsService.createPaymentProviderSettings(
            tenantId.value,
            paymentProviderSettings.value
          );
        toast.success("Created Payment Provider Settings");
      } catch (error) {
        toast.error(error);
        return;
      }

      if (logoFileSelectedForUpload.value) {
        try {
          await paymentProviderSettingsService.uploadLogo(
            response.tenantId,
            response.paymentProviderSettingsId,
            logoFileSelectedForUpload.value
          );
          toast.success("Logo successfully uploaded");
        } catch (error) {
          toast.error(error);
        }
      }
      setTimeout(
        await router.push({
          name: "TenantCompanies",
          params: { tenantId: tenantId.value },
        }),
        2000
      );
    }

    async function onNotificationEmailAddressEditSave(event) {
      let { newData, index } = event;
      updatedNotificationEmailAddress.value = newData.emailAddress;

      if (!(await v$.value.updatedNotificationEmailAddress.$validate())) {
        editingRows.value = [...editingRows.value, event.data];
        return;
      }

      paymentProviderSettings.value.notificationEmailAddresses[index] = newData;
      isEmailAddressLoading.value = true;

      if (isUpdate.value) {
        try {
          await paymentProviderSettingsService.updateNotificationEmailAddresses(
            paymentProviderSettings.value.tenantId,
            paymentProviderSettings.value.paymentProviderSettingsId,
            paymentProviderSettings.value.notificationEmailAddresses
          );
          toast.success("Notification email address updated");
        } catch (error) {
          toast.error(error);
        }
      }

      v$.value.updatedNotificationEmailAddress.$reset();
      isEmailAddressLoading.value = false;
    }

    async function onNotificationEmailAddressEditCancel() {
      v$.value.updatedNotificationEmailAddress.$reset();
    }

    async function updateField(propertyName) {
      loadingIconType.value = "pi pi-spinner";
      let properties = propertyName.split(".");
      let fieldValue = "";
      let payload = {};
      if (properties.length == 2 && properties[0] == "address") {
        if (!(await v$.value.paymentProviderSettings.address.$validate())) {
          loadingIconType.value = "pi pi-check";
          return;
        }
        fieldValue = paymentProviderSettings.value.address[properties[1]];
        payload = {
          address: {
            addressLine1:
              properties[1] == "addressLine1"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.addressLine1,
            addressLine2:
              properties[1] == "addressLine2"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.addressLine2,
            city:
              properties[1] == "city"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.city,
            state:
              properties[1] == "state"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.state,
            zipCode:
              properties[1] == "zipCode"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.zipCode,
            phoneNumber:
              properties[1] == "phoneNumber"
                ? fieldValue
                : initialPaymentProviderSettings.value.address.phoneNumber,
            contactEmailAddress:
              properties[1] == "contactEmailAddress"
                ? fieldValue
                : initialPaymentProviderSettings.value.address
                    .contactEmailAddress,
          },
        };
      } else if (properties.length == 1 && properties[0] != "address") {
        if (
          typeof paymentProviderSettings.value[propertyName] !== "boolean" &&
          !(await v$.value.paymentProviderSettings[properties[0]].$validate())
        ) {
          loadingIconType.value = "pi pi-check";
          return;
        }
        fieldValue = paymentProviderSettings.value[propertyName];
        payload =
          typeof fieldValue === "boolean"
            ? { [propertyName]: fieldValue.toString() }
            : { [propertyName]: fieldValue };
      } else {
        loadingIconType.value = "pi pi-check";
        return toast.error("Unable to Update Payment Provider Settings value");
      }

      try {
        const response =
          await paymentProviderSettingsService.updatePaymentProviderSettings(
            paymentProviderSettings.value.tenantId,
            paymentProviderSettings.value.paymentProviderSettingsId,
            payload
          );

        paymentProviderSettings.value = response;
        Object.assign(
          initialPaymentProviderSettings.value.address,
          paymentProviderSettings.value.address
        );
        toast.success("Payment Provider Settings updated");
      } catch (error) {
        toast.error(error);
      }

      loadingIconType.value = "pi pi-check";
    }

    async function onFileUpload(event) {
      try {
        let formData = new FormData();
        formData.append("file", event.files[0]);
        await paymentProviderSettingsService.uploadLogo(
          paymentProviderSettings.value.tenantId,
          paymentProviderSettings.value.paymentProviderSettingsId,
          formData
        );
        toast.success("Logo successfully uploaded");
      } catch (error) {
        toast.error(error);
      }
      window.location.reload();
    }

    function onFileUploadSelectEvent(event) {
      if (!event.files[0]) {
        logoFileSelectedForUpload.value = undefined;
      } else {
        let formData = new FormData();
        formData.append("file", event.files[0]);
        logoFileSelectedForUpload.value = formData;
      }
    }

    function onClearQueuedFileEvent() {
      logoFileSelectedForUpload.value = undefined;
    }

    async function deleteSelectedNotificationEmailAddress() {
      deleteNotificationEmailAddressDialog.value = false;
      let indexToRemove =
        paymentProviderSettings.value.notificationEmailAddresses.findIndex(
          (x) =>
            x.emailAddress === deleteNotificationEmailAddress.value.emailAddress
        );
      paymentProviderSettings.value.notificationEmailAddresses.splice(
        indexToRemove,
        1
      );

      if (isUpdate.value) {
        try {
          await paymentProviderSettingsService.updateNotificationEmailAddresses(
            paymentProviderSettings.value.tenantId,
            paymentProviderSettings.value.paymentProviderSettingsId,
            paymentProviderSettings.value.notificationEmailAddresses
          );
          toast.success("Notification email address updated");
        } catch (error) {
          toast.error(error);
        }
      }

      v$.value.updatedNotificationEmailAddress.$reset();
    }

    function validateDuplicatePaymentProviderSettingsName() {
      var existingPaymentProviderSettingsName =
        tenantPaymentProviderSettings.value.filter(function (elem) {
          if (
            elem.name.toLowerCase() ==
            paymentProviderSettings.value.name.toLowerCase()
          )
            return elem.name;
        });
      return existingPaymentProviderSettingsName.length > 0 ? false : true;
    }

    function showNotificationEmailAddressModal() {
      isNotificationEmailAddressModalVisible.value = true;
    }

    function confirmDeleteNotificationEmailAddress(deleteEmailAddress) {
      deleteNotificationEmailAddress.value = deleteEmailAddress;
      deleteNotificationEmailAddressDialog.value = true;
    }

    function onNotificationEmailAddressModalDataEvent() {
      setTimeout((isNotificationEmailAddressModalVisible.value = false), 1500);
    }

    function validateEditDuplicateEmailAddress() {
      var existingEmailAddress =
        paymentProviderSettings.value.notificationEmailAddresses.filter(
          function (elem) {
            if (
              elem.emailAddress.toLowerCase() ===
              updatedNotificationEmailAddress.value.toLowerCase()
            )
              return elem.emailAddress;
          }
        );

      return existingEmailAddress.length > 0 ? false : true;
    }

    function switchConsumerSecretVisibility() {
      consumerSecretPasswordFieldType.value =
        consumerSecretPasswordFieldType.value === "password"
          ? "text"
          : "password";
    }

    return {
      paymentProviderSettings,
      imagePath,
      editingRows,
      isEmailAddressLoading,
      loadingIconType,
      deleteNotificationEmailAddress,
      deleteNotificationEmailAddressDialog,
      isNotificationEmailAddressModalVisible,
      isUpdate,
      consumerSecretPasswordFieldType,
      cardProcessingPriceProgramSelectButtonOptions,
      v$,
      updateField,
      onSubmit,
      onNotificationEmailAddressEditSave,
      onNotificationEmailAddressEditCancel,
      onFileUpload,
      onFileUploadSelectEvent,
      onClearQueuedFileEvent,
      confirmDeleteNotificationEmailAddress,
      deleteSelectedNotificationEmailAddress,
      showNotificationEmailAddressModal,
      onNotificationEmailAddressModalDataEvent,
      switchConsumerSecretVisibility,
    };
  },
};
</script>
