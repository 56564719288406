import store from "../store";
import clientsClient from "../api/clients.api";

export default {
    getClientById: async function () {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            response = await clientsClient.getClientById(tenantId, clientId);
        }
        catch (error) {
            const ex = new Error("Unable to retrieve client");
            throw ex.message;
        }

        if (response && response.status !== 200) {
            const ex = new Error("Unable to retrieve client");
            throw ex.message;
        }

        return response.data;
    },

    fetchClientStoredPaymentMethods: async function () {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            const paymentProviderSettingsId = store.getters.paymentProviderSettingsId;

            response = await clientsClient.getVaultedAccounts(
                tenantId,
                clientId,
                paymentProviderSettingsId
            );
        } catch (error) {
            response = error;
        }

        if (response && response.status === 200 && response.data) {
            response = await retrieveClientStoredPaymentMethodsFromResponse(
                response.data
            );
            if (Array.isArray(response)) {
                store.commit("setAccounts", response);
            }
        }
        return response;
    },

    createClientVaultedAccount: async function (accountDto) {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;
            accountDto.paymentProviderSettingsId =
                store.getters.paymentProviderSettingsId;

            response = await clientsClient.postVaultedAccount(
                tenantId,
                clientId,
                accountDto
            );
        } catch (error) {
            response = error;
        }
        return response;
    },

    updateClientVaultedCardAccount: async function (vaultedCardAccountDto) {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            let updateVaultedCardAccountDto = {
                id: vaultedCardAccountDto.id,
                token: vaultedCardAccountDto.token,
                expiryMonth: vaultedCardAccountDto.expiryMonth,
                expiryYear: vaultedCardAccountDto.expiryYear,
                avsStreet: vaultedCardAccountDto.avsStreet,
                avsZip: vaultedCardAccountDto.avsZip,
                isDefault: vaultedCardAccountDto.isDefault,
                paymentProviderSettingsId: vaultedCardAccountDto.paymentProviderSettingsId,
            }

            response = await clientsClient.putVaultedCardAccount(
                tenantId,
                clientId,
                updateVaultedCardAccountDto
            );

            if (response && response.status === 204) {
                await this.fetchClientStoredPaymentMethods();
            }
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    updateClientVaultedBankAccount: async function (vaultedBankAccountDto) {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            let updateVaultedBankAccountDto = {
                id: vaultedBankAccountDto.id,
                token: vaultedBankAccountDto.token,
                name: vaultedBankAccountDto.name,
                type: vaultedBankAccountDto.type,
                isDefault: vaultedBankAccountDto.isDefault,
                paymentProviderSettingsId: vaultedBankAccountDto.paymentProviderSettingsId,
            }

            response = await clientsClient.putVaultedBankAccount(
                tenantId,
                clientId,
                updateVaultedBankAccountDto
            );

            if (response && response.status === 204) {
                await this.fetchClientStoredPaymentMethods();
            }
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    deleteClientVaultedCardAccount: async function (cardAccountDto) {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            response = await clientsClient.deleteVaultedCardAccount(
                tenantId,
                clientId,
                cardAccountDto
            );

            if (response && response.status === 204) {
                await this.fetchClientStoredPaymentMethods();
            }
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    deleteClientVaultedBankAccount: async function (bankAccountDto) {
        let response;

        try {
            const tenantId = store.getters.tenantId;
            const clientId = store.getters.clientId;

            response = await clientsClient.deleteVaultedBankAccount(
                tenantId,
                clientId,
                bankAccountDto
            );

            if (response && response.status === 204) {
                await this.fetchClientStoredPaymentMethods();
            }
        } catch (error) {
            response = error;
        }
        return response.status;
    },

    fetchStoredBankAccounts: async function (storedPaymentMethods) {
        let storedBankAccounts = [];

        if (storedPaymentMethods.length) {
            for (let i = 0; i < storedPaymentMethods.length; i++) {
                if (storedPaymentMethods[i].type) {
                    storedBankAccounts.push(storedPaymentMethods[i]);
                }
            }
        }
        return storedBankAccounts;
    },

    fetchStoredCardAccounts: async function (storedPaymentMethods) {
        let storedCardAccounts = [];

        if (storedPaymentMethods.length) {
            for (let i = 0; i < storedPaymentMethods.length; i++) {
                if (storedPaymentMethods[i].cardType) {
                    storedCardAccounts.push(storedPaymentMethods[i]);
                }
            }
        }
        return storedCardAccounts;
    },

    fetchDefaultStoredAccount: async function () {
        const storedAccounts = await store.getters.accounts;
        let defaultAccount = {};

        if (storedAccounts && storedAccounts.length) {
            defaultAccount = storedAccounts.find(
                (account) => account.isDefault === true
            );
        }

        if (store.getters.enableCC === false && defaultAccount.cardType) {
            defaultAccount = storedAccounts.find(
                (account) => account.isDefault === false && account.type
            );
        }

        if (store.getters.enableACH === false && defaultAccount.type) {
            defaultAccount = storedAccounts.find(
                (account) => account.isDefault === false && account.cardType
            );
        }

        if (store.getters.enableCC === false && store.getters.enableACH === false) {
            defaultAccount = {};
        }

        return defaultAccount;
    },

    fetchClientAdminUserEmailAddress: async function () {
        const client = await this.getClientById();
        let adminEmailAddress;
        if (Array.isArray(client.adminEmailAddresses) && client.adminEmailAddresses.length) {
            adminEmailAddress = client.adminEmailAddresses[0].emailAddress;
        }

        return adminEmailAddress;
    }
};

async function retrieveClientStoredPaymentMethodsFromResponse(data) {
    const responseData = data;
    let storedAccounts = [];
    if (
        responseData.mxMerchantVaultedCardAccounts &&
        responseData.mxMerchantVaultedCardAccounts.length
    ) {
        pushClientStoredPaymentMethods(
            storedAccounts,
            responseData.mxMerchantVaultedCardAccounts
        );
    }

    if (
        responseData.mxMerchantVaultedBankAccounts &&
        responseData.mxMerchantVaultedBankAccounts.length
    ) {
        pushClientStoredPaymentMethods(
            storedAccounts,
            responseData.mxMerchantVaultedBankAccounts
        );
    }
    return storedAccounts;
}

function pushClientStoredPaymentMethods(storedAccounts, accountsToStore) {
    for (let i = 0; i < accountsToStore.length; i++) {
        storedAccounts.push(accountsToStore[i]);
    }
    return storedAccounts;
}