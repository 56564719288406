<template>
  <div>
    <div>
      <div class="card ev-fluid-card-wide">
        <DataTable
          :value="scheduledPayments"
          v-model:editingRows="rowEdit"
          @row-edit-save="onRowEditSave"
          :paginator="true"
          :rows="15"
          :loading="isLoading"
          :globalFilterFields="[
            'erpInvoiceNumber',
            'erpProjectNumber',
            'invoiceNumber',
            'paymentAmount',
            'paymentMethod',
            'scheduleStatus',
            'paymentDateCalendar',
            'invoiceDateCalendar',
            'dueDateCalendar',
          ]"
          :multiSortMeta="[{ field: 'paymentDate', order: 1 }]"
          editMode="row"
          sortMode="multiple"
          class="p-datatable-customers"
          responsiveLayout="scroll"
          dataKey="scheduledPaymentId"
          v-model:filters="filters"
          filterDisplay="menu"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Keyword Search"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <p style="text-align: center">Loading scheduled payments data...</p>
          </template>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === true"
            header="Invoice"
            field="invoiceNumber"
            style="min-width: 10rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="image-text">{{ data.invoiceNumber }}</span>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
                placeholder="Search by invoice"
              />
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            header="Project"
            field="erpProjectNumber"
            style="min-width: 10rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="image-text">{{ data.erpProjectNumber }}</span>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
                placeholder="Search by project"
              />
            </template>
          </Column>
          <Column
            v-if="isCombinedInvoiceFormatEnabled === false"
            header="Invoice"
            field="erpInvoiceNumber"
            style="min-width: 10rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="image-text">{{ data.erpInvoiceNumber }}</span>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
                placeholder="Search by invoice number"
              />
            </template>
          </Column>
          <Column
            header="Invoice Date"
            field="invoiceDate"
            dataType="date"
            :sortable="true"
            style="min-width: 8rem"
          >
            <template #body="{ data }">
              {{ data.invoiceDateCalendar }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
              />
            </template>
          </Column>
          <Column
            v-if="showInvoiceDueDate === true"
            header="Due Date"
            field="dueDate"
            sortField="dueDateSort"
            dataType="date"
            :sortable="true"
            style="min-width: 8rem"
          >
            <template #body="{ data }">
              {{ data.dueDateCalendar }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
              />
            </template>
          </Column>
          <Column
            header="Amount"
            field="paymentAmount"
            dataType="numeric"
            style="min-width: 10rem"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.paymentAmountCurrency }}
            </template>
            <template #filter="{ filterModel }">
              <InputNumber
                v-model="filterModel.value"
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Search by payment amount"
              />
            </template>
          </Column>
          <Column
            header="Method"
            field="paymentMethod"
            style="min-width: 10rem"
            :sortable="true"
          >
            <template #body="{ data }">
              <span class="image-text">{{ data.paymentMethod }}</span>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
                placeholder="Search by payment method"
              />
            </template>
          </Column>
          <Column
            header="Payment Date"
            field="paymentDate"
            dataType="date"
            :sortable="true"
            style="min-width: 8rem"
          >
            <template #body="{ data }">
              {{ data.paymentDateCalendar }}
            </template>
            <template #filter="{ filterModel }">
              <Calendar
                v-model="filterModel.value"
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
              />
            </template>
            <template #editor="{ data, field }">
              <Calendar
                id="icon"
                v-model="data[field]"
                :selectionMode="'single'"
                :minDate="minDate"
                :maxDate="calculateMaxScheduleDate(data)"
                :manualInput="false"
                :showIcon="true"
              />
            </template>
          </Column>
          <Column
            v-if="isInvoiceFileViewerEnabled"
            bodyStyle="text-align:center"
          >
            <template #body="{ data }">
              <Button
                v-if="data.filePath"
                v-tooltip="'View Invoice'"
                icon="pi pi-file-pdf"
                @click="fetchInvoiceFileUrl(data.invoiceId)"
              />
            </template>
          </Column>
          <Column
            header="Status"
            field="scheduleStatus"
            :showFilterMatchModes="false"
            :showApplyButton="false"
            :showClearButton="false"
            :sortable="true"
            style="min-width: 6rem"
          >
            <template #body="{ data }">
              <span class="image-text">{{ data.scheduleStatus }}</span>
            </template>
            <template #filter="{ filterModel }">
              <Dropdown
                v-model="filterModel.value"
                :options="statuses"
                placeholder="Status"
                class="p-column-filter"
                @change="onStatusChange($event)"
                :showFilterMatchModes="false"
              >
                <template #option="slotProps">
                  <div class="p-multiselect-scheduleStatus-option">
                    <span class="image-text">{{ slotProps.option }}</span>
                  </div>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column
            v-if="isColumnRoleVisible && isCreatedStatus"
            :rowEditor="true"
            bodyStyle="text-align:right"
            headerStyle="width: 20%"
          ></Column>
          <Column
            v-if="isColumnRoleVisible && isCreatedStatus"
            bodyStyle="text-align:left" 
            headerStyle="width: 8%"
          >
            <template #body="slotProps">
              <Button
                :disabled="slotProps.data.scheduleStatus !== 'created'"
                :loading="isLoading"
                v-tooltip="'Cancel payment'"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmCancelScheduledPayment(slotProps.data)"
              />
            </template>
          </Column>
          <template #empty>
            <p style="text-align: center">No records found.</p>
          </template>
        </DataTable>
      </div>
    </div>
    <div>
      <Dialog
        v-model:visible="cancelScheduledPaymentDialog"
        :style="{ width: '450px' }"
        header="Confirm Cancelation"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="cancelScheduledPayment"
            >Are you sure you want to cancel this scheduled payment?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="cancelScheduledPaymentDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="onCancelScheduledPayment()"
          />
        </template>
      </Dialog>
    </div>
    <div v-if="isWebViewerModalVisible === true">
      <WebViewerModal
        :document="invoiceFileUrl"
        :showModalProp="isWebViewerModalVisible"
        @resetIsVisible="onWebViewerModalDataEvent()"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import scheduledPaymentService from "@/services/scheduledPayment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useToast } from "vue-toastification";
import {
  formatDate,
  formatInvoiceNumber,
  calculateNumberDaysUntilDate,
} from "@/utility/helpers";
import { CLIENT_USER_ROLE_NAMES } from "@/utility/role";
import invoiceService from "@/services/invoice";
import WebViewerModal from "../WebViewerModal.vue";
import store from "@/store";

export default {
  components: {
    WebViewerModal,
  },
  setup() {
    const toast = useToast();
    const scheduledPayments = ref([]);
    const isLoading = ref(true);
    const statuses = ref(["created", "completed", "failed", "canceled"]);
    const rowEdit = ref([]);
    const minDate = ref(new Date());
    minDate.value.setDate(minDate.value.getDate() + 1);
    const cancelScheduledPayment = ref();
    const cancelScheduledPaymentDialog = ref(false);
    const isCreatedStatus = ref(false);
    const invoiceFileUrl = ref();
    const isWebViewerModalVisible = ref(false);
    const isColumnRoleVisible = computed(() =>
      CLIENT_USER_ROLE_NAMES.includes(store.getters.userRole)
    );
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const showInvoiceDueDate = computed(() => store.getters.showInvoiceDueDate);

    const fetchScheduledPayments = async () => {
      scheduledPayments.value = await getScheduledPayments("created", "");
    };

    const isInvoiceFileViewerEnabled = computed(
      () => store.getters.invoiceFileViewerEnabled
    );

    onMounted(fetchScheduledPayments);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      erpInvoiceNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      erpProjectNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      invoiceNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      paymentAmount: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      paymentMethod: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      invoiceDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      dueDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      paymentDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      scheduleStatus: {
        constraints: [{ value: "created", matchMode: FilterMatchMode.EQUALS }],
      },
    });

    async function getScheduledPayments(status = "", date = "") {
      isLoading.value = true;
      try {
        var scheduledPayments =
          await scheduledPaymentService.getScheduledPaymentsByClient(
            status,
            date
          );
        scheduledPayments.forEach((result) => {
          result.invoiceNumber =
            result.erpProjectNumber +
            "-" +
            formatInvoiceNumber(result.erpInvoiceNumber);
          result.paymentMethod =
            (result.paymentTypeDetail == null ? "" : result.paymentTypeDetail) +
            " - " +
            (result.last4 == null ? "" : result.last4);
        });
        isLoading.value = false;
        if (scheduledPayments.length) {
          isCreatedStatus.value =
            scheduledPayments[0].scheduleStatus === "created" ? true : false;
        } else {
          isCreatedStatus.value = false;
        }
        return scheduledPayments;
      } catch (error) {
        isLoading.value = false;
        toast.error(error);
      }
    }

    async function onRowEditSave(event) {
      isLoading.value = true;
      let { newData } = event;
      if (newData.scheduleStatus !== "created") {
        toast.error(
          "Scheduled payment must be in a 'created' status to update"
        );
        return (isLoading.value = false);
      } else {
        const updateDate = formatDate(newData.paymentDate);
        const payload = { paymentDate: updateDate };

        try {
          await scheduledPaymentService.updateScheduledPayment(
            newData.invoiceId,
            newData.scheduledPaymentId,
            payload
          );
        } catch (error) {
          toast.error(error);
        }
        scheduledPayments.value = [];
        var result = await getScheduledPayments("created", "");
        scheduledPayments.value.push(...result);
        toast.success("Updated Scheduled Payment");
        return (isLoading.value = false);
      }
    }

    async function onStatusChange(event) {
      let status = event.value;
      scheduledPayments.value = [];
      var result = await getScheduledPayments(status, "");
      scheduledPayments.value.push(...result);
    }

    async function onCancelScheduledPayment() {
      cancelScheduledPaymentDialog.value = false;
      if (cancelScheduledPayment.value.scheduleStatus === "created") {
        const payload = { scheduleStatus: "canceled" };
        try {
          await scheduledPaymentService.updateScheduledPayment(
            cancelScheduledPayment.value.invoiceId,
            cancelScheduledPayment.value.scheduledPaymentId,
            payload
          );
        } catch (error) {
          toast.error(error);
        }
        scheduledPayments.value = [];
        var result = await getScheduledPayments("created", "");
        scheduledPayments.value.push(...result);
        toast.success("Scheduled Payment Cancelled");
        return (isLoading.value = false);
      }
    }

    async function fetchInvoiceFileUrl(invoiceId) {
      invoiceFileUrl.value = await invoiceService.getInvoiceFileUrl(invoiceId);
      isWebViewerModalVisible.value = true;
    }

    function onWebViewerModalDataEvent() {
      isWebViewerModalVisible.value = false;
    }

    function confirmCancelScheduledPayment(eventData) {
      cancelScheduledPayment.value = eventData;
      cancelScheduledPaymentDialog.value = true;
    }

    function calculateMaxScheduleDate(data) {
      let scheduledPayment = data;
      let maxScheduleDays = 90;
      var returnDate = new Date();

      if (
        scheduledPayment.dueDateCalendar != null &&
        scheduledPayment.dueDateCalendar != ""
      ) {
        let daysUntilDue = calculateNumberDaysUntilDate(
          scheduledPayment.dueDateCalendar
        );
        if (daysUntilDue <= 30) {
          returnDate.setDate(returnDate.getDate() + 30);
          return returnDate;
        }
        if (daysUntilDue < maxScheduleDays) {
          maxScheduleDays = daysUntilDue;
        }
      } else {
        returnDate.setDate(returnDate.getDate() + 30);
        return returnDate;
      }
      returnDate.setDate(returnDate.getDate() + maxScheduleDays);
      return returnDate;
    }

    return {
      scheduledPayments,
      isLoading,
      filters,
      statuses,
      rowEdit,
      minDate,
      cancelScheduledPayment,
      cancelScheduledPaymentDialog,
      isColumnRoleVisible,
      isCombinedInvoiceFormatEnabled,
      showInvoiceDueDate,
      calculateMaxScheduleDate,
      onRowEditSave,
      confirmCancelScheduledPayment,
      onCancelScheduledPayment,
      onStatusChange,
      isCreatedStatus,
      isWebViewerModalVisible,
      isInvoiceFileViewerEnabled,
      invoiceFileUrl,
      fetchInvoiceFileUrl,
      onWebViewerModalDataEvent,
    };
  },
};
</script>
