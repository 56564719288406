<template>
  <div>
    <Dialog
      header="Create Scheduled Payment"
      v-model:visible="isModalVisible"
      :style="{ width: '40vw' }"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
      :draggable="false"
    >
      <div class="card">
        <p><strong>Customer Notice</strong></p>
        <p v-if="paymentMethod !== 'StoredAccount'">
          - Creating a scheduled payment requires safely and securely storing
          this payment method for future use
        </p>
        <p>
          - Removing the stored payment method prior to the payment date will
          cause the scheduled payment to be unsuccessful
        </p>
        <div>
          <p>
            <label for="icon">Select Payment Date: </label>
            <Calendar
              id="icon"
              v-model="paymentDateSelected"
              :selectionMode="'single'"
              :minDate="minDate"
              :maxDate="maxDate"
              :manualInput="false"
              :showIcon="true"
            />
          </p>
        </div>
      </div>
      <template #footer>
        <Button
          class="p-button-text button-cancel-schedule-payment"
          label="Cancel"
          icon="pi pi-times"
          :disabled="isLoading"
          @click="emitScheduledPaymentModalDataEvent()"
        />
        <Button
          class="p-button-text button-schedule-payment"
          label="Schedule Payment"
          icon="pi pi-check"
          autofocus
          :loading="isLoading"
          :disabled="paymentDateSelected === undefined"
          @click="onSubmitScheduledPayment()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
import router from "@/router";
import scheduledPaymentService from "@/services/scheduledPayment";
import { formatDate, calculateNumberDaysUntilDate } from "@/utility/helpers";
import { useToast } from "vue-toastification";
import store from "@/store";

export default {
  props: {
    isModalVisibleProp: {
      type: Boolean,
    },
    paymentAccountProp: {
      type: Object,
    },
    paymentMethodProp: {
      type: String,
    },
    termsAndConditionsAcceptedProp: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ["resetScheduledPaymentModal"],
  setup(props, { emit }) {
    const isModalVisible = toRef(props, "isModalVisibleProp");
    const paymentMethod = toRef(props, "paymentMethodProp");
    const paymentAccount = toRef(props, "paymentAccountProp");
    const termsAndConditionsAccepted = toRef(
      props,
      "termsAndConditionsAcceptedProp"
    );
    const paymentProviderSettingsId = computed(
      () => store.getters.paymentProviderSettingsId
    );
    const toast = useToast();
    const isLoading = ref(false);
    const paymentDateSelected = ref();
    const minDate = ref(new Date());
    minDate.value.setDate(minDate.value.getDate() + 1);
    const maxDate = ref(new Date());
    maxDate.value.setDate(calculateMaxScheduleDate());

    async function onSubmitScheduledPayment() {
      let token;
      let typeDetail;
      let lastFour;
      if (paymentMethod.value === "StoredAccount") {
        isLoading.value = true;
        if (paymentAccount.value.cardAccount) {
          token = paymentAccount.value.cardAccount.token;
          typeDetail = paymentAccount.value.cardAccount.type;
          lastFour = paymentAccount.value.cardAccount.last4;
        } else {
          token = paymentAccount.value.bankAccount.token;
          typeDetail = paymentAccount.value.bankAccount.type;
          lastFour = paymentAccount.value.bankAccount.last4;
        }
        try {
          let createScheduledPaymentDto = {
            paymentDate: formatDate(paymentDateSelected.value),
            paymentType: paymentAccount.value.paymentType,
            paymentUserEmail: paymentAccount.value.paymentUserEmail,
            paymentToken: token,
            customerId:
              paymentAccount.value?.cardAccount?.customerId?.toString(),
            cardId: paymentAccount.value?.cardAccount?.cardId?.toString(),
            paymentProviderSettingsId: paymentProviderSettingsId.value,
            paymentTypeDetail: typeDetail,
            last4: lastFour,
            termsAndConditionsAccepted: termsAndConditionsAccepted.value,
          };
          await scheduledPaymentService.createScheduledPayment(
            createScheduledPaymentDto
          );
        } catch (error) {
          toast.error("Unable to Create Scheduled Payment");
          return emitScheduledPaymentModalDataEvent();
        }
        isLoading.value = false;
        emitScheduledPaymentModalDataEvent();
        setTimeout(await router.push({ path: "/scheduled-payments" }), 4000);
        toast.success("Scheduled Payment Created");
      } else {
        isLoading.value = true;
        let response;
        try {
          response =
            await scheduledPaymentService.createStoredAccountAndRetrieveToken(
              paymentAccount.value
            );
        } catch (error) {
          toast.error("Unable to Create Scheduled Payment");
          return emitScheduledPaymentModalDataEvent();
        }
        if (paymentAccount.value.cardAccount) {
          typeDetail = getCardIssuer(paymentAccount.value.cardAccount.number);
          lastFour = filterLast4(paymentAccount.value.cardAccount.number);
        } else {
          typeDetail = paymentAccount.value.bankAccount.type;
          lastFour = filterLast4(
            paymentAccount.value.bankAccount.accountNumber
          );
        }

        try {
          let createScheduledPaymentDto = {
            paymentDate: formatDate(paymentDateSelected.value),
            paymentType: paymentAccount.value.paymentType,
            paymentUserEmail: paymentAccount.value.paymentUserEmail,
            paymentToken: response.token,
            customerId: response?.customerId?.toString(),
            cardId: response?.id?.toString(),
            paymentProviderSettingsId: paymentProviderSettingsId.value,
            paymentTypeDetail: typeDetail,
            last4: lastFour,
            termsAndConditionsAccepted: termsAndConditionsAccepted.value,
          };
          await scheduledPaymentService.createScheduledPayment(
            createScheduledPaymentDto
          );
        } catch (error) {
          toast.error("Unable to Create Scheduled Payment");
          return emitScheduledPaymentModalDataEvent();
        }
        isLoading.value = false;
        emitScheduledPaymentModalDataEvent();
        setTimeout(await router.push({ path: "/scheduled-payments" }), 4000);
        toast.success("Scheduled Payment Created");
      }
    }

    function emitScheduledPaymentModalDataEvent() {
      setTimeout(emit("resetScheduledPaymentModal", false), 1500);
      paymentDateSelected.value = undefined;
      isLoading.value = false;
    }

    function filterLast4(accountNumber) {
      return accountNumber.substring(accountNumber.length - 4);
    }

    function getCardIssuer(cardAccount) {
      switch (cardAccount.charAt(0)) {
        case "3":
          return "American Express";
        case "4":
          return "Visa";
        case "5":
          return "Mastercard";
        case "6":
          return "Discover";
        default:
          return "";
      }
    }

    function calculateMaxScheduleDate() {
      let invoicesToPay = store.getters.invoicesToPay;
      let dateToday = new Date();
      let maxScheduleDays = 90;

      for (let i = 0; i < invoicesToPay.length; i++) {
        if (
          invoicesToPay[i].dueDate != null &&
          invoicesToPay[i].dueDate != ""
        ) {
          let daysUntilDue = calculateNumberDaysUntilDate(
            invoicesToPay[i].dueDate
          );
          if (daysUntilDue <= 30) {
            return dateToday.getDate() + 30;
          }
          if (daysUntilDue < maxScheduleDays) {
            maxScheduleDays = daysUntilDue;
          }
        } else {
          return dateToday.getDate() + 30;
        }
      }
      return dateToday.getDate() + maxScheduleDays;
    }

    return {
      isLoading,
      paymentMethod,
      isModalVisible,
      paymentDateSelected,
      minDate,
      maxDate,
      onSubmitScheduledPayment,
      emitScheduledPaymentModalDataEvent,
    };
  },
};
</script>
