<template>
  <div class="error-page">
    <div class="card ev-fluid-card-wide">
      <div class="error-page-bottom-padding">
        <div class="superdude-logo">
          <img
            class="evpay-superdude-img"
            src="/images/evpay_assets/evpay_superhero_med.png"
            alt="evpay-superdude"
          />
        </div>
        <h1 class="col-12 pt-3 text-center">
          403
          <br />
          Forbidden
        </h1>
        <h3 class="col-12 text-center">
          We're sorry, you don't have access to the page you requested.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Forbidden",
};
</script>
