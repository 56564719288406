<template>
  <div class="payment-detail">
    <div class="card ev-fluid-card">
      <div class="client-datatable">
        <div v-if="isMerchantSettingsSuccessful === true">
          <h4>Select Payment Method</h4>
          <div
            class="field-radiobutton"
            v-if="
              isGuestCheckoutLink === false && storedClientPaymentMethods.length
            "
          >
            <RadioButton
              name="storedAccount"
              value="StoredAccount"
              v-model="selectedPaymentMethod"
              :disabled="isRadioDisabled"
              @change="
                setDefaultPaymentMethod();
                onChangeReloadInvoicesEvent();
              "
            />
            <label>Stored Corporate Bank or Card Accounts</label>
          </div>

          <div class="field-radiobutton" v-if="hasAchActive">
            <RadioButton
              name="bankAccount"
              value="ACH"
              v-model="selectedPaymentMethod"
              :disabled="isRadioDisabled"
              @change="onChangeReloadInvoicesEvent()"
            />
            <label>New Corporate Bank Account </label>
          </div>

          <div class="field-radiobutton" v-if="hasCardActive">
            <RadioButton
              name="creditCard"
              value="Card"
              v-model="selectedPaymentMethod"
              :disabled="isRadioDisabled"
              @change="onChangeReloadInvoicesEvent()"
            />
            <label
              v-if="
                cardProcessingPriceProgramType !==
                CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment
              "
              >New Corporate Card</label
            >
            <label
              v-if="
                cardProcessingPriceProgramType ===
                CARD_PROCESSING_PRICE_PROGRAM.NonCashAdjustment
              "
              >New Corporate Card<span>
                - Includes a {{ nonCashAdjustmentPercent }}% Non-Cash
                Adjustment</span
              ></label
            >
          </div>

          <div
            v-if="
              selectedPaymentMethod === 'StoredAccount' &&
              storedClientPaymentMethods.length
            "
          >
            <StoredPaymentMethod
              :paymentMethodProp="selectedPaymentMethod"
              :cardProcessingPriceProgramTypeProp="
                cardProcessingPriceProgramType
              "
              @is-radio-disabled="onNonCashAdjustmentLoadingDataEvent"
              @reload-selected-payment-method="
                onReloadSelectedPaymentMethodEvent
              "
            />
          </div>
          <div v-if="selectedPaymentMethod === 'ACH'">
            <AchPaymentMethod
              :paymentMethodProp="selectedPaymentMethod"
              :signedInvoiceIdProp="signedInvoiceId"
              @is-radio-disabled="onNonCashAdjustmentLoadingDataEvent"
            />
          </div>
          <div v-if="selectedPaymentMethod === 'Card'">
            <CardPaymentMethod
              :paymentMethodProp="selectedPaymentMethod"
              :cardProcessingPriceProgramTypeProp="
                cardProcessingPriceProgramType
              "
              :signedInvoiceIdProp="signedInvoiceId"
              @is-radio-disabled="onNonCashAdjustmentLoadingDataEvent"
            />
          </div>
          <br />
        </div>
        <div v-else>
          <div class="block bg-pink-700 font-bold text-white p-4 border-round">
            Unable to process payments at this time. Please try again later.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted, computed } from "vue";
import store from "@/store";
import AchPaymentMethod from "./PaymentMethodAch.vue";
import CardPaymentMethod from "./PaymentMethodCard.vue";
import StoredPaymentMethod from "./PaymentMethodStoredAccount.vue";
import { CARD_PROCESSING_PRICE_PROGRAM } from "@/utility/cardProcessingPriceProgram";
import clientService from "@/services/client";
import invoiceService from "@/services/invoice";
import paymentProviderSettingsService from "@/services/paymentProviderSetting";
export default {
  components: {
    AchPaymentMethod,
    CardPaymentMethod,
    StoredPaymentMethod,
  },
  props: {
    signedInvoiceIdProp: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const signedInvoiceId = toRef(props, "signedInvoiceIdProp");
    const selectedPaymentMethod = ref();
    const storedClientPaymentMethods = ref([]);
    const isRadioDisabled = ref(false);
    const fetchMerchantSettingsResponse = ref();
    const hasCardActive = computed(() => store.getters.enableCC);
    const hasAchActive = computed(() => store.getters.enableACH);
    const cardProcessingPriceProgramType = computed(
      () => store.getters.cardProcessingPriceProgramType
    );
    const nonCashAdjustmentPercent = computed(
      () => store.getters.nonCashAdjustmentPercent
    );
    const isMerchantSettingsSuccessful = ref(true);
    const isGuestCheckoutLink = computed(() => {
      return signedInvoiceId.value ? true : false;
    });
    const fetchMerchantSettings = async () => {
      isRadioDisabled.value = true;
      if (isGuestCheckoutLink.value === true) {
        fetchMerchantSettingsResponse.value =
          await paymentProviderSettingsService.fetchMerchantSettingsBySignedInvoiceId(
            signedInvoiceId.value
          );
      } else {
        fetchMerchantSettingsResponse.value =
          await paymentProviderSettingsService.fetchMerchantSettings();
      }
      if (
        (hasAchActive.value === false && hasCardActive.value === false) ||
        fetchMerchantSettingsResponse.value !== 200
      ) {
        isMerchantSettingsSuccessful.value = false;
      } else {
        await setDefaultPaymentMethod();
        isRadioDisabled.value = false;
      }
    };

    onMounted(fetchMerchantSettings);

    async function setDefaultPaymentMethod() {
      isRadioDisabled.value = true;
      if (isGuestCheckoutLink.value === false) {
        storedClientPaymentMethods.value =
          await clientService.fetchClientStoredPaymentMethods();
      }
      if (storedClientPaymentMethods.value.length) {
        selectedPaymentMethod.value = "StoredAccount";
      } else {
        if (hasAchActive.value === true) {
          selectedPaymentMethod.value = "ACH";
        } else if (hasCardActive.value === true) {
          selectedPaymentMethod.value = "Card";
        }
      }
      isRadioDisabled.value = false;
    }

    async function onReloadSelectedPaymentMethodEvent(event) {
      if (event === true) {
        return await setDefaultPaymentMethod();
      }
    }

    async function onChangeReloadInvoicesEvent() {
      if (
        cardProcessingPriceProgramType.value ===
          CARD_PROCESSING_PRICE_PROGRAM.Surcharge ||
        selectedPaymentMethod.value === "ACH"
      ) {
        await invoiceService.reloadInvoicesAsync();
      }
    }

    function onNonCashAdjustmentLoadingDataEvent(event) {
      isRadioDisabled.value = event;
    }

    return {
      CARD_PROCESSING_PRICE_PROGRAM,
      cardProcessingPriceProgramType,
      signedInvoiceId,
      selectedPaymentMethod,
      storedClientPaymentMethods,
      isRadioDisabled,
      hasCardActive,
      hasAchActive,
      isGuestCheckoutLink,
      isMerchantSettingsSuccessful,
      nonCashAdjustmentPercent,
      setDefaultPaymentMethod,
      onReloadSelectedPaymentMethodEvent,
      onChangeReloadInvoicesEvent,
      onNonCashAdjustmentLoadingDataEvent,
    };
  },
};
</script>
