"use strict";
import httpClient from "../httpClient";
import store from "@/store";

export default {
    async createTenant(createTenantDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.post(
                `/tenants`,
                createTenantDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getAllTenants() {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getTenantById(tenantId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getUserPoolAppClient(tenantId, appClientId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/user-pool-app-client/${appClientId}`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async getApiKeyConfiguration(tenantId, apiKeyId) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.get(
                `/tenants/${tenantId}/api-key-configuration/${apiKeyId}`, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },

    async updateTenant(updateTenantDto) {
        let response;
        try {
            const bearerToken = store.getters.accessToken;

            response = await httpClient.put(
                `/tenants/${updateTenantDto.tenantId}`,
                updateTenantDto, {
                    baseURL: process.env.VUE_APP_EVPAY_MANAGER_API_BASE_URL,
                    headers: {
                        "x-api-key": process.env.VUE_APP_MANAGER_API_KEY,
                        Authorization: "Bearer " + bearerToken,
                    },
                }
            );
        } catch (error) {
            response = error.response;
        }
        return response;
    },
};