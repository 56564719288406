<template>
  <div class="layout-dashboard">
    <div v-if="showClientReadonlyMessage === true">
      <Message
        >Your user role currently is <strong>read only</strong>, if you need to
        perform elevated functions, please request a user role update via email
        to your EVPay administrator -
        <strong>{{ clientAdminEmailAddress }}</strong></Message
      >
    </div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <div class="card no-gutter widget-overview-box widget-overview-box-1">
          <span class="overview-icon">
            <i class="pi pi-dollar"></i>
          </span>
          <router-link to="/invoices">
            <span class="overview-title">Unpaid Invoices</span>
            <div class="grid overview-detail">
              <div class="col-6">
                <div class="overview-number">
                  {{ dashboardData.unpaidInvoicesCount }}
                </div>
                <div class="overview-subtext">Total</div>
              </div>
              <div class="col-6">
                <div class="overview-number">
                  {{ dashboardData.unpaidInvoicesAmount }}
                </div>
                <div class="overview-subtext">Amount</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card no-gutter widget-overview-box widget-overview-box-2">
          <span class="overview-icon">
            <i class="pi pi-dollar"></i>
          </span>
          <router-link to="/payment-history">
            <span class="overview-title">Last Payment</span>
            <div class="grid overview-detail">
              <div class="col-6">
                <div class="overview-number">
                  {{
                    dashboardData.lastClientPaymentDate == null
                      ? "--"
                      : dashboardData.lastClientPaymentDate
                  }}
                </div>
                <div class="overview-subtext">Date</div>
              </div>
              <div class="col-6">
                <div class="overview-number">
                  {{
                    dashboardData.lastClientPaymentAmount == null
                      ? "--"
                      : dashboardData.lastClientPaymentAmount
                  }}
                </div>
                <div class="overview-subtext">Amount</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card no-gutter widget-overview-box widget-overview-box-3">
          <div
            v-if="showInvoiceDueDate === true && hasPastDueInvoices === true"
          >
            <span class="overview-icon">
              <i class="pi pi-dollar"></i>
            </span>
            <router-link to="/invoices">
              <span class="overview-title">Past Due Invoices</span>
              <div class="grid overview-detail">
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.pastDueInvoicesCount }}
                  </div>
                  <div class="overview-subtext">Total</div>
                </div>
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.pastDueInvoicesAmount }}
                  </div>
                  <div class="overview-subtext">Amount</div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-else>
            <span class="overview-icon">
              <i class="pi pi-dollar"></i>
            </span>
            <router-link to="/payment-history">
              <span class="overview-title">Paid Invoices</span>
              <div class="grid overview-detail">
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.paidInvoicesCount }}
                  </div>
                  <div class="overview-subtext">Total</div>
                </div>
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.paidInvoicesAmount }}
                  </div>
                  <div class="overview-subtext">Amount</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card no-gutter widget-overview-box widget-overview-box-4">
          <div v-if="scheduledPaymentEnabled === true">
            <span class="overview-icon">
              <i class="pi pi-dollar"></i>
            </span>
            <router-link to="/scheduled-payments">
              <span class="overview-title">Scheduled Payments</span>
              <div class="grid overview-detail">
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.scheduledInvoicesCount }}
                  </div>
                  <div class="overview-subtext">Total</div>
                </div>
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.scheduledInvoicesAmount }}
                  </div>
                  <div class="overview-subtext">Amount</div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-else>
            <span class="overview-icon">
              <i class="pi pi-dollar"></i>
            </span>
            <router-link to="/payment-history">
              <span class="overview-title">Paid Invoices (over 60 days)</span>
              <div class="grid overview-detail">
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.paidInvoicesCountOver60Days }}
                  </div>
                  <div class="overview-subtext">Total</div>
                </div>
                <div class="col-6">
                  <div class="overview-number">
                    {{ dashboardData.paidInvoicesAmountOver60Days }}
                  </div>
                  <div class="overview-subtext">Amount</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="card">
          <h4>Invoices</h4>
          <p>Your invoices over time.</p>
          <Chart type="bar" :data="basicData" :options="basicOptions" />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="card">
          <div class="card-header">
            <h4>All Invoices</h4>
          </div>

          <p>Your invoices over time.</p>

          <DataTable
            :value="dashboardData.invoiceTable"
            :paginator="true"
            :rows="5"
            :loading="isLoading"
            responsiveLayout="scroll"
            sortField="dueDate"
            :sortOrder="1"
            :rowClass="rowClass"
          >
            <Column
              v-if="isCombinedInvoiceFormatEnabled === true"
              field="invoiceNumber"
              header="Invoice"
              style="max-width: 15rem"
              :sortable="true"
            >
              <template #body="slotProps">
                <span class="p-column-title">Invoice</span>
                {{ slotProps.data.invoiceNumber }}
              </template>
            </Column>
            <Column
              v-if="isCombinedInvoiceFormatEnabled === false"
              field="erpProjectNumber"
              header="Project"
              :sortable="true"
            >
              <template #body="slotProps">
                <span class="p-column-title">erpProjectNumber</span>
                {{ slotProps.data.erpProjectNumber }}
              </template>
            </Column>
            <Column
              v-if="isCombinedInvoiceFormatEnabled === false"
              field="erpInvoiceNumber"
              header="Invoice"
              :sortable="true"
            >
              <template #body="slotProps">
                <span class="p-column-title">erpInvoiceNumber</span>
                {{ slotProps.data.erpInvoiceNumber }}
              </template>
            </Column>
            <Column field="invoiceDate" header="Invoice Date" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">invoiceDate</span>
                {{ formatCalendarDate(slotProps.data.invoiceDate) }}
              </template>
            </Column>
            <Column
              v-if="showInvoiceDueDate === true"
              field="dueDate"
              header="Due Date"
              :sortable="true"
            >
              <template #body="slotProps">
                <span class="p-column-title">dueDate</span>
                <span
                  v-if="
                    slotProps.data.dueDate != null &&
                    slotProps.data.dueDate != ''
                  "
                  >{{ formatCalendarDate(slotProps.data.dueDate) }}</span
                >
              </template>
            </Column>
            <Column field="invoiceAmount" header="Amount" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">invoiceAmount</span>
                {{ formatToCurrency(slotProps.data.invoiceAmount) }}
              </template>
            </Column>
            <Column field="invoiceStatus" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">invoiceStatus</span>
                {{ slotProps.data.invoiceStatus }}
              </template>
            </Column>
            <template #empty>
              <p style="text-align: center">No records found.</p>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import dashboardService from "@/services/dashboard";
import userService from "@/services/user";
import clientService from "@/services/client";
import store from "@/store";
import {
  formatCalendarDate,
  formatToCurrency,
  calculateNumberDaysUntilDate,
} from "@/utility/helpers";

export default {
  // eslint-disable-next-line
  name: "Dashboard",
  setup() {
    const isLoading = ref(false);
    const showClientReadonlyMessage = ref(false);
    const clientAdminEmailAddress = ref();
    const invoices = ref([]);
    const basicData = ref({});
    const basicOptions = ref({});
    const dashboardData = ref({
      unpaidInvoicesCount: 0,
      unpaidInvoicesAmount: 0,
      unpaidInvoicesCountOver60Days: 0,
      unpaidInvoicesAmountOver60Days: 0,
      paidInvoicesCount: 0,
      paidInvoicesAmount: 0,
      paidInvoicesCountOver60Days: 0,
      paidInvoicesAmountOver60Days: 0,
      pastDueInvoicesCount: 0,
      pastDueInvoicesAmount: 0,
      scheduledInvoicesCount: 0,
      scheduledInvoicesAmount: 0,
      invoiceChartUnpaid: [],
      invoiceChartPaid: [],
      invoiceChartScheduled: [],
      invoiceTable: [],
      lastClientPaymentAmount: null,
      lastClientPaymentDate: null,
    });

    const scheduledPaymentEnabled = computed(
      () => store.getters.scheduledPaymentEnabled
    );
    const isCombinedInvoiceFormatEnabled = computed(
      () => store.getters.combinedInvoiceFormatEnabled
    );
    const showInvoiceDueDate = computed(() => store.getters.showInvoiceDueDate);
    const hasPastDueInvoices = computed(
      () => dashboardData.value.pastDueInvoicesCount > 0
    );

    const fetchDashboardData = async () => {
      await store.dispatch("flushInvoicesStore");
      await store.dispatch("flushPaymentsStore");

      isLoading.value = true;
      invoices.value = await dashboardService.getAllClientInvoices();
      dashboardData.value = await dashboardService.getDashboardData(
        invoices.value
      );
      isLoading.value = false;

      basicData.value = {
        labels: [
          "Under 30 days",
          "30 - 60 days",
          "60 - 90 days",
          "90 - 120 days",
        ],
        datasets: [
          {
            label: "Paid",
            data: dashboardData.value.invoiceChartPaid,
            backgroundColor: [
              "rgba(77, 182, 172, 0.2)",
              "rgba(77, 182, 172, 0.2)",
              "rgba(77, 182, 172, 0.2)",
              "rgba(77, 182, 172, 0.2)",
            ],
            borderColor: [
              "rgb(77, 182, 172)",
              "rgb(77, 182, 172)",
              "rgb(77, 182, 172)",
              "rgb(77, 182, 172)",
            ],
            borderWidth: 1,
          },
          {
            label: "Unpaid",
            data: dashboardData.value.invoiceChartUnpaid,
            backgroundColor: [
              "rgba(255, 95, 31, 0.2)",
              "rgba(255, 95, 31, 0.2)",
              "rgba(255, 95, 31, 0.2)",
              "rgba(255, 95, 31, 0.2)",
            ],
            borderColor: [
              "rgb(255, 95, 31)",
              "rgb(255, 95, 31)",
              "rgb(255, 95, 31)",
              "rgb(255, 95, 31)",
            ],
            borderWidth: 1,
          },
          {
            label: "Scheduled",
            data: dashboardData.value.invoiceChartScheduled,
            backgroundColor: [
              "rgba(77, 208, 225, 0.2)",
              "rgba(77, 208, 225, 0.2)",
              "rgba(77, 208, 225, 0.2)",
              "rgba(77, 208, 225, 0.2)",
            ],
            borderColor: [
              "rgb(77, 208, 225)",
              "rgb(77, 208, 225)",
              "rgb(77, 208, 225)",
              "rgb(77, 208, 225)",
            ],
            borderWidth: 1,
          },
        ],
      };

      if (scheduledPaymentEnabled.value == false) {
        basicData.value.datasets.splice(2, 1);
      }

      basicOptions.value = {
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
              callback: function (value) {
                return "$" + value;
              },
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    };

    function rowClass(data) {
      if (
        data.invoiceStatus == "Unpaid" &&
        data.dueDate != null &&
        data.dueDate != "" &&
        showInvoiceDueDate.value === true
      ) {
        let daysUntilDue = calculateNumberDaysUntilDate(data.dueDate);
        if (daysUntilDue < 0) {
          return "ev-invoice-overdue-color";
        }
        if (daysUntilDue >= 0 && daysUntilDue <= 14) {
          return "ev-invoice-near-overdue-color";
        }
      }
      return null;
    }

    async function showReadonlyUserMessageAsync() {
      const isUserReadonly =
        await userService.isUserRoleClientReadonlyAndSetCreatedDate();
      if (isUserReadonly === true) {
        clientAdminEmailAddress.value =
          await clientService.fetchClientAdminUserEmailAddress();
        showClientReadonlyMessage.value = true;
      }
    }

    onMounted(fetchDashboardData);
    onMounted(showReadonlyUserMessageAsync);

    return {
      isLoading,
      showClientReadonlyMessage,
      clientAdminEmailAddress,
      invoices,
      basicData,
      basicOptions,
      dashboardData,
      scheduledPaymentEnabled,
      isCombinedInvoiceFormatEnabled,
      showInvoiceDueDate,
      hasPastDueInvoices,
      formatCalendarDate,
      fetchDashboardData,
      formatToCurrency,
      rowClass,
    };
  },
};
</script>
